import React, { Component } from "react";

import { connect } from "react-redux";
import { postEditStoreGeneralTemplate } from "../../../../redux/backend/action/store";

import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/lib/Creatable";
import AsyncSelect from "react-select/lib/Async";
import DatePicker from "react-datepicker";
import moment from "moment";
import { withRouter } from "react-router-dom";

import ColorInput from "./inputColorPicker";

class Index extends Component {
  state = {
    linkColor: "",
    priceColor: "",
    headerBackgroundColor: "",
    pageBackgroundColor: "",
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    const propsStore = this.props.store;
    if (!this.state.store && propsStore && propsStore.item) {
      this.setState({
        linkColor: this.props.store.item.linkColor,
        priceColor: this.props.store.item.priceColor,
        headerBackgroundColor: this.props.store.item.headerBackgroundColor,
        pageBackgroundColor: this.props.store.item.pageBackgroundColor,
        store: this.props.store,
      });
    }
  }

  handleChangeColor = (color, name) => {
    var data = "";
    if (name == "linkColor") {
      data = "linkColor=" + color;
    } else if (name == "priceColor") {
      data = "priceColor=" + color;
    } else if (name == "headerBackgroundColor") {
      data = "headerBackgroundColor=" + color;
    } else if (name == "pageBackgroundColor") {
      data = "pageBackgroundColor=" + color;
    }

    if (data) {
      var storeId = this.props.store.item.id;
      this.props.postEditStoreGeneralTemplate(storeId, data);
      console.log("posting", data);
    }
  };

  hexToRgb = (hex) => {
    if (hex == null || !hex.includes("#")) {
      return { r: "", g: "", b: "" };
    }

    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: "", g: "", b: "" };
  };

  render() {
    const { store } = this.props;
    if (!store.item) return <>Loading..</>;

    /* width */
    let logoWidth = store.item.logoWidth
      ? store.item.logoWidth + "px"
      : "200px";

    /* height */
    let logoHeight = store.item.logoHeight
      ? store.item.logoHeight + "px"
      : "200px";

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="form-container">
            <Row>
              <Col md="3">
                <div className="">Link Color:</div>

                <ColorInput
                  name="linkColor"
                  handleChangeColor={this.handleChangeColor}
                  colorR={this.hexToRgb(store.item.linkColor).r}
                  colorG={this.hexToRgb(store.item.linkColor).g}
                  colorB={this.hexToRgb(store.item.linkColor).b}
                />
              </Col>

              <Col md="3">
                <div className="">Price Color:</div>
                <ColorInput
                  name="priceColor"
                  handleChangeColor={this.handleChangeColor}
                  colorR={this.hexToRgb(store.item.priceColor).r}
                  colorG={this.hexToRgb(store.item.priceColor).g}
                  colorB={this.hexToRgb(store.item.priceColor).b}
                />
              </Col>

              <Col md="3">
                <div className="">Header Background:</div>
                <ColorInput
                  name="headerBackgroundColor"
                  handleChangeColor={this.handleChangeColor}
                  colorR={this.hexToRgb(store.item.headerBackgroundColor).r}
                  colorG={this.hexToRgb(store.item.headerBackgroundColor).g}
                  colorB={this.hexToRgb(store.item.headerBackgroundColor).b}
                />
              </Col>
            </Row>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});

export default connect(
  mapStateToProps,
  { postEditStoreGeneralTemplate }
)(withRouter(Index));
