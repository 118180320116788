import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postEditFilter } from '../../../redux/backend/action/filter';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FormEdit extends Component {
  state = {
    name: '',
  };

  componentDidMount() {}

  componentWillReceiveProps(recievedProps) {
    const filter = recievedProps.filter.item;
    if (filter) {
      this.setState({
        id: filter.id,
        name: filter.name,
        internalName: filter.internalName,
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    } else if (name === 'internalName') {
      this.setState({ internalName: value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postEditFilter(
      this.props.match.params.id,
      this.state,
      this.props.history
    );
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md='12'>
              <h4>Edit Filter</h4>
              <div className='form-container'>
                <Row>
                  <Col md='6'>
                    <div className='form-group'>
                      <label>Name: </label>
                      <input
                        type='text'
                        name='name'
                        className='form-control form-control-sm'
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md='6'>
                    <div className='form-group'>
                      <label>Internal Name: </label>
                      <input
                        type='text'
                        name='internalName'
                        className='form-control form-control-sm'
                        value={this.state.internalName}
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filterReducer.filter,
});

export default connect(
  mapStateToProps,
  {
    postEditFilter,
  }
)(withRouter(FormEdit));
