import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getStore } from '../../../redux/backend/action/store';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Index extends Component {
  state = {
    store: '',
  };

  componentDidMount() {}

  componentWillReceiveProps(recievedProps) {
    const store = recievedProps.store.item;
    if (store) {
      this.setState({
        id: store.id,
        name: store.name,
        content: store.content,
      });
    }
  }

  render() {
    const { store } = this.props;

    if (!store.item) {
      return <>Loading..</>;
    }

    return (
      <>
        <dl class='row'>
          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Name
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {store.item.name}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Address
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {store.item.address}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Email
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {store.item.email}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Mobile
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {store.item.mobile}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Phone
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {store.item.phone}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Website
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {store.item.website}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mt-20'
            style={{ maxWidth: '120px' }}
          >
            <Link
              className='btn btn-outline-default'
              to={'/store/edit'}
              style={{ marginLeft: '5px' }}
            >
              Edit
            </Link>
          </dt>
        </dl>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});

export default connect(
  mapStateToProps,
  { getStore }
)(withRouter(Index));
