import React, { Component } from 'react';
import { ListGroup, Card, Button, Row, Col, Accordion } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import {
  getAllHomeSection,
  postSortSection,
} from '../../../redux/backend/action/section';

import { Link } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import DefaultSections from './defaultSection';

const DragHandle = sortableHandle(() => (
  <span className='Showcase__style__handle'>::</span>
));

const SortableItem = sortableElement(
  ({ value, type, id, activeId, hasPublished }) => (
    <li className={activeId == id ? 'SortableItem active' : 'SortableItem'}>
      <DragHandle />
      <div
        style={{
          right: '0',
          position: 'absolute',
          marginRight: '8px',
          fontStyle: 'italic',
          color: '#737171',
          fontSize: '10px',
          textTransform: 'uppercase',
        }}
      >
        {/* {type} */}

        {hasPublished == 0 && (
          <div style={{ fontSize: '7px', color: 'red', textAlign: 'right' }}>
            <b>Un-published</b>
          </div>
        )}

        <div style={{ fontSize: '8px', textAlign: 'right' }}>{type}</div>
      </div>
      <Link to={'/setting/template/section/' + id}>{value}</Link>
    </li>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className='SortableList'>{children}</ul>;
});

class Index extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    this.props.getAllHomeSection();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    if (
      JSON.stringify(this.state.items) !==
      JSON.stringify(this.props.sectionListing.items)
    ) {
      console.log('trigger');
      this.setState({
        items: this.props.sectionListing.items,
      });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    console.log(oldIndex, newIndex);

    let sortedItems = arrayMove(this.state.items, oldIndex, newIndex);

    this.setState(({ items }) => ({
      items: sortedItems,
    }));

    this.props.postSortSection(sortedItems);
    console.log('sortedItems', sortedItems);
  };

  render() {
    const { items } = this.state;

    if (!items) {
      return <div>Loading..</div>;
    }

    return (
      <>
        {/* General Setup */}
        <ListGroup className='mb-10'>
          <Link to={'/setting/template/general'}>
            <ListGroup.Item
              style={
                this.props.activeId == 'general'
                  ? { background: '#daf794' }
                  : { background: '#FFF' }
              }
            >
              General Setup
            </ListGroup.Item>
          </Link>
        </ListGroup>

        {/* Header Setup */}
        <ListGroup className='mb-10'>
          <Link to={'/setting/template/header'}>
            <ListGroup.Item
              style={
                this.props.activeId == 'header'
                  ? { background: '#daf794' }
                  : { background: '#FFF' }
              }
            >
              Header Setup
            </ListGroup.Item>
          </Link>
        </ListGroup>

        {/* Menu Setup */}
        <ListGroup className='mb-10'>
          <Link to={'/setting/template/menu'}>
            <ListGroup.Item
              style={
                this.props.activeId == 'menu'
                  ? { background: '#daf794' }
                  : { background: '#FFF' }
              }
            >
              Menu Setup
            </ListGroup.Item>
          </Link>
        </ListGroup>

        <div style={{ marginBottom: '10px' }}>
          <DefaultSections />
        </div>
        <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
          {items.map((item, index) => (
            <SortableItem
              key={`item-${item.id}`}
              index={index}
              value={item.name}
              id={item.id}
              activeId={this.props.activeId}
              type={item.type}
              hasPublished={item.hasPublished}
            />
          ))}
        </SortableContainer>

        {this.props.sectionListing.footerSection && (
          <>
            <ListGroup className='mb-10'>
              <Link
                to={
                  '/setting/template/section/' +
                  this.props.sectionListing.footerSection.id
                }
              >
                <ListGroup.Item
                  style={
                    this.props.activeId ==
                    this.props.sectionListing.footerSection.id
                      ? { background: '#daf794' }
                      : { background: '#FFF' }
                  }
                >
                  FOOTER
                </ListGroup.Item>
              </Link>
            </ListGroup>
          </>
        )}
      </>

      // this.props.match.params.id this.props.sectionListing.footerSection.id == this.props.sectionListing.footerSection.id ? { background: '#daf794' }: {background: '#FFF'}
    );
  }
}

const mapStateToProps = (state) => ({
  sectionListing: state.sectionReducer.sectionListing,
});
export default connect(
  mapStateToProps,
  { getAllHomeSection, postSortSection }
)(Index);
