import React, { Component } from 'react';

import ReactImageMagnify from 'react-image-magnify';

import { Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

class Index extends Component {
  render() {
    const { page } = this.props;
    if (!page) {
      return <>Loading..</>;
    }

    return (
      <>
        <nav aria-label='breadcrumb'>
          <ol class='breadcrumb bg-white mb-0 pt-0'>
            <li class='breadcrumb-item'>
              <Link to='/'>Home</Link>
            </li>

            <li class='breadcrumb-item active' aria-current='page'>
              {page.item.name}
            </li>
          </ol>
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.pageReducer.page,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
