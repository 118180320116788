import React, { Component } from "react";
import "react-table/react-table.css";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { Nav, Navbar, Brand } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import MenuLink from "./link";

class Menu extends Component {
  render() {
    const { templateMenuListing, indexMenu } = this.props;
    if (!templateMenuListing) {
      return <></>;
    }

    var child1AndMore = "";

    var keyChild1 = "menu" + indexMenu + "_child1";

    var Child1List = templateMenuListing[keyChild1];

    if (Child1List) {
      child1AndMore = Child1List.map((child1, indexChild1) => {
        var keyChild2 = "menu" + indexMenu + "_child" + indexChild1 + "_child2";

        var Child2List = templateMenuListing[keyChild2];

        if (Child2List) {
          var child2AndMore = Child2List.map((child2, indexChild2) => {
            var keyChild3 =
              "menu" +
              indexMenu +
              "_child" +
              indexChild1 +
              "_child" +
              indexChild2 +
              "_child3";

            var Child3List = templateMenuListing[keyChild3];

            if (Child3List) {
              var allChild3 = Child3List.map((child3, indexChild3) => {
                return (
                  <>
                    <MenuLink
                      templateMenuListing={templateMenuListing}
                      keyValue={keyChild3}
                      index={indexChild3}
                    />
                  </>
                );
              });

              return (
                <>
                  <DropdownSubmenu href="#action/3.7" title={"ggg " + child2}>
                    {allChild3}
                  </DropdownSubmenu>
                </>
              );
            } else {
              // not child3, so link
              return (
                <>
                  <MenuLink
                    templateMenuListing={templateMenuListing}
                    keyValue={keyChild2}
                    index={indexChild2}
                  />
                </>
              );
            }
          });

          return (
            <>
              <DropdownSubmenu href="#action/3.7" title={"ggg " + child1}>
                {child2AndMore}
              </DropdownSubmenu>
            </>
          );
        } else {
          // not child2, so link
          return (
            <>
              <MenuLink
                templateMenuListing={templateMenuListing}
                keyValue={keyChild1}
                index={indexChild1}
              />
            </>
          );
        }
      });
    }

    return <> {child1AndMore}</>;
  }
}

const mapStateToProps = (state) => ({
  templateMenuListing: state.menuReducer.templateMenuListing,
});
export default connect(
  mapStateToProps,
  {}
)(Menu);
