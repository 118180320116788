import React, { Component } from "react";

import { connect } from "react-redux";
import {
  getAllItemImage,
  deleteItemImage,
} from "../../../redux/backend/action/itemImage";

import { Row, Col } from "react-bootstrap";

import { withRouter } from "react-router-dom";

/* image uploader: https://www.npmjs.com/package/react-upload-gallery */
/* http://react-component.github.io/upload/examples/customRequest.html */
import RUG, { DragArea, DropArea, Card, List } from "react-upload-gallery";
import "react-upload-gallery/dist/style.css"; // or scss

import { apiBaseUrl } from "../../../constant/api";
import axios from "axios";

class FormAdd extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <>
        <Row>
          <Col md="12">
            <RUG
              initialState={this.props.imageData}
              action={
                apiBaseUrl +
                "/backend/itemimage/addImage?id=" +
                this.props.itemId
              }
              rules={{
                limit: 10,
              }}
              onChange={(e, images) => {}}
              onSuccess={() => {
                this.props.getAllItemImage(this.props.itemId);
              }}
              customRequest={({
                uid,
                file,
                filename,
                data,
                send,
                action,
                headers,
                onSuccess,
                onError,
                withCredentials,
              }) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("uid", uid);

                // handlePostImage(formData)
                // this.props.postAddItemImage(formData);
                axios
                  .post(action, formData, {
                    withCredentials,
                    headers,
                  })
                  .then(({ data: response }) => {
                    onSuccess(response, file);
                  })
                  .catch(onError);

                return {
                  abort() {
                    console.log("upload progress is aborted.");
                  },
                };
              }}
            >
              <div></div>
            </RUG>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  itemImageListing: state.itemImageReducer.itemImageListing,
});

export default connect(
  mapStateToProps,
  { getAllItemImage, deleteItemImage }
)(withRouter(FormAdd));
