import React, { Component, useState } from 'react';
import { Carousel, Image } from 'react-bootstrap';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Index extends Component {
  render() {
    const { productRow } = this.props;
    if (!productRow.item) return <>Loading..</>;

    const product = productRow.item;
    const filterListing = product.filterListingData;

    console.log('filterListing', filterListing);
    return (
      <>
        <dl class='row'>
          {filterListing.items.map((item, index) => {
            return (
              <>
                <dt className='col-sm-2 proxima-nova-light'>{item.name}</dt>
                <dd className='col-sm-10 proxima-nova-bold text-uppercase'>
                  {item.filterOptions.length == 0 && <>-</>}
                  {item.filterOptions && (
                    <>
                      {item.filterOptions.map((filterOption, indexOption) => {
                        return (
                          <>
                            {filterOption.hasItemFilter === true && (
                              <span className='mr-10'>
                                #{filterOption.name}
                              </span>
                            )}
                          </>
                        );
                      })}
                    </>
                  )}
                </dd>
              </>
            );
          })}
        </dl>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
