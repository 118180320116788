import React from 'react';
import Pagination from 'react-js-pagination';

export default function PaginateTable(props) {
  const { listing, activePage } = props;

  if (!listing.pagination) return <></>;

  return (
    <>
      <div style={{ width: '100%', display: 'table' }}>
        {/* 0 results */}
        {listing.pagination.itemCount == 0 && (
          <>
            <div
              style={{
                background: '##f7f7f7',
                padding: '10px',
                border: '2px solid #e6e6e6',
              }}
            >
              No results found.
            </div>
          </>
        )}

        {/* >0 results */}
        {listing.pagination.itemCount > 0 && (
          <>
            <div style={{ float: 'left' }}>
              Listing {listing.pagination.start} - {listing.pagination.end} of{' '}
              {listing.pagination.itemCount} results
            </div>
          </>
        )}

        <div
          style={{ float: 'right' }}
          className={
            listing.pagination.noShowPagination === true ? 'force-hide' : ''
          }
        >
          <Pagination
            activePage={activePage}
            itemsCountPerPage={listing.pagination.pageSize}
            totalItemsCount={listing.pagination.itemCount}
            pageRangeDisplayed={5}
            itemClass='page-item'
            linkClass='page-link'
            onChange={props.handlePageChange}
          />
        </div>
      </div>
    </>
  );
}
