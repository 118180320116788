import React, { Component } from 'react';
import { Card, Button, Row, Col, Accordion } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import {
  getAllMenu,
  postSortMenu,
  postDeleteMenu,
} from '../../../../redux/backend/action/menu';

import { Link } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const DragHandle = sortableHandle(() => (
  <span className='Showcase__style__handle'>::</span>
));

const SortableItem = sortableElement(({ value, id, onDele, handleDelete }) => (
  <li className='SortableItem'>
    <DragHandle />

    <div
      style={{
        right: '0',
        position: 'absolute',
        marginRight: '8px',
      }}
    >
      <div className='btn btn-danger btn-sm' onClick={() => handleDelete(id)}>
        Delete
      </div>
    </div>

    {value}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className='SortableList'>{children}</ul>;
});

class Index extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    this.props.getAllMenu();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    if (
      JSON.stringify(this.state.items) !==
      JSON.stringify(this.props.menuListing.items)
    ) {
      console.log('trigger');
      this.setState({
        items: this.props.menuListing.items,
      });
    }
  }

  handleDelete = (id) => {
    if (window.confirm('Are you sure, you want to delete?')) {
      this.props.postDeleteMenu(id);
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    console.log(oldIndex, newIndex);

    let sortedItems = arrayMove(this.state.items, oldIndex, newIndex);

    this.setState(({ items }) => ({
      items: sortedItems,
    }));

    this.props.postSortMenu(sortedItems);
    console.log('sortedItems', sortedItems);
  };

  render() {
    const { items } = this.state;

    if (!items) {
      return <div>Loading..</div>;
    }

    return (
      <>
        <SortableContainer
          onSortEnd={this.onSortEnd}
          handleDelete={this.handleDelete}
          useDragHandle
        >
          {items.map((item, index) => (
            <>
              <SortableItem
                key={`item-${item.id}`}
                index={index}
                value={item.name}
                id={item.id}
                handleDelete={this.handleDelete}
              />
            </>
          ))}
        </SortableContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menuListing: state.menuReducer.menuListing,
});
export default connect(
  mapStateToProps,
  { getAllMenu, postSortMenu, postDeleteMenu }
)(Index);
