import React, { Component } from 'react';

import { connect } from 'react-redux';
import FormEdit from './formEdit';
// import {} from '../../../redux/action/user'
class Index extends Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <h4>
          Edit Profile{' '}
          <small>
            {this.props.user.item && <>{this.props.user.item.name}</>}
          </small>
        </h4>

        <div className='form-container'>
          <FormEdit />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
});
export default connect(
  mapStateToProps,
  {}
)(Index);
