import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  getProfile,
  postEditProfile,
} from '../../../redux/backend/action/user';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

class FormEdit extends Component {
  state = {};

  componentDidMount() {
    this.props.getProfile();
  }

  componentWillReceiveProps(recievedProps) {
    const user = recievedProps.user.item;

    if (user) {
      this.setState({
        id: user.id,
        name: user.name,
        address: user.address,
        phone: user.phone,
        mobile: user.mobile,
        email: user.email,
        vehicleId: user.vehicleId,
        vehicleName: user.vehicleName,
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    } else if (name === 'address') {
      this.setState({ address: value });
    } else if (name === 'phone') {
      this.setState({ phone: value });
    } else if (name === 'mobile') {
      this.setState({ mobile: value });
    } else if (name === 'email') {
      this.setState({ email: value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postEditProfile(this.state, this.props.history);
  };

  render() {
    const { user } = this.props;

    if (!user) {
      return <>Loading..</>;
    }

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>Name: </label>
                <input
                  type='text'
                  name='name'
                  className='form-control'
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>Email: </label>
                <input
                  type='text'
                  name='email'
                  className='form-control'
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>Phone: </label>
                <input
                  type='text'
                  name='phone'
                  className='form-control'
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>Mobile: </label>
                <input
                  type='text'
                  name='mobile'
                  className='form-control'
                  value={this.state.mobile}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
});

export default connect(
  mapStateToProps,
  { getProfile, postEditProfile }
)(withRouter(FormEdit));
