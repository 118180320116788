// export constants

export const SET_LOADING = 'SET_LOADING';

export const SUBMITTING_STARTED = 'SUBMITTING_STARTED';
export const SUBMITTING_DONE = 'SUBMITTING_DONE';

export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const REGISTER_SUBMIT = 'REGISTER_SUBMIT';

// section
export const SECTION_GET_ALL = 'SECTION_GET_ALL';
export const SECTION_GET = 'SECTION_GET';

// item
export const ITEM_GET_ALL = 'ITEM_GET_ALL';
export const ITEM_GET = 'ITEM_GET';

// itemimage
export const ITEMIMAGE_GET_ALL = 'ITEMIMAGE_GET_ALL';
export const ITEMIMAGE_GET = 'ITEMIMAGE_GET';

// template menu
export const TEMPLATE_MENU_GET_ALL = 'TEMPLATE_MENU_GET_ALL';

// collection
export const COLLECTION_GET = 'COLLECTION_GET';
export const COLLECTION_GET_ALL = 'COLLECTION_GET_ALL';

// filter
export const COLLECTION_FILTER_GET_ALL = 'COLLECTION_FILTER_GET_ALL';

// section template
export const SECTION_TEMPLATE_GET = 'SECTION_TEMPLATE_GET';
export const SECTION_TEMPLATE_GET_ALL = 'SECTION_TEMPLATE_GET_ALL';

// page
export const PAGE_GET_ALL = 'PAGE_GET_ALL';
export const PAGE_GET = 'PAGE_GET';

// menu
export const MENU_GET_ALL = 'MENU_GET_ALL';
export const MENU_GET = 'MENU_GET';

// watchlist
export const WATCHLIST_GET_ALL = 'WATCHLIST_GET_ALL';
export const WATCHLIST_GET = 'WATCHLIST_GET';
export const WATCHLIST_GET_COUNT = 'WATCHLIST_GET_COUNT';

// cart
export const CART_GET_ALL = 'CART_GET_ALL';
export const CART_GET = 'CART_GET';
export const CART_GET_COUNT = 'CART_GET_COUNT';

// cart
export const ORDER_GET_ALL = 'ORDER_GET_ALL';
export const ORDER_GET = 'ORDER_GET';
export const ORDER_GET_COUNT = 'CART_GEORDER_GET_COUNTT_COUNT';

// store
export const STORE_GET = 'STORE_GET';
