import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getFooter } from '../../redux/frontend/action/section';

import FooterContent from './content';

class Index extends Component {
  componentDidMount() {
    this.props.getFooter();
  }

  render() {
    const { section } = this.props;
    if (!section) {
      return <>Loading..</>;
    }

    return (
      <>
        {/* footer */}
        {section.footerSection && (
          <>
            <div className='proxima-nova'>
              <FooterContent data={section.footerSection.publishJson} />
            </div>
          </>
        )}
        {/*  */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  section: state.sectionReducer.section,
});
export default connect(
  mapStateToProps,
  { getFooter }
)(withRouter(Index));
