import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getAllOrder } from '../../../redux/frontend/action/order';

import Header from '../../header';
import Footer from '../../footer';
import ListTable from './table';
// import OrderSummary from './summary';

class Index extends Component {
  componentDidMount() {
    this.props.getAllOrder();
  }
  render() {
    return (
      <>
        <Header />
        <div className='main-container' style={{ width: '100%' }}>
          {/* CART LIST */}
          <div
            style={{
              maxWidth: '75rem',
              margin: '0px auto',
              minHeight: '400px',
            }}
          >
            <h2>My Order</h2>

            <ListTable />
          </div>
        </div>

        {/* footer */}
        <Footer />
        {/*  */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orderListing: state.orderReducer.orderListing,
});
export default connect(
  mapStateToProps,
  { getAllOrder }
)(withRouter(Index));
