import React, { Component } from "react";
import "react-table/react-table.css";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

class MenuLink extends Component {
  render() {
    const { templateMenuListing, keyValue, index } = this.props;
    if (!templateMenuListing) {
      return <></>;
    }

    var name = templateMenuListing[keyValue + "_" + index + "_name"];
    // var slug = templateMenuListing[keyValue + "_" + index + "_slug"];
    // var id = templateMenuListing[keyValue + "_" + index + "_id"];

    return (
      <>
        <NavLink
          exact
          activeClassName="selected"
          className="dropdown-item"
          to="#"
        >
          {name}
        </NavLink>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(MenuLink);
