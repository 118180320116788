import React, { Component } from 'react';

import ReactImageMagnify from 'react-image-magnify';

import { Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

class Index extends Component {
  render() {
    const { collection } = this.props;
    if (!collection.breadCrumbData) {
      return <></>;
    }

    return (
      <>
        <nav aria-label='breadcrumb'>
          <ol class='breadcrumb bg-white mb-0 pt-0'>
            <li class='breadcrumb-item'>
              <Link to='/'>Home</Link>
            </li>

            {collection.breadCrumbData.length > 1 && (
              <>
                {collection.breadCrumbData.map((breadCrumb, index) => {
                  return (
                    <>
                      {/* donot show last, as last is displayed as active in the bottom section */}
                      {collection.breadCrumbData.length !=
                        parseInt(index) + 1 && (
                        <>
                          <li class='breadcrumb-item'>
                            <Link
                              to={`/collection/${breadCrumb.slug}/${breadCrumb.id}`}
                            >
                              {breadCrumb.name}
                            </Link>
                          </li>
                        </>
                      )}
                      {/*  */}
                    </>
                  );
                })}
              </>
            )}

            {/* active */}
            <li class='breadcrumb-item active' aria-current='page'>
              {collection.name}
            </li>
          </ol>
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collection: state.itemReducer.collection,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
