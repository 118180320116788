import { combineReducers } from 'redux';

import sectionTemplateReducer from './reducer/sectionTemplate';
import sectionReducer from './reducer/section';
import templateReducer from './reducer/template';
import collectionReducer from './reducer/collection';
import itemReducer from './reducer/item';
import pageReducer from './reducer/page';
import menuReducer from './reducer/menu';
import registerReducer from './reducer/register';
import watchlistReducer from './reducer/watchlist';
import cartReducer from './reducer/cart';
import orderReducer from './reducer/order';
import storeReducer from './reducer/store';

export default combineReducers({
  storeReducer: storeReducer,
  itemReducer: itemReducer,
  orderReducer: orderReducer,
  cartReducer: cartReducer,
  watchlistReducer: watchlistReducer,
  registerReducer: registerReducer,
  menuReducer: menuReducer,
  pageReducer: pageReducer,
  sectionTemplateReducer: sectionTemplateReducer,
  collectionReducer: collectionReducer,
  templateReducer: templateReducer,
  sectionReducer: sectionReducer,
});
