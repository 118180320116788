import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import { connect } from 'react-redux';
import { getOrder } from '../../../redux/backend/action/order';
import Form from './form';
import OrderDetail from './orderDetail';

class Index extends Component {
  componentDidMount() {
    this.props.getOrder(this.props.match.params.id);
  }

  render() {
    const { order } = this.props;

    if (!order.item) {
      return <div>Loading..</div>;
    }

    return (
      <>
        <h4>Fulfill Order</h4>

        <Row>
          <Col md='4'>
            <div className='plane-border-container'>
              <Form />
            </div>
          </Col>
          <Col md='8'>
            <div className='plane-border-container'>
              <OrderDetail />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.orderReducer.order,
});
export default connect(
  mapStateToProps,
  { getOrder }
)(Index);
