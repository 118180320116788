import React, { Component } from 'react';

import ReactImageMagnify from 'react-image-magnify';

import { Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getItem } from '../../redux/frontend/action/item';

import Header from '../header';
import Footer from '../footer';
import Detail from './detail';
import BreadCrumb from './breadCrumb';

import DescriptionView from './description';
import SimilarProducts from './similarProduct';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {
    this.props.getItem(this.state.id);
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /**
     * new Project in town ?
     */
    if (this.state.id !== this.props.match.params.id) {
      this.props.getItem(this.props.match.params.id);

      this.setState({
        id: this.props.match.params.id,
      });
    }
  }

  createMarkup = (text) => {
    return { __html: text };
  };

  render() {
    const { productRow } = this.props;

    if (!productRow.item) return <></>;

    const product = productRow.item;
    return (
      <>
        <Header />

        <div
          className='main-container'
          style={{ width: '100%', minHeight: '300px' }}
        >
          <div style={{ maxWidth: '75rem', margin: '0px auto' }}>
            <BreadCrumb />

            {/* image, filters */}
            <Detail itemId={this.state.id} />

            {/* description */}
            <DescriptionView />
          </div>

          {/* similar products */}
          <SimilarProducts itemId={product.id} />
        </div>

        {/* footer */}
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  { getItem }
)(withRouter(Index));
