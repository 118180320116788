import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import {
  SET_LOADING,
  COLLECTION_GET_ALL,
  COLLECTION_GET,
  COLLECTION_FILTER_GET_ALL,
} from './types';

const url = apiBaseUrl + '/frontend/collection';

export const getAllCollection = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};
  
  try {
    // headers
    const config = {};

    // get
    const response = await axios.get(
      `${url}/getAllCollection?${param}`,
      config
    );

    // dispatch
    dispatch({
      type: COLLECTION_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};
export const getAllCollectionFilter = (id, param) => async (dispatch) => {
  try {
    // headers
    const config = {};

    // get
    const response = await axios.get(
      `${url}/getAllCollectionFilter?id=${id}&${param}`,
      config
    );

    // dispatch
    dispatch({
      type: COLLECTION_FILTER_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getCollection = (id) => async (dispatch) => {
  try {
    // headers
    const config = {};

    // response
    const response = await axios.get(`${url}/getCollection?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: COLLECTION_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

// export const postFilter = (id, param) => async (dispatch) => {
//   try {
//     // headers
//     const config = {};

//     // response
//     const response = await axios.post(
//       `${url}/postFilter?id=${id}&${param}`,
//       config
//     );

//     if (response.data.result === 'SUCCESS') {
//       // dispatch
//       dispatch({ type: COLLECTION_GET, payload: response.data });
//     } else if (response.data.result === 'ERROR') {
//       toast.error(response.data.message);
//     }
//   } catch (err) {}
// };
