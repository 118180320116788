import React, { Component } from 'react';

import ReactImageMagnify from 'react-image-magnify';
import { Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

class Index extends Component {
  state = {};
  
  createMarkup = (text) => {
    return { __html: text };
  };

  render() {
    const { productRow } = this.props;
    if (!productRow.item) return <>Loading..</>;

    return (
      <>
        {/* description */}
            {productRow.item.description && (
              <>
                <div
                  className='product-description proxima-nova-light'
                  style={{
                    background: 'rgb(243 241 241)',
                    padding: '15px',
                    marginTop: '15px',
                  }}
                >
                  <h2
                    style={{ fontSize: '17px' }}
                    className='proxima-nova-bold'
                  >
                    Product details of {productRow.item.name}
                  </h2>

                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      productRow.item.description
                    )}
                    className='editor'
                  ></div>
                </div>
              </>
            )}
            {/* ./ description */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
