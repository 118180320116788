import { LOGIN_SUBMIT } from '../action/types';

const initialState = {
  data: [],
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUBMIT: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }

    default:
      return state;
  }
};
