import { combineReducers } from 'redux';

import sectionTemplateReducer from './reducer/sectionTemplate';
import sectionReducer from './reducer/section';
import templateReducer from './reducer/template';
import itemImageReducer from './reducer/itemImage';
import itemReducer from './reducer/item';
import userReducer from './reducer/user';
import customerReducer from './reducer/customer';
import loginReducer from './reducer/login';
import collectionReducer from './reducer/collection';
import filterReducer from './reducer/filter';
import pageReducer from './reducer/page';
import menuReducer from './reducer/menu';
import orderReducer from './reducer/order';
import storeReducer from './reducer/store';
import migrationReducer from './reducer/migration';

export default combineReducers({
  migrationReducer: migrationReducer,
  storeReducer: storeReducer,
  orderReducer: orderReducer,
  menuReducer: menuReducer,
  pageReducer: pageReducer,
  filterReducer: filterReducer,
  collectionReducer: collectionReducer,
  sectionTemplateReducer: sectionTemplateReducer,
  sectionReducer: sectionReducer,
  templateReducer: templateReducer,
  itemImageReducer: itemImageReducer,
  itemReducer: itemReducer,
  userReducer: userReducer,
  customerReducer: customerReducer,
  loginReducer: loginReducer,

  // salesItemReducer: salesItemReducer,
  // vehicleBatchReducer: vehicleBatchReducer,
  // dashboardReducer: dashboardReducer,
  // salesReducer: salesReducer,
  // vehicleReducer: vehicleReducer,
  // batchReducer: batchReducer,
  // purchaseReducer: purchaseReducer,
  // supplierReducer: supplierReducer,
  // stockReducer: stockReducer,
});
