import {
  SUBMITTING_DONE,
  COLLECTION_FILTER_GET_ALL,
  COLLECTION_GET_ALL,
  COLLECTION_GET,
  COLLECTION_DROPDOWN_GET_ALL,
  COLLECTION_TREE_GET_ALL,
  SET_LOADING,
} from '../action/types';

const initialState = {
  collection: [],
  collectionListing: [],
  collectionFilterListing: [],
  collectionTreeListing: [],
  collectionDropdownListing: [],
  loading: true,
  submitting: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTING_DONE: {
      return {
        ...state,
        submitting: false,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case COLLECTION_GET_ALL: {
      return {
        ...state,
        collectionListing: action.payload,
        loading: false,
      };
    }

    case COLLECTION_GET: {
      return {
        ...state,
        collection: action.payload,
        loading: false,
      };
    }

    case COLLECTION_TREE_GET_ALL: {
      return {
        ...state,
        collectionTreeListing: action.payload,
        loading: false,
      };
    }

    case COLLECTION_DROPDOWN_GET_ALL: {
      return {
        ...state,
        collectionDropdownListing: action.payload,
        loading: false,
      };
    }

    case COLLECTION_FILTER_GET_ALL: {
      return {
        ...state,
        collectionFilterListing: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};
