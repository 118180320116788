import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Accordion, Card, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  getSection,
  postSectionTemplate,
  getAllHomeSection,
} from '../../../../redux/backend/action/section';

import {
  addMoreTemplate,
  removeSectionTemplate,
} from '../../../../redux/backend/action/sectionTemplate';

import { getAllSectionTemplate } from '../../../../redux/backend/action/sectionTemplate';
import Select from 'react-select';

import SectionList from '../sectionList';
import Detail from './detail';
import PreviewSection from './preview';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };
  componentDidMount() {
    this.props.getAllHomeSection();
    this.props.getAllSectionTemplate(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /**
     * new Project in town ?
     */
    if (this.state.id !== this.props.match.params.id) {
      this.props.getAllSectionTemplate(this.props.match.params.id);

      this.setState({
        id: this.props.match.params.id,
      });
    }
  }

  removeSectionHandle = (e, id) => {
    e.preventDefault();
    console.log('id', id);

    this.props.removeSectionTemplate(id);
  };

  addMoreHandle = (e, id) => {
    e.preventDefault();
    console.log('id', id);

    this.props.addMoreTemplate(id);
  };

  handleChange = (e, data) => {
    var name = e.target.name;
    var value = e.target.value;

    console.log('update-text', name, value);
    this.props.postSectionTemplate(data);
  };

  handleChangeDropdown = (selectedOption, data) => {
    var name = data.name;
    var value = selectedOption.value;
    console.log('eeedrrr', data);
    console.log('update-dropdown', name, value);
  };

  render() {
    const { sectionListing, sectionTemplateListing } = this.props;
    const section = sectionTemplateListing.section;

    console.log('sectionTemplate', sectionTemplateListing);

    if (!sectionTemplateListing.items || !sectionListing.items) {
      return <div>Loading..</div>;
    }

    console.log('ssss', sectionTemplateListing.items);
    return (
      <>
        <h4>
          Setting <small>Store template</small>
        </h4>

        <Row>
          <Col className='col-md-3'>
            <SectionList activeId={this.state.id} />
          </Col>

          <Col className='col-md-9'>
            <h4>Preview</h4>
            

            <div className='mb-20'>
              <PreviewSection />
            </div>

           
              <Detail sectionId={this.state.id} />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sectionListing: state.sectionReducer.sectionListing,
  sectionTemplateListing: state.sectionTemplateReducer.sectionTemplateListing,
});
export default connect(
  mapStateToProps,
  {
    getSection,
    getAllSectionTemplate,
    postSectionTemplate,
    addMoreTemplate,
    removeSectionTemplate,
    getAllHomeSection,
  }
)(Index);
