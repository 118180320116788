import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { getAllOrder } from '../../../redux/backend/action/order';

import ListTable from './table';
import { Link } from 'react-router-dom';

class Index extends Component {
  state = {};

  componentDidMount() {
    this.props.getAllOrder();
  }

  render() {
    const { orderListing } = this.props;

    if (!orderListing) {
      return <div>Loading..</div>;
    }

    return (
      <div>
        <h4>
          Order <small>Listing</small>
        </h4>

        <div className='plane-border-container'>
          <ListTable />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orderListing: state.orderReducer.orderListing,
});
export default connect(
  mapStateToProps,
  { getAllOrder }
)(Index);
