import React, { Component } from "react";

import { connect } from "react-redux";
import {
  getAllCollectionDropdown,
  postAddCollection,
} from "../../../redux/backend/action/collection";

import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/lib/Creatable";
import AsyncSelect from "react-select/lib/Async";
import DatePicker from "react-datepicker";
import moment from "moment";

import { withRouter } from "react-router-dom";

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class FormAdd extends Component {
  state = {
    mainCollection: [],
  };

  componentDidMount() {
    this.props.getAllCollectionDropdown();
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === "name") {
      this.setState({ name: value });
    } else if (name === "displayName") {
      this.setState({ displayName: value });
    }
  };

  handleChangeDropdown = (e) => {
    this.setState({
      mainCollection: e,
    });

    console.log(e);
  };
  handleChangeEditorDescription = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data });

    this.setState({ description: data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postAddCollection(this.state, this.props.history);
  };

  render() {
    const { collectionDropdownListing } = this.props;

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md="7">
              <div className="form-group">
                <label>Display Name: </label>
                <input
                  type="text"
                  name="displayName"
                  className="form-control form-control-sm"
                  value={this.props.displayName}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label>Identifier: </label>
                <input
                  type="text"
                  name="name"
                  className="form-control form-control-sm"
                  value={this.props.name}
                  onChange={this.handleChange}
                />
                <div className="text-muted font-12">
                  Identifier name must be unique
                </div>
              </div>
            </Col>

            <Col md="4">
              <div className="form-group mt-15">
                <button
                  type="submit"
                  id="btn_submit"
                  onClick={(e) => this.handleSubmit(e)}
                  style={{ marginTop: "10px" }}
                  className="btn-outline-success btn"
                >
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collectionDropdownListing: state.collectionReducer.collectionDropdownListing,
});

export default connect(
  mapStateToProps,
  { getAllCollectionDropdown, postAddCollection }
)(withRouter(FormAdd));
