import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  getCollection,
  getAllCollectionFilter,
} from '../../../redux/backend/action/collection';
import { getAllFilter } from '../../../redux/backend/action/filter';

import FormEdit from './form';

class Index extends Component {
  componentDidMount() {
    this.props.getCollection(this.props.match.params.id);
    this.props.getAllCollectionFilter(this.props.match.params.id);
    this.props.getAllFilter();
  }

  render() {
    return (
      <>
        <div>
          <FormEdit />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filterListing: state.filterReducer.filterListing,
  collection: state.collectionReducer.collection,
  collectionFilterListing: state.collectionReducer.collectionFilterListing,
});
export default connect(
  mapStateToProps,
  { getCollection, getAllFilter, getAllCollectionFilter }
)(Index);
