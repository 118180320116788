import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { getStore } from '../../redux/frontend/action/store';
import { getCountCartByCustomer } from '../../redux/frontend/action/cart';
import { getCountWatchlistByCustomer } from '../../redux/frontend/action/watchlist';
import { getCountOrderByCustomer } from '../../redux/frontend/action/order';

import Menu from '../../components/menu/index';
import Search from './filtering';
import store from '../../redux/frontend/reducer/store';

class Index extends Component {
  // state = {};

  componentDidMount() {
    this.props.getCountCartByCustomer();
    this.props.getCountWatchlistByCustomer();
    this.props.getCountOrderByCustomer();
  }

  render() {
    const { store, countCart, countWatchlist, countOrder } = this.props;
    if (!store.item) return <></>;

    /* width */
    let logoWidth = store.item.logoWidth
      ? store.item.logoWidth + 'px'
      : '200px';

    /* height */
    let logoHeight = store.item.logoHeight
      ? store.item.logoHeight + 'px'
      : '200px';

    const currentCustomer = localStorage.getItem('currentCustomer');

    return (
      <>
        <div className='header-block'>
          <Row>
            {/* logo */}
            <Col md='3' style={{ margin: '-25px', textAlign: 'center' }}>
              <Link to='/'>
                {store.item.logoSrc && (
                  <>
                    <img
                      src={store.item.fileBaseUrl + store.item.logoSrc}
                      width={logoWidth}
                      height={logoHeight}
                    />
                  </>
                )}
              </Link>
            </Col>

            {/* search */}
            <Col
              md='5'
              className='text-center'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div style={{ width: '100%' }}>
                <Search />
              </div>
            </Col>

            {/* links */}
            <Col
              md='4'
              className='mt-10 pr-20 text-left header-quick-link pt-5'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Link to='/watchlist' style={{ marginRight: '15px' }}>
                <i class='fa fa-heart-o' aria-hidden='true'></i> Wishlist
                <span
                  class='badge badge-pill badge-warning'
                  style={{
                    position: 'absolute',
                    marginTop: '-14px',
                    marginLeft: '-38px',
                  }}
                >
                  {currentCustomer ? countWatchlist.count : ""}
                </span>
              </Link>
              <Link to='/order' style={{ marginRight: '15px' }}>
                <i class='fa fa-server' aria-hidden='true'></i> Order
                <span
                  class='badge badge-pill badge-primary'
                  style={{
                    position: 'absolute',
                    marginTop: '-14px',
                    marginLeft: '-38px',
                  }}
                >
                  {currentCustomer ? countOrder.count : ""}
                </span>
              </Link>
              <Link to='/cart' style={{ marginRight: '15px' }}>
                <i class='fa fa-shopping-cart' aria-hidden='true'></i> Cart
                <span
                  class='badge badge-pill badge-success'
                  style={{
                    position: 'absolute',
                    marginTop: '-14px',
                    marginLeft: '-38px',
                  }}
                >
                  {currentCustomer ? countCart.count : ""}
                </span>
              </Link>

              {currentCustomer && (
                <>
                  <Link to='/logout'>
                    <i class='fa fa-power-off' aria-hidden='true'></i> Logout
                  </Link>
                </>
              )}

              {!currentCustomer && (
                <>
                  <Link to='/login'>
                    <i class='fa fa-unlock-alt' aria-hidden='true'></i> Login
                  </Link>
                </>
              )}
            </Col>
          </Row>
        </div>

        <Menu />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
  countCart: state.cartReducer.countCart,
  countWatchlist: state.watchlistReducer.countWatchlist,
  countOrder: state.orderReducer.countOrder,
});
export default connect(
  mapStateToProps,
  {
    getStore,
    getCountCartByCustomer,
    getCountWatchlistByCustomer,
    getCountOrderByCustomer,
  }
)(Index);
