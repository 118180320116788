import React, { useState, useEffect } from 'react';
import { Carousel, Image } from 'react-bootstrap';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Form, InputGroup } from "react-bootstrap";
import _ from "lodash";

const Index = (props) => {
  const {cartForm, setCartForm} = props;
  const [ formOptions, setFormOptions] = useState([]);

  useEffect(() => {
    let filters = props?.productRow?.item?.filterListingData?.items;
    filters = _.filter(filters, function (o) {
      return o?.id;
    });
    if(!filters) return;

    filters.forEach((filter) => {
      cartForm[filter.name] = '';
    })

    setCartForm({...cartForm});

  }, [props?.productRow]);


  const { productRow } = props;
  if (!productRow.item) return <>Loading..</>;

  const product = productRow.item;
  const filterListing = product.filterListingData;

  const handleChangeValue = (e) => {
    // formOptions[e.target.name] = e.target.value;
    // setFormOptions({...formOptions});
    
    cartForm[e.target.name] = e.target.value;
    setCartForm({...cartForm});
  }

return (
    <>
      <dl class='row'>
        {filterListing.items.map((item, index) => {
          return (
            <>
              <dt
                className='col-sm-2 proxima-nova-light text-uppercase'
                style={{ width: '55px' }}
              >
                {item.name}
              </dt>

              <dd className='col-sm-10 proxima-nova-bold text-uppercase'>
              
                {item.filterOptions && item.filterOptions.length == 0 && <>-</>}
                {item.filterOptions && (
                  <>
                    {item.filterOptions.map((filterOption, indexOption) => {
                      const isSelectedOption = cartForm[item.name] && cartForm[item.name] === filterOption?.name;
                      const activeLabel = isSelectedOption ? 'active' : '';

                      return (
                        <>

                          <input 
                            type="checkbox" 
                            class="btn-check hide" 
                            id={`btn-check-${filterOption.name}`} 
                            autocomplete="off"
                            name={item.name}
                            value={filterOption.name}
                            onChange={handleChangeValue} />
                          
                          <label 
                            class={`btn btn-sm btn-default mr-5 ${activeLabel}`} 
                            for={`btn-check-${filterOption.name}`}>{filterOption.name}</label>

                        </>
                      );
                    })}

                  </>
                )}
              </dd>

            </>
          );
        })}
      </dl>
    </>
  );
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
