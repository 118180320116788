import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Accordion, Card, Button } from 'react-bootstrap';

import Select from 'react-select';
import { Link, withRouter } from 'react-router-dom';

import Slideshow from './section/slideshow';
import ImageSlider from './section/imageSlider';
import LineBreaker from './section/lineBreaker';
import RowImages from './section/rowImages';
import ProductList from './section/productList';
import ImageWithText from './section/imageWithText';
import Testimonial from './section/testimonial';
import Footer from './section/footer';

class Index extends Component {
  state = {
    sectionName: '',
  };

  render() {
    const { sectionTemplateListing } = this.props;
    const section = sectionTemplateListing.section;

    if (!sectionTemplateListing.items) {
      return <div>Loading..</div>;
    }

    return (
      <>
        {/* slideshow */}
        {section.type === 'slideshow' && (
          <div
            className='plane-border-container'
            style={{ maxHeight: '250px', overflow: 'auto' }}
          >
            <Slideshow data={section.previewJson} />
          </div>
        )}

        {/* image-slider */}
        {section.type === 'image-slider' && (
          <div
            className='plane-border-container'
            style={{ maxHeight: '250px', overflow: 'auto' }}
          >
            <ImageSlider data={section.previewJson} />
          </div>
        )}

        {/* line-breaker */}
        {section.type === 'line-breaker' && (
          <LineBreaker data={section.previewJson} />
        )}

        {/* row-images */}
        {section.type === 'row-images' && (
          <div
            className='plane-border-container'
            style={{ maxHeight: '250px', overflow: 'auto' }}
          >
            <RowImages data={section.previewJson} />
          </div>
        )}

        {/* product-list */}
        {section.type === 'product-list' && (
          <div
            className='plane-border-container'
            style={{ maxHeight: '250px', overflow: 'auto' }}
          >
            <ProductList data={section.previewJson} />
          </div>
        )}

        {/* image-with-text */}
        {section.type === 'image-with-text' && (
          <div
            className='plane-border-container'
            style={{ maxHeight: '250px', overflow: 'auto' }}
          >
            <ImageWithText data={section.previewJson} />
          </div>
        )}

        {/* testimonial */}
        {section.type === 'testimonial' && (
          <div
            className='plane-border-container'
            style={{ maxHeight: '250px', overflow: 'auto' }}
          >
            <Testimonial data={section.previewJson} />
          </div>
        )}

        {/* testimonial */}
        {section.type === 'footer' && <Footer data={section.previewJson} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sectionTemplateListing: state.sectionTemplateReducer.sectionTemplateListing,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
