import React, { Component } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Form from "./form";
import SortList from "./sortList";
import Menu from "../../../../components/menuSetup/index";
import SectionList from "../sectionList";
import MenuTemplate from "./template";

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {
    // this.props.getAllItem();
  }

  render() {
    return (
      <div>
        <h4>
          Setting <small>Menu</small>
        </h4>

        <Row>
          <Col className="col-md-3">
            <SectionList activeId="menu" />
          </Col>
          <Col className="col-md-9">
            <h4 className="text-left">Preview</h4>
            <div className="form-container mb-20">
              <Menu />
            </div>

            <div className="plane-border-container">
              <Row>
                <Col md="6">
                  <h4 className="pl-15">
                    New Menu<small></small>
                  </h4>
                  <Form />
                </Col>
                <Col md="6">
                  <h4>
                    Menu Listing <small>Sort Menu</small>
                  </h4>
                  <SortList />
                </Col>
              </Row>
            </div>

            <div className="plane-border-container mt-20">
              <Row>
                <Col md="6">
                  <h4 className="pl-15">
                    Templating<small></small>
                  </h4>
                  <MenuTemplate />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //   itemListing: state.itemReducer.itemListing,
});
export default connect(
  mapStateToProps,
  {}
)(Index);
