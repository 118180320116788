export function isLoggedIn() {
  if (localStorage.getItem('jwt')) {
    return true;
  }
}

export function loggedInAdmin() {
  var current_user = localStorage.getItem('currentAdmin');

  if (current_user) {
    return JSON.parse(localStorage.getItem('currentAdmin'));
  }
}

export function loggedInCustomer() {
  var current_user = localStorage.getItem('currentCustomer');

  if (current_user) {
    return JSON.parse(localStorage.getItem('currentCustomer'));
  }
}

export function setSessionPharmacy(clinic) {
  localStorage.setItem('pharmacy', JSON.stringify(clinic));
  localStorage.setItem('clinic', JSON.stringify(clinic));
}

export function getSessionClinicId() {
  var clinic = localStorage.getItem('clinic');

  if (clinic) {
    var clinic = JSON.parse(localStorage.getItem('clinic'));

    return clinic.id;
  }
}

export function checkAuthAccess() {
  if (!isLoggedIn()) {
    console.log('Redirecting to login!');
    // history.push(`/login`)
    return;
  }
}

export function handleLogout() {
  localStorage.removeItem('currentAdmin', null);
  localStorage.removeItem('currentCustomer', null);
  localStorage.removeItem('jwt', null);
  localStorage.removeItem('pharmacy', null);
}
