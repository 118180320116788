import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getAllCollectionDropdown } from '../../../redux/backend/action/collection';
import { postAddCollection } from '../../../redux/backend/action/item';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FormAdd extends Component {
  state = {
    id: this.props.match.params.id,
    collection: [],
  };

  componentDidMount() {
    // this.props.getAllCollectionDropdown();
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    }
  };

  handleChangeDropdown = (e) => {
    this.setState({
      collection: e,
    });

    console.log(e);
  };
  handleChangeEditorDescription = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data });

    this.setState({ description: data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postAddCollection(this.state.id, this.state, this.props.history);
  };

  render() {
    const { collectionDropdownListing } = this.props;

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className='form-container'>
            <div className='form-group'>
              <label>Choose Collection: </label>

              <Select
                name={'parentId'}
                defaultValue={{
                  label: this.state.collection.label,
                  value: this.state.collection.value,
                }}
                options={collectionDropdownListing.dropdownOptions}
                // options={[
                //   { label: 'bbb', value: '11' },
                //   { label: 'aaa', value: '22' },
                // ]}
                onChange={(e) => this.handleChangeDropdown(e)}
              />
            </div>

            <div className='form-group'>
              <button
                type='submit'
                id='btn_submit'
                onClick={(e) => this.handleSubmit(e)}
                style={{ marginTop: '10px' }}
                className='btn-success btn'
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collectionDropdownListing: state.collectionReducer.collectionDropdownListing,
});

export default connect(
  mapStateToProps,
  { getAllCollectionDropdown, postAddCollection }
)(withRouter(FormAdd));
