import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Form from './form';
import SectionList from '../sectionList';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        <h4>
          Setting <small>General</small>
        </h4>

        <Row>
          <Col className='col-md-3'>
            <SectionList activeId='general' />
          </Col>
          <Col className='col-md-9'>

            <div className='plane-border-container'>
              <Row>
                <Col md='12'>
                  <h4 className='pl-15'>
                    General Setup<small></small>
                  </h4>
                  <Form />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});
export default connect(
  mapStateToProps,
  {}
)(Index);
