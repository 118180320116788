import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { getAllCollection } from '../../../redux/backend/action/collection';

import ListTable from './table';
import { Link } from 'react-router-dom';

class Index extends Component {
  state = {
    page: '',
    drugFilter: '',
  };

  componentDidMount() {
    this.props.getAllCollection();
  }

  render() {
    const { collectionListing } = this.props;

    if (!collectionListing) {
      return <div>Loading..</div>;
    }

    return (
      <div>
        <h4>
          Collection <small>Listing</small>
        </h4>

        <div className='plane-border-container'>
          <Link
            style={{
              right: '0',
              marginTop: '-10px',
              marginRight: '40px',
              position: 'absolute',
            }}
            className='btn btn-small btn-outline-success'
            to={'/collection/add'}
          >
            + ADD
          </Link>

          <ListTable />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  collectionListing: state.collectionReducer.collectionListing,
});
export default connect(
  mapStateToProps,
  { getAllCollection }
)(Index);
