import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import { SET_LOADING, VEHICLE_BATCH_GET_ALL, VEHICLE_BATCH_GET } from './types';

const url = apiBaseUrl + '/vehicleBatch';

export const getAllVehicleBatch = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};
  
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(
      `${url}/getAllVehicleBatch?${param}`,
      config
    );

    // dispatch
    dispatch({
      type: VEHICLE_BATCH_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getVehicleBatch = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.get(`${url}/getVehicleBatch?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: VEHICLE_BATCH_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

// export const postAddVehicleBatch = (formData, history) => async (dispatch) => {
//   try {
//     // headers
//     const config = {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('jwt')}`,
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     };

//     // response
//     const response = await axios.post(`${url}/postAddVehicleBatch`, formData, config);

//     if (response.data.result === 'SUCCESS') {
//       toast.success(response.data.message);
//       history.push('/setting/vehicleBatch');
//     } else if (response.data.result === 'ERROR') {
//       toast.error(response.data.message);
//     }
//   } catch (err) {}
// };

// export const postEditVehicleBatch = (id, formData, history) => async (dispatch) => {
//   try {
//     // headers
//     const config = {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('jwt')}`,
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     };

//     // response
//     const response = await axios.post(
//       `${url}/postEditVehicleBatch?id=${id}`,
//       formData,
//       config
//     );

//     if (response.data.result === 'SUCCESS') {
//       toast.success(response.data.message);
//       history.push('/setting/vehicleBatch');
//     } else if (response.data.result === 'ERROR') {
//       toast.error(response.data.message);
//     }
//   } catch (err) {}
// };

// export const getCountVehicleBatch = (data) => async (dispatch) => {
//   try {
//     // headers
//     const config = {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('jwt')}`,
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     };

//     // get
//     const response = await axios.get(`${url}/getCountVehicleBatch`, config);

//     // dispatch
//     // dispatch({ type: GET_COUNT_LAB_REPORTS, payload: response.data });
//   } catch (err) {}
// };
