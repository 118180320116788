import React, { Component } from 'react';
import { render } from 'react-dom';

import { connect } from 'react-redux';
import { getAllCollectionTree } from '../../redux/backend/action/collection';

import { withRouter, Link } from 'react-router-dom';

import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';

import { Label } from 'reactstrap';

class Index extends Component {
  state = {
    treeData: this.props.product.collectionTreeData.items,
  };

  componentDidMount() {}

  render() {
    return (
      <div style={{ height: 80 }} className='hide-sort-tree-move'>
        <SortableTree
          getNodeKey={({ node }) => node.id}
          treeData={this.state.treeData}
          theme={FileExplorerTheme}
          generateNodeProps={({ node, path }) => ({
            title: (
              <>
                {/* Assigned set BOLD */}
                {node.isAssignedToItem === true && (
                  <>
                    <span className='ml-10 badge badge-success'>
                      {node.title}
                    </span>
                  </>
                )}

                {/* UN-Assigned set Blur */}
                {node.isAssignedToItem !== true && (
                  <>
                    <Label
                      for={node.id}
                      className='ml-10'
                      style={{
                        position: 'absolute',
                        marginTop: '8px',
                        textDecoration: 'line-through',
                        fontSize: '13px',
                        opacity: '0.3',
                      }}
                    >
                      {node.title}
                    </Label>
                  </>
                )}
              </>
            ),
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {
    getAllCollectionTree,
  }
)(withRouter(Index));
