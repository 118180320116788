import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postSectionTemplate } from '../../../redux/backend/action/sectionTemplate';

import { Accordion, Card, Button } from 'react-bootstrap';
import Select from 'react-select';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Index extends Component {
  handleChange = (e, data) => {
    let section = this.props.section;

    let formData = new FormData();
    formData.append('value', e.target.value);
    formData.append('name', e.target.name);
    formData.append('id', data.id);

    this.props.postSectionTemplate(section.id, formData);
  };

  handleChangeEditorContent = (event, editor, data) => {
    let section = this.props.section;
    const editorContent = editor.getData();
    //console.log({ event, editor, editorContent, data });

    let formData = new FormData();
    formData.append('value', editorContent);
    formData.append('name', data.name);
    formData.append('id', data.id);

    this.props.postSectionTemplate(section.id, formData);
  };

  handleImageChange = (e, data) => {
    let section = this.props.section;

    let imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append('image', imageFile);
    formData.append('value', e.target.value);
    formData.append('name', e.target.name);
    formData.append('id', data.id);

    // console.log('formData', formData);
    this.props.postSectionTemplate(section.id, formData);
  };

  handleChangeDropdown = (e, data) => {
    const section = this.props.section;

    let formData = new FormData();
    formData.append('value', JSON.stringify(e));
    formData.append('name', data.name);
    formData.append('id', data.id);

    this.props.postSectionTemplate(section.id, formData);
  };

  handleChangeCheckbox = (e, data) => {
    if (e.isChecked) {
      console.log('checked');
    } else {
      console.log('UN-checked');
    }
    console.log(e);
    console.log(e.target.name);
    console.log(e.target.value);
    // const section = this.props.section;

    // let formData = new FormData();
    // formData.append('value', JSON.stringify(e));
    // formData.append('name', data.name);
    // formData.append('id', data.id);

    // this.props.postSectionTemplate(section.id, formData);
  };

  render() {
    const { item, index } = this.props;

    return (
      <>
        {item.isHeading === true && (
          <>
            <h4>{item.name}</h4>
          </>
        )}

        <>
          {/* input-text */}
          {item.inputType === 'text' && (
            <div className='mb-20'>
              {item.name}
              <input
                type='text'
                name={'input' + item.id + item.inputName}
                key={item.id}
                className='form-control form-control-sm'
                defaultValue={item.content}
                onChange={(e) =>
                  this.handleChange(e, {
                    id: item.id,
                  })
                }
              />
            </div>
          )}

          {/* input-dropdown */}
          {item.inputType === 'dropdown' && (
            <div className='mb-20'>
              {item.name}
              <Select
                name={'input' + item.id}
                defaultValue={{
                  label: item.contentJson.label,
                  value: item.contentJson.value,
                }}
                options={item.dropdownOptions}
                // options={[
                //   { label: 'bbb', value: '11' },
                //   { label: 'aaa', value: '22' },
                // ]}
                onChange={(e) =>
                  this.handleChangeDropdown(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />
            </div>
          )}

          {/* input-textArea */}
          {item.inputType === 'textArea' && (
            <div className='mb-20'>
              {item.name}
              <CKEditor
                editor={ClassicEditor}
                data={item.content}
                // onInit={(editor) => {
                //   // You can store the "editor" and use when it is needed.
                // }}
                // onChange={this.handleChangeEditorContent}
                onChange={(e, editor) =>
                  this.handleChangeEditorContent(e, editor, {
                    id: item.id,
                    name: item.name,
                  })
                }
                // onBlur={(event, editor) => {
                //   console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
              />
            </div>
          )}

          {/* input-image */}
          {item.inputType === 'image' && (
            <div className='mb-20'>
              {item.name}

              {item.content && (
                <div>
                  <img
                    src={'http://localhost' + item.content}
                    style={{ maxWidth: '250px', marginBottom: '10px' }}
                  />
                </div>
              )}

              <input
                type='file'
                name={item.inputName}
                key={item.id}
                className=''
                onChange={(e) =>
                  this.handleImageChange(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />
            </div>
          )}

          {/* input-productSelect */}
          {item.inputType === 'productSelect' && (
            <div className='mb-20'>
              {item.name}
              <Select
                name={'input' + item.id}
                defaultValue={item.contentJson}
                options={item.dropdownOptions}
                onChange={(e) =>
                  this.handleChangeDropdown(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />
            </div>
          )}

          {/* input-collectionSelect */}
          {item.inputType === 'collectionSelect' && (
            <div className='mb-20' style={{ minHeight: '350px' }}>
              {item.name}
              <Select
                name={'input' + item.id}
                defaultValue={item.contentJson}
                options={item.dropdownOptions}
                onChange={(e) =>
                  this.handleChangeDropdown(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />

              {/* input-checkbox */}
              {/* {item.inputType === 'checkbox' && (
            <div className='mb-20'>
              <input
                type='checkbox'
                name={'input' + item.id}
                checked
                key={item.id}
                id={item.id}
                className=''
                defaultValue={item.content}
                onChange={(e) =>
                  this.handleChangeCheckbox(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />
              <label for={item.id} className='ml-3'>
                {item.name}
              </label>
            </div>
          )} */}

              {/* <input
                type='file'
                name={'input' + item.id}
                key={item.id}
                className=''
                defaultValue={item.content}
                onChange={(e) =>
                  this.handleChange(e, {
                    key: item.id,
                    name: 'input' + item.id,
                  })
                }
              /> */}
            </div>
          )}
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  { postSectionTemplate }
)(Index);
