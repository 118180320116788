import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { getAllHomeSection } from '../../redux/frontend/action/section';
import Header from '../header';
import Footer from '../footer';

import Slideshow from './section/slideshow';
import ImageSlider from './section/imageSlider';
import LineBreaker from './section/lineBreaker';
import RowImages from './section/rowImages';
import ProductList from './section/productList';
import ImageWithText from './section/imageWithText';
import Testimonial from './section/testimonial';

class Index extends Component {
  componentDidMount() {
    this.props.getAllHomeSection({ hasPublished: true });
  }

  render() {
    const { sectionListing } = this.props;

    if (!sectionListing.items) {
      return <div>Loading..</div>;
    }

    return (
      <>
        <Header />

        {sectionListing.items.map((item, index) => {
          return (
            <>
              {/* slideshow */}
              {item.type === 'slideshow' && (
                <Slideshow data={item.publishJson} />
              )}

              {/* image-slider */}
              {item.type === 'image-slider' && (
                <ImageSlider data={item.publishJson} />
              )}

              {/* line-breaker */}
              {item.type === 'line-breaker' && (
                <LineBreaker data={item.publishJson} />
              )}

              {/* row-images */}
              {item.type === 'row-images' && (
                <RowImages data={item.publishJson} />
              )}

              {/* product-list */}
              {item.type === 'product-list' && (
                <ProductList data={item.publishJson} />
              )}

              {/* image-with-text */}
              {item.type === 'image-with-text' && (
                <ImageWithText data={item.publishJson} />
              )}

              {/* testimonial */}
              {item.type === 'testimonial' && (
                <Testimonial data={item.publishJson} />
              )}
            </>
          );
        })}

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sectionListing: state.sectionReducer.sectionListing,
});
export default connect(
  mapStateToProps,
  { getAllHomeSection }
)(Index);
