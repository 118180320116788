import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  getAllCart,
  postChangeQuantity,
  deleteCart,
} from '../../redux/frontend/action/cart';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// import history from '../../../history';

class ListTable extends Component {
  state = {
    page: '',
  };

  handleQuantityChange = (e, id) => {
    let value = e.target.value;
    this.props.postChangeQuantity(id, { quantity: value });
  };

  handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete it?')) {
      this.props.deleteCart(id);
    }
  };

  createMarkup = (text) => {
    return { __html: text };
  };

  getOptions = (optionString) => {
    const data = [];
    const obj = JSON.parse(optionString);
    for (let name in obj) {
      data.push(`<b>${name}</b>: ${obj[name]}`)
    }

    return data;
  }

  render() {
    const { cartListing, store } = this.props;

    if (!store.item) {
      return <div></div>;
    }

    if (!cartListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <div
          style={{
            padding: '10px',
            marginBottom: '3px',
          }}
        >
          <Row>
            <Col md='3'></Col>
            <Col md='3'>Name</Col>
            <Col md='2' className='text-center'>
              Price
            </Col>
            <Col md='2' className='text-center'>
              Option(s)
            </Col>
            <Col md='1' className='text-center'>
              Quantity
            </Col>
            <Col md='1' className='text-center'></Col>
          </Row>
        </div>

        {cartListing.items.length == 0 && (
          <>
            <div style={{ background: 'rgb(239 239 239)', padding: '10px' }}>
              No product in cart.
            </div>
          </>
        )}

        {cartListing.items.map((cart, index) => {
          const product = cart.item;
          const optionsHtml = this.getOptions(cart.options);
          return (
            <>
              <div style={{ background: 'rgb(239 239 239)', padding: '10px' }}>
                <Row>
                  <Col md='3'>
                    <img
                      style={{ width: '200px', maxHeight: '300px' }}
                      src={store.item.fileBaseUrl + product.imageUrl}
                      alt='a'
                    />
                  </Col>
                  <Col md='3'>
                    <h5 className='proxima-nova-bold'>{product.name}</h5>
                  </Col>
                  <Col md='2' className='text-center'>
                    {product.sellPriceLabel}
                  </Col>
                  <Col md='2' className='text-center'>
                    {<div
                      dangerouslySetInnerHTML={this.createMarkup(
                        optionsHtml.join("<br />")
                      )}
                    ></div>}
                  </Col>
                  <Col md='1' className='text-center'>
                  {cart.quantity}
                  </Col>
                  <Col md='1' className='text-center'>
                    <Link
                      onClick={(e) => this.handleDelete(e, cart.id)}
                      className='btn btn-outline-danger btn-sm'
                    >
                      X
                    </Link>
                  </Col>
                </Row>
              </div>
            </>
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
  cartListing: state.cartReducer.cartListing,
});
export default connect(
  mapStateToProps,
  { getAllCart, postChangeQuantity, deleteCart }
)(ListTable);
