import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  getProfile,
  postChangePassword,
} from '../../../redux/backend/action/user';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

class FormEdit extends Component {
  state = {};

  componentDidMount() {
    this.props.getProfile();
  }

  componentWillReceiveProps(recievedProps) {
    const user = recievedProps.user.item;

    if (user) {
      this.setState({
        id: user.id,
        name: user.name,
        address: user.address,
        phone: user.phone,
        mobile: user.mobile,
        email: user.email,
        vehicleId: user.vehicleId,
        vehicleName: user.vehicleName,
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'password') {
      this.setState({ password: value });
    } else if (name === 'repeatPassword') {
      this.setState({ repeatPassword: value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postChangePassword(this.state, this.props.history);
  };

  render() {
    const { user } = this.props;

    if (!user) {
      return <>Loading..</>;
    }

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          {/* <Row>
            <Col md='8'>
              <div className='form-group'>
                Verification code will be sent to <b>{this.state.email}</b>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>New Password: </label>
                <input
                  type='password'
                  name='password'
                  className='form-control'
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>Repeat Password: </label>
                <input
                  type='password'
                  name='repeatPassword'
                  className='form-control'
                  value={this.state.repeatPassword}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
});

export default connect(
  mapStateToProps,
  { getProfile, postChangePassword }
)(withRouter(FormEdit));
