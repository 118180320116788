import {
  SUBMITTING_STARTED,
  SUBMITTING_DONE,
  FILTER_GET_ALL,
  FILTER_GET,
  SET_LOADING,
} from '../action/types';

const initialState = {
  filter: [],
  filterListing: [],
  loading: true,
  submitting: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTING_STARTED: {
      return {
        ...state,
        submitting: true,
      };
    }

    case SUBMITTING_DONE: {
      return {
        ...state,
        submitting: false,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case FILTER_GET_ALL: {
      return {
        ...state,
        filterListing: action.payload,
        loading: false,
      };
    }

    case FILTER_GET: {
      return {
        ...state,
        filter: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};
