import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getAllCart } from '../../redux/frontend/action/cart';

import Header from '../header';
import Footer from '../footer';
import ListTable from './table';
import OrderSummary from './summary';

class Index extends Component {
  componentDidMount() {
    this.props.getAllCart();
  }
  render() {
    return (
      <>
        <Header />
        <div className='main-container' style={{ width: '100%' }}>
          {/* CART LIST */}
          <div
            style={{
              maxWidth: '90rem',
              margin: '0px auto',
              minHeight: '400px',
            }}
          >
            <h2>My Cart</h2>

            <Row>
              <Col md='8'>
                <ListTable />
              </Col>
              <Col md='4'>
                <OrderSummary />
              </Col>
            </Row>
          </div>
        </div>

        {/* footer */}
        <Footer />
        {/*  */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartListing: state.cartReducer.cartListing,
});
export default connect(
  mapStateToProps,
  { getAllCart }
)(withRouter(Index));
