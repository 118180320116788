import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Accordion, Card, Button } from 'react-bootstrap';
// import Detail from './detail';
import {
  getSection,
  postSectionTemplate,
} from '../../../redux/backend/action/section';

import {
  addMoreTemplate,
  removeSectionTemplate,
} from '../../../redux/backend/action/sectionTemplate';

import { getAllSectionTemplate } from '../../../redux/backend/action/sectionTemplate';
import Select from 'react-select';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import LoopItem from './loop';
import InputItem from './inputItem';

class Index extends Component {
  componentDidMount() {
    // this.props.getSection(this.props.match.params.id);
    // if (this.props.section.item) {
    //   var templateJson = this.props.section.item.templateJson;
    //   templateJson.map((item, index) => {
    //     if (item.formInput && item.formInput.type === 'text') {
    //       var name = item.formInput.name;
    //       var value = item.formInput.value;
    //       if (name == 'heading') {
    //         this.setState({ heading: value });
    //       }
    //     }
    //   });
    // }
  }

  removeSectionHandle = (e, id) => {
    e.preventDefault();
    console.log('id', id);

    this.props.removeSectionTemplate(id);
  };

  addMoreHandle = (e, id) => {
    e.preventDefault();
    console.log('id', id);

    this.props.addMoreTemplate(id);
  };

  handleChange = (e, data) => {
    var name = e.target.name;
    var value = e.target.value;

    //{ key: index, data: 'label' }
    console.log('eeevv', data);
    // if (name === 'heading') {
    //   this.setState({ heading: value });
    // } else if (name === 'text') {
    //   this.setState({ text: value });
    // } else if (name === 'buttonLabel') {
    //   this.setState({ buttonLabel: value });
    // } else if (name === 'buttonLink') {
    //   this.setState({ buttonLink: value });
    // }

    console.log('update-text', name, value);
    this.props.postSectionTemplate(data);
  };

  handleChangeDropdown = (selectedOption, data) => {
    var name = data.name;
    var value = selectedOption.value;
    console.log('eeedrrr', data);
    console.log('update-dropdown', name, value);

    // console.log('eeee', e);
    // console.log('ssss', selectedOption);
    // if (name === 'alignment') {
    //   this.setState({
    //     alignment: { label: selectedOption.label, value: selectedOption.value },
    //   });
    // }
  };

  render() {
    const { sectionTemplateListing } = this.props;
    const section = sectionTemplateListing.section;

    console.log('sectionTemplate', sectionTemplateListing);

    if (!sectionTemplateListing.items) {
      return <div>Loading..</div>;
    }

    console.log('ssss', sectionTemplateListing.items);
    return (
      <>
        <h4> {section.name}</h4>

        <div className='form-container'>
          {sectionTemplateListing.items.map((item, index) => {
            return (
              <>
                {/* input-text */}

                <div>
                  {item.isLoop === false && (
                    <>
                      <InputItem item={item} section={section} />
                    </>
                  )}
                </div>

                {/* loop-text */}
                <div>
                  {item.isLoop === true && (
                    <>
                      {/* label */}
                      <div>
                        {item.isHeading === true && (
                          <>
                            <b>{item.name}</b>
                          </>
                        )}
                      </div>

                      {/*  */}
                      {/*  */}

                      {item.childItems.map((itemChild, indexChild) => {
                        return (
                          <>
                            {/* further loop */}
                            {itemChild.isLoop === true && (
                              <>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <Accordion.Toggle
                                        as={Button}
                                        variant='link'
                                        eventKey={indexChild}
                                      >
                                        {itemChild.name}
                                      </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey={indexChild}>
                                      <Card.Body>
                                        {itemChild.childItems.map(
                                          (itemChildLast, indexChildLast) => {
                                            return (
                                              <>
                                                <InputItem
                                                  item={itemChildLast}
                                                  section={section}
                                                />

                                                {/*  */}

                                                {indexChildLast.isLoop ===
                                                  true && (
                                                  <>
                                                    {indexChildLast.childItems.map(
                                                      (
                                                        itemChildLastFinal,
                                                        indexChildLastFinal
                                                      ) => {
                                                        return (
                                                          <>
                                                            abc
                                                            {/* <InputItem
                                                              item={
                                                                itemChildLastFinal
                                                              }
                                                              section={section}
                                                            /> */}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                )}

                                                {/*  */}
                                              </>
                                            );
                                          }
                                        )}

                                        <div className='mt-10'>
                                          <Button
                                            className='btn btn-xs btn-danger'
                                            onClick={(e) =>
                                              this.removeSectionHandle(
                                                e,
                                                itemChild.id
                                              )
                                            }
                                          >
                                            X Remove Section
                                          </Button>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </>
                            )}
                            {/* items */}
                            {itemChild.isLoop === false && (
                              <>
                                B-{itemChild.name}
                                <InputItem item={itemChild} section={section} />
                              </>
                            )}
                          </>
                        );
                      })}

                      {/*  */}
                      {/*  */}

                      <div className='mt-10'>
                        <Button
                          className='btn btn-xs btn-outline-success'
                          onClick={(e) => this.addMoreHandle(e, item.id)}
                        >
                          + Add More
                        </Button>
                      </div>
                    </>
                  )}
                </div>

                {/* input-dropdown */}
                <div>
                  {/* {item.json.formInput &&
                    item.json.formInput.type === 'dropdown' && (
                      <>
                        {item.json.formInput.label}
                        <Select
                          name={item.json.formInput.name}
                          // defaultValue={{
                          //   label: item.jsonformInput.value,
                          //   value: item.formInput.value,
                          // }}
                          options={[{ value: 'aa', label: 'ddd' }]}
                          onChange={(e) =>
                            this.handleChangeDropdown(e, {
                              key: index,
                              name: item.json.formInput.name,
                            })
                          }
                        />
                      </>
                    )} */}
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sectionTemplateListing: state.sectionTemplateReducer.sectionTemplateListing,
});
export default connect(
  mapStateToProps,
  {
    getSection,
    getAllSectionTemplate,
    postSectionTemplate,
    addMoreTemplate,
    removeSectionTemplate,
  }
)(Index);
