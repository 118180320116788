import React, { Component } from 'react';
import { Carousel, Card, CardGroup, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import ImageWithTextLeft from './imageWithTextLeft';
import ImageWithTextRight from './imageWithTextRight';

class Index extends Component {
  createMarkup = (text) => {
    return { __html: text };
  };

  render() {
    const { data } = this.props;
    if (!data || !data.items) return <></>;

    let heading = data.heading;
    let headerAlignment = data.headerAlignment;
    headerAlignment = headerAlignment ? headerAlignment : 'left';

    let items = data.items;

    return (
      <>
        <div style={{ maxWidth: '75rem', margin: '0px auto' }}>
          <h1 className={'text-' + headerAlignment}>{heading}</h1>
        </div>

        <div style={{ maxWidth: '75rem', margin: '0px auto' }}>
          <Row>
            {items.map((item, index) => {
              return (
                <Col className='12 pr-0'>
                  <CardGroup>
                    <Card style={{ width: '100%', minHeight: '165px' }}>
                      <Card.Body className='text-center'>
                        <Card.Title>
                          <i class='fa fa-quote-left' aria-hidden='true'></i>
                        </Card.Title>

                        <Card.Text>
                          <p>
                            <div
                              dangerouslySetInnerHTML={this.createMarkup(
                                item.text
                              )}
                              className='editor'
                            ></div>
                          </p>
                        </Card.Text>

                        <Card.Text className='bold'>
                          <span
                            style={{
                              position: 'absolute',
                              marginTop: '-7px',
                              marginLeft: '-20px',
                            }}
                          >
                            __
                          </span>
                          {item.authorName}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </CardGroup>
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
