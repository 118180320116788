import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getOrder, postSetFulfill } from '../../../redux/backend/action/order';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';

import { withRouter } from 'react-router-dom';
import { Textarea } from 'glamorous';

class FormAdd extends Component {
  state = {
    order: '',
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /**
     * new Project in town ?
     */
    if (this.state.id !== this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id);

      this.setState({
        id: this.props.match.params.id,
        order: this.props.order,
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'remark') {
      this.setState({ remark: value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.postSetFulfill(this.state.id, this.state, this.props.history);
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md='6'>
              <div className='form-group'>
                <label className='mr-10'>Set Status: </label>
                <b>FULFILLED</b>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='6'>
              <div className='form-group'>
                <label>Remark: </label>
                <Textarea
                  name='remark'
                  className='form-control form-control-sm'
                  onChange={this.handleChange}
                ></Textarea>
              </div>
            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.orderReducer.order,
});

export default connect(
  mapStateToProps,
  { getOrder, postSetFulfill }
)(withRouter(FormAdd));
