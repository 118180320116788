import React, { Component, useState } from 'react';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { postItemFilter } from '../../../redux/backend/action/item';

class Index extends Component {
  handleOnChange = (e, productId, filterSlug, filterOptionSlug) => {
    // console.log(e.target.checked);
    // console.log(productId, filterSlug, filterOptionSlug);

    this.props.postItemFilter(productId, {
      filterSlug: filterSlug,
      filterOptionSlug: filterOptionSlug,
      checked: e.target.checked,
    });
  };

  render() {
    const { productRow } = this.props;
    if (!productRow.item) return <>Loading..</>;

    const product = productRow.item;
    const filterListing = product.filterListingData;

    console.log('filterListing', filterListing);
    return (
      <>
        <dl class='row'>
          {filterListing.items.map((item, index) => {
            return (
              <>
                <dt class='col-sm-2 proxima-nova-light'>{item.name}</dt>
                <dd class='col-sm-10 proxima-nova-bold'>
                  {item.filterOptions.length == 0 && <>-</>}
                  {item.filterOptions && (
                    <>
                      {item.filterOptions.map((filterOption, indexOption) => {
                        return (
                          <div>
                            <input
                              type='checkbox'
                              defaultChecked={
                                filterOption.hasItemFilter === true
                                  ? 'checked'
                                  : false
                              }
                              id={filterOption.id}
                              // ref='complete'
                              onChange={(e) =>
                                this.handleOnChange(
                                  e,
                                  product.id,
                                  item.slug,
                                  filterOption.slug
                                )
                              }
                            />{' '}
                            <label
                              for={filterOption.id}
                              className='proxima-nova-light'
                            >
                              {filterOption.name}
                            </label>
                          </div>
                        );
                      })}
                    </>
                  )}
                </dd>
              </>
            );
          })}
        </dl>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  { postItemFilter }
)(withRouter(Index));
