import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postEditStoreHeaderTemplate } from '../../../../redux/backend/action/store';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

class Index extends Component {
  state = {
    logoUrl: '',
    logoWidth: '',
    logoHeight: '',
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    if (!this.state.store && this.props.store.item) {
      this.setState({
        logoUrl: this.props.store.item.logoSrc,
        logoWidth: this.props.store.item.logoWidth,
        logoHeight: this.props.store.item.logoHeight,
        store: this.props.store,
      });
    }
  }

  handleBlurWidth = (e) => {
    let formData = new FormData();
    formData.append('logoWidth', this.state.logoWidth);

    /* post */
    if (this.props.store) {
      this.props.postEditStoreHeaderTemplate(
        this.props.store.item.id,
        formData
      );
    }
  };

  handleBlurHeight = (e) => {
    let formData = new FormData();
    formData.append('logoHeight', this.state.logoHeight);

    /* post */
    if (this.props.store) {
      this.props.postEditStoreHeaderTemplate(
        this.props.store.item.id,
        formData
      );
    }
  };

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    console.log(name, value);
    if (name == 'logoWidth') {
      this.setState({
        logoWidth: value,
      });
    } else if (name == 'logoHeight') {
      this.setState({
        logoHeight: value,
      });
    }
  };

  handleImageChange = (e, data) => {
    let imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append('image', imageFile);

    /* post */
    if (this.props.store) {
      this.props.postEditStoreHeaderTemplate(
        this.props.store.item.id,
        formData
      );
    }
  };

  render() {
    const { store } = this.props;
    if (!store.item) return <>Loading..</>;

    /* width */
    let logoWidth = store.item.logoWidth
      ? store.item.logoWidth + 'px'
      : '200px';

    /* height */
    let logoHeight = store.item.logoHeight
      ? store.item.logoHeight + 'px'
      : '200px';

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className='form-container'>
            <div className='form-group'>
              <Row>
                <Col md='12'>
                  <div className=''>Upload Logo:</div>

                  <input
                    type='file'
                    name='logoUrl'
                    onChange={this.handleImageChange}
                  />
                </Col>
              </Row>

              <Row className='mt-35'>
                <Col md='5'>
                  <div className=''>Logo Width:</div>
                  <input
                    type='text'
                    name='logoWidth'
                    placeholder='e.g. 200'
                    className='form-control form-control-sm'
                    defaultValue={store.item.logoWidth}
                    onChange={this.handleChange}
                    onBlur={this.handleBlurWidth}
                  />
                </Col>

                <Col md='5'>
                  <div className=''>Logo Height:</div>
                  <input
                    type='text'
                    name='logoHeight'
                    placeholder='e.g. 200'
                    className='form-control form-control-sm'
                    defaultValue={store.item.logoHeight}
                    onChange={this.handleChange}
                    onBlur={this.handleBlurHeight}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});

export default connect(
  mapStateToProps,
  { postEditStoreHeaderTemplate }
)(withRouter(Index));
