import {
  SUBMITTING_STARTED,
  SUBMITTING_DONE,
  CUSTOMER_GET_ALL,
  CUSTOMER_GET,
  SET_LOADING,
} from '../action/types';

const initialState = {
  customer: [],
  customerListing: [],
  loading: true,
  submitting: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTING_STARTED: {
      return {
        ...state,
        submitting: true,
      };
    }

    case SUBMITTING_DONE: {
      return {
        ...state,
        submitting: false,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case CUSTOMER_GET_ALL: {
      return {
        ...state,
        customerListing: action.payload,
        loading: false,
      };
    }

    case CUSTOMER_GET: {
      return {
        ...state,
        customer: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};
