import React, { useState, useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead'; // ES2015
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { apiBaseUrl } from '../../../../../constant/api';
const url = apiBaseUrl + '/frontend/item';

const AsyncExample = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [redirectUrl, redirectNow] = useState('');

  const handleSearch = (query) => {
    setIsLoading(true);

    // fetch(`${SEARCH_URI}?q=${query}+in:login&page=1&per_page=50`)
    fetch(`${url}/getAllItem?q=${query}+in:login&page=1&per_page=50`)
      .then((resp) => resp.json())
      .then(({ items }) => {
        const options = items.map((i) => ({
          // avatar_url: i.avatar_url,
          id: i.id,
          name: i.name,
          slug: i.slug,
        }));

        setOptions(options);
        setIsLoading(false);
      });
  };

  return (
    <>
      {redirectUrl && <Redirect push to={redirectUrl} />}

      <AsyncTypeahead
        id='async-example'
        isLoading={isLoading}
        labelKey='name'
        minLength={3}
        onSearch={handleSearch}
        options={options}
        placeholder='Search...'
        renderMenuItemChildren={(option, props) => (
          <>
            <span>{option.name}</span>
          </>
        )}
      />
    </>
  );
};

export default AsyncExample;
