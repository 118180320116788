import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getItem } from '../../../redux/backend/action/item';

import { Card, Button, Row, Col } from 'react-bootstrap';

import Form from './form';
import List from './list';
import TreeCollection from './treeCollection';
import ManageNav from '../nav';
import FilterOption from '../edit/filterOption';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {
    this.props.getItem(this.props.match.params.id);
  }

  render() {
    const { productRow } = this.props;
    if (!productRow.item) return <>Loading..</>;

    const product = productRow.item;

    return (
      <>
      <h4>Assign Collection: <small>{product.name}  (#{product.code})</small></h4>
        <div class='row'>
          <div class='col-2'>
            <ManageNav itemId={product.id} activeLabel='assign-collection' />
          </div>

          <div class='col-6'>
            <div class='tab-content' id='v-pills-tabContent'>
              <div className='plane-border-container'>
                <TreeCollection itemId={product.id} />
              </div>
            </div>
          </div>

          <div class='col-4'>
            <div class='tab-content' id='v-pills-tabContent'>
              <div className='plane-border-container'>
              <h4 style={{ marginTop: '20px' }}>Filters</h4>
              <FilterOption />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  { getItem }
)(Index);
