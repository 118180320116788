import React, { Component } from 'react';
import { render } from 'react-dom';

import { connect } from 'react-redux';
import { getAllCollectionTree } from '../../../redux/backend/action/collection';

import { postAddCollection } from '../../../redux/backend/action/item';

import { withRouter, Link } from 'react-router-dom';

import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { Label } from 'reactstrap';

class Index extends Component {
  state = {
    itemId: this.props.itemId,
    treeData: {},
    dataLoaded: false,
  };
  componentDidMount() {
    var data = { itemId: this.state.itemId };
    this.props.getAllCollectionTree(data);
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /* first time load, set sate treeData */
    if (this.state.dataLoaded === false && this.props.collectionTreeListing.items) {

      var treeData = this.props.collectionTreeListing.items;
      this.setState({
        treeData: treeData,
        dataLoaded: true,
      });
    }
  }

  handleCheck = (e, id, children) => {
    console.log(e.target.checked, id, children);
    var itemId = this.props.itemId;
    var data = {
      collectionId: id,
      isChecked: e.target.checked,
      children: children,
      treeData: this.state.treeData,
    };
    this.props.postAddCollection(itemId, data);
  };

  render() {
    return (
      <div style={{ height: 1000 }} className='hide-sort-tree-move'>
        <SortableTree
          getNodeKey={({ node }) => node.id}
          treeData={this.state.treeData}
          generateNodeProps={({ node, path }) => ({
            title: (
              <>
                <input
                  type='checkbox'
                  defaultChecked={
                    node.isAssignedToItem === true ? 'checked' : ''
                  }
                  style={{ width: '16px', height: '18px', marginTop: '9px' }}
                  onChange={(e) => this.handleCheck(e, node.id, node.children)}
                  id={node.id}
                />
                <Label
                  for={node.id}
                  className='ml-10'
                  style={{ position: 'absolute', marginTop: '8px' }}
                >
                  {node.title}
                </Label>
              </>
            ),
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  collectionTreeListing: state.collectionReducer.collectionTreeListing,
});

export default connect(
  mapStateToProps,
  {
    getAllCollectionTree,
    postAddCollection,
  }
)(withRouter(Index));
