import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getAllFilter,
  deleteFilter,
} from '../../../redux/backend/action/filter';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import history from '../../../history';
import PaginateTable from '../../../components/PaginateTable';

class ListTable extends Component {
  state = {
    page: '',
  };

  handlePageChange = (page) => {
    this.setState({ Filter_page: page });

    /* get all filters */
    let param = this.state;

    /* send current filter immediately as state can't get current value */
    param.Filter_page = page;

    this.reloadGrid(param);
  };

  handleFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    /* get all filters */
    let param = this.state;

    if (name === 'filterName') {
      this.setState({ filterName: value });
      param.filterName = value;
    }

    this.reloadGrid(param);
  };

  handleDeleteFilter = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete this item?')) {
      this.props.deleteFilter(id);
    }
  };

  reloadGrid = (param) => {
    this.props.getAllFilter(param);
  };

  render() {
    const { filterListing } = this.props;

    if (!filterListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <Table responsive size='sm' className='tbl-list-view'>
          <thead>
            <tr>
              <th style={{ width: '300px' }}>Name</th>
              <th>Options</th>
              <th style={{ width: '215px' }}></th>
            </tr>
            <tr className='filters' style={{ background: '#E3EAF2' }}>
              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterName'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filterListing.items.map((item, index) => {
              return (
                <>
                  <tr>
                    <td>
                      {item.name !== item.internalName && item.internalName && (
                        <>
                          {item.name} ({item.internalName})
                        </>
                      )}

                      {item.name === item.internalName && <>{item.name}</>}
                      {item.name && !item.internalName && <>{item.name}</>}
                    </td>

                    <td>
                      {item.filterOptions && (
                        <>
                          <div
                            className='mb-15'
                            style={{ overflow: 'auto', maxHeight: '100px' }}
                          >
                            {item.filterOptions.map((option, optionIndex) => {
                              return (
                                <>
                                  <div>{option.name}</div>
                                </>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </td>

                    <td align='right'>
                      <Link
                        className='btn btn-small btn-outline-default'
                        to={'/filter/option/add/' + item.id}
                        style={{ marginLeft: '5px' }}
                      >
                        Add Option
                      </Link>{' '}
                      <Link
                        className='btn btn-small btn-outline-default'
                        to={'/filter/edit/' + item.id}
                        style={{ marginLeft: '5px' }}
                      >
                        Edit
                      </Link>{' '}
                      <Link
                        className='btn btn-small btn-outline-danger'
                        onClick={(e) => this.handleDeleteFilter(e, item.id)}
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>

        <PaginateTable
          listing={this.props.filterListing}
          activePage={this.state.page}
          handlePageChange={this.handlePageChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filterListing: state.filterReducer.filterListing,
});
export default connect(
  mapStateToProps,
  { getAllFilter, deleteFilter }
)(ListTable);
