import React, { Component } from 'react';

import { connect } from 'react-redux';
import FormAdd from './form';

import CollectionTopNav from '../../collection/top-nav';

class Index extends Component {
  render() {
    return (
      <>
        <CollectionTopNav activeLabel='all-filter' />
        <div>
          <FormAdd />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(Index);
