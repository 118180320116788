import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllUser } from '../../../redux/backend/action/user';
import { getAllVehicleBatch } from '../../../redux/backend/action/vehicleBatch';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import history from '../../../history';
import PaginateTable from '../../../components/PaginateTable';
import VehicleBatchTable from './tableVehicleBatch';

class ListTable extends Component {
  state = {
    page: '',
    selectedStaffId: '',
  };

  handleGetUserVehicleBatch = (staff, index) => {
    this.setState({ selectedStaffId: staff.id, index: index });

    if (staff) {
      this.props.getAllVehicleBatch({ staffId: staff.id });
    }
  };

  handlePageChange = (page) => {
    this.setState({ drugGroup_page: page });

    /* get all filters */
    let param = this.state;

    /* send current filter immediately as state can't get current value */
    param.Druggroup_page = page;

    this.reloadGrid(param);
  };

  handleFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    /* get all filters */
    let param = this.state;

    if (name === 'filterCode') {
      this.setState({ filterCode: value });
      param.filterCode = value;
    } else if (name === 'filterName') {
      this.setState({ filterName: value });
      param.filterName = value;
    } else if (name === 'filterUnit') {
      this.setState({ filterUnit: value });
      param.filterUnit = value;
    } else if (name === 'filterQtyAlert') {
      this.setState({ filterQtyAlert: value });
      param.filterQtyAlert = value;
    }

    this.reloadGrid(param);
  };

  reloadGrid = (param) => {
    this.props.getAllUser(param);
  };

  render() {
    const { userListing } = this.props;

    if (!userListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <Table responsive size='sm'>
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Mobile</th>
              {/* <th>Vehicle</th> */}
              <th>Created At</th>
              <th style={{ width: '115px' }}></th>
            </tr>
            <tr className='filters' style={{ background: '#E3EAF2' }}>
              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterCode'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterName'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterAddress'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterPhone'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterMobile'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              {/* <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterVehicle'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th> */}

              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userListing.items.map((item, index) => {
              return (
                <>
                  <tr>
                    <td>{item.code}</td>
                    <td>{item.name}</td>
                    <td>{item.address}</td>
                    <td>{item.phone}</td>
                    <td>{item.mobile}</td>
                    {/* <td>
                      {item.vehicleId && (
                        <>
                          {item.vehicleName} <br />
                          <Link
                            to='#'
                            onClick={() =>
                              this.handleGetUserVehicleBatch(item, index)
                            }
                          >
                            {item.vehicleItemCountLabel} Items
                          </Link>
                        </>
                      )}
                    </td> */}
                    <td>{item.createdAt}</td>
                    <td align='right'>
                      <Link
                        className='btn btn-small btn-outline-default'
                        to={'/user/edit/' + item.id}
                        style={{ marginLeft: '5px' }}
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>

                  <tr
                    style={{
                      display:
                        this.state.selectedStaffId &&
                        this.state.selectedStaffId === item.id
                          ? ''
                          : 'none',
                    }}
                  >
                    <td colSpan='8' style={{ background: '#EDF9D3' }}>
                      <VehicleBatchTable userId={item.id} />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>

        <PaginateTable
          listing={this.props.userListing}
          activePage={this.state.page}
          handlePageChange={this.handlePageChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userListing: state.userReducer.userListing,
  vehicleBatchListing: state.vehicleBatchReducer.vehicleBatchListing,
});
export default connect(
  mapStateToProps,
  { getAllUser, getAllVehicleBatch }
)(ListTable);
