import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  getCollection,
  getAllCollectionFilter,
} from '../../../redux/backend/action/collection';
import { getFilter } from '../../../redux/backend/action/filter';

import FormManage from './form';
import CollectionTopNav from '../../collection/top-nav';

class Index extends Component {
  componentDidMount() {
    this.props.getFilter(this.props.match.params.id);
  }

  render() {
    return (
      <>
        <CollectionTopNav activeLabel='all-filter' />

        <div>
          <FormManage />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filterReducer.filter,
});
export default connect(
  mapStateToProps,
  { getFilter }
)(Index);
