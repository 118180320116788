import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class ListTable extends Component {
  state = {
    page: '',
  };

  handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete it?')) {
      this.props.deleteOrder(id);
    }
  };

  render() {
    const { orderItems, store } = this.props;

    if (!store.item) {
      return <></>;
    }

    if (!orderItems.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        {orderItems.items.map((orderItem, index) => {
          let product = orderItem.item;
          return (
            <>
              <Row>
                <Col md='4'>
                  <Link to={'/product/view/' + product.id}>
                    <img
                      style={{ maxWidth: '150px', maxHeight: '200px' }}
                      src={store.item.fileBaseUrl + product.imageUrl}
                      alt={product.name}
                    />
                  </Link>
                </Col>
                <Col md='3'>
                  <h5 className='proxima-nova-bold'>
                    <Link to={'/product/view/' + product.id}>
                      {product.name}
                    </Link>
                  </h5>
                </Col>
                <Col md='2' className='text-center'>
                  {orderItem.sellPriceLabel}
                </Col>
                <Col md='1' className='text-center'>
                  {orderItem.quantity}
                </Col>
                <Col md='2' className='text-center'>
                  {orderItem.subTotalLabel}
                </Col>
              </Row>
            </>
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  {}
)(ListTable);
