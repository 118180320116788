import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getAllItemByWatchlist } from '../../redux/frontend/action/item';
import { postAddWatchlist } from '../../redux/frontend/action/watchlist';
import { postAddCart } from '../../redux/frontend/action/cart';

import Header from '../header';
import Footer from '../footer';

import ProductThumb from '../components/productThumb';

class Index extends Component {
  componentDidMount() {
    this.props.getAllItemByWatchlist();
  }

  handleAddToCart = (e, id) => {
    e.preventDefault();
    this.props.postAddCart(id);
    console.log('cart here ', id);
  };

  handleAddToWishlist = (e, id) => {
    e.preventDefault();
    this.props.postAddWatchlist(id);
    console.log('wishlist here', id);
  };

  render() {
    const { itemListing } = this.props;

    return (
      <>
        <Header />
        <div className='main-container' style={{ width: '100%' }}>
          <div style={{ maxWidth: '75rem', margin: '0px auto' }}>
            <h2>My Wishlist</h2>
            <Row>
              {!itemListing.items && <>No product in watchlist.</>}

              {itemListing.items && (
                <>
                  {itemListing.items.map((item, index) => {
                    return (
                      <>
                        <Col md='3' xs='6' style={{ marginBottom: '20px' }}>
                          <ProductThumb
                            product={item}
                            handleAddToCart={this.handleAddToCart}
                            handleAddToWishlist={this.handleAddToWishlist}
                          />
                        </Col>
                      </>
                    );
                  })}
                </>
              )}
            </Row>
          </div>
        </div>

        {/* footer */}
        <Footer />
        {/*  */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  itemListing: state.itemReducer.itemListing,
});
export default connect(
  mapStateToProps,
  { getAllItemByWatchlist, postAddCart, postAddWatchlist }
)(withRouter(Index));
