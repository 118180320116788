import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllVehicleBatch } from '../../../redux/backend/action/vehicleBatch';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import history from '../../../history';
import PaginateTable from '../../../components/PaginateTable';

class ListTable extends Component {
  state = {
    page: '',
  };

  handlePageChange = (page) => {
    this.setState({ drugGroup_page: page });

    /* get all filters */
    let param = this.state;

    /* send current filter immediately as state can't get current value */
    param.Druggroup_page = page;

    this.reloadGrid(param);
  };

  handleFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    /* get all filters */
    let param = this.state;

    if (name === 'filterCode') {
      this.setState({ filterCode: value });
      param.filterCode = value;
    } else if (name === 'filterName') {
      this.setState({ filterName: value });
      param.filterName = value;
    } else if (name === 'filterUnit') {
      this.setState({ filterUnit: value });
      param.filterUnit = value;
    } else if (name === 'filterQtyAlert') {
      this.setState({ filterQtyAlert: value });
      param.filterQtyAlert = value;
    }

    this.reloadGrid(param);
  };

  reloadGrid = (param) => {
    this.props.getAllVehicleBatch(param);
  };

  render() {
    const { vehicleBatchListing } = this.props;

    if (!vehicleBatchListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <Table responsive size='sm'>
          <thead>
            <tr>
              <th>S.N</th>
              <th>Item</th>
              <th>quantity</th>
              <th style={{ width: '115px' }}></th>
            </tr>
            <tr className='filters' style={{ background: '#E3EAF2' }}>
              <th style={{ verticalAlign: 'middle' }}></th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterName'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}></th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {vehicleBatchListing.items.map((item, index) => {
              return (
                <>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.stock.name}</td>
                    <td>{item.quantityAvailableLabel}</td>
                    <td align='right'>
                      {/* <Link
                        className='btn btn-small btn-outline-default'
                        to={'/customer/load-item/' + item.id}
                        style={{ marginLeft: '5px' }}
                      >
                        Load Items
                      </Link>

                      <Link
                        className='btn btn-small btn-outline-default'
                        to={'/customer/edit/' + item.id}
                        style={{ marginLeft: '5px' }}
                      >
                        Edit
                      </Link> */}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>

        <PaginateTable
          listing={this.props.vehicleBatchListing}
          activePage={this.state.page}
          handlePageChange={this.handlePageChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  vehicleBatchListing: state.vehicleBatchReducer.vehicleBatchListing,
});
export default connect(
  mapStateToProps,
  { getAllVehicleBatch }
)(ListTable);
