import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getAllCollection,
  deleteCollection,
} from '../../../redux/backend/action/collection';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import history from '../../../history';
import PaginateTable from '../../../components/PaginateTable';

class ListTable extends Component {
  state = {
    page: '',
  };

  handlePageChange = (page) => {
    this.setState({ drugGroup_page: page });

    /* get all filters */
    let param = this.state;

    /* send current filter immediately as state can't get current value */
    param.Druggroup_page = page;

    this.reloadGrid(param);
  };

  handleFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    /* get all filters */
    let param = this.state;

    if (name === 'filterCode') {
      this.setState({ filterCode: value });
      param.filterCode = value;
    } else if (name === 'filterName') {
      this.setState({ filterName: value });
      param.filterName = value;
    } else if (name === 'filterUnit') {
      this.setState({ filterUnit: value });
      param.filterUnit = value;
    } else if (name === 'filterQtyAlert') {
      this.setState({ filterQtyAlert: value });
      param.filterQtyAlert = value;
    }

    this.reloadGrid(param);
  };

  reloadGrid = (param) => {
    this.props.getAllCollection(param);
  };

  handleDelete = (e, id) => {
    e.preventDefault();

    if (window.confirm('Are you sure you want to delete?')) {
      this.props.deleteCollection(id);
    }
  };
  render() {
    const { collectionListing } = this.props;

    if (!collectionListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <Table responsive size='sm'>
          <thead>
            <tr>
              <th>Code</th>
              <th>Category</th>
              <th>Name</th>
              <th>Description</th>
              <th style={{ width: '185px' }}>Created At</th>
              <th style={{ width: '125px' }}></th>
            </tr>
            <tr className='filters' style={{ background: '#E3EAF2' }}>
              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterCode'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterName'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterDescription'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterQuantity'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {collectionListing.items.map((item, index) => {
              return (
                <>
                  <tr>
                    <td>{item.code}</td>
                    <td>
                      {item.levelJsonToString}
                      {/* {item.levelJson && (
                        <>
                          {item.levelJson.map((label, labelIndex) => {
                            return <>{label}</>;
                          })}
                        </>
                      )} */}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.descriptionTruncate}</td>
                    <td>{item.createdAt}</td>
                    <td align='right'>
                      {/* <Link
                        className='btn btn-small btn-outline-success'
                        to={'/product/view/' + item.id}
                        style={{ marginLeft: '5px' }}
                      >
                        View
                      </Link> */}

                      <Link
                        className='btn btn-small btn-outline-default'
                        to={'/collection/edit/' + item.id}
                        style={{ marginLeft: '5px' }}
                      >
                        Edit
                      </Link>

                      <Link
                        className='btn btn-small btn-outline-danger'
                        onClick={(e) => this.handleDelete(e, item.id)}
                        style={{ marginLeft: '5px' }}
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>

        {/* <PaginateTable
          listing={this.props.collectionListing}
          activePage={this.state.page}
          handlePageChange={this.handlePageChange}
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collectionListing: state.collectionReducer.collectionListing,
});
export default connect(
  mapStateToProps,
  { getAllCollection, deleteCollection }
)(ListTable);
