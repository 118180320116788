import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import {
  SET_LOADING,
  WATCHLIST_GET_ALL,
  WATCHLIST_GET,
  WATCHLIST_GET_COUNT,
} from './types';

const url = apiBaseUrl + '/frontend/watchlist';

export const getCountWatchlistByCustomer = () => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // token param
    var paramToken = `x-authorization=${localStorage.getItem('jwt')}`;

    // response
    const response = await axios.get(
      `${url}/getCountWatchlistByCustomer?${paramToken}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({
        type: WATCHLIST_GET_COUNT,
        payload: response.data,
      });
    }
  } catch (err) {}
};

export const getAllWatchlist = (history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // token param
    var paramToken = `x-authorization=${localStorage.getItem('jwt')}`;

    // response
    const response = await axios.get(
      `${url}/getAllWatchlist?${paramToken}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: WATCHLIST_GET_ALL, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const getWatchlist = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // token param
    var paramToken = `x-authorization=${localStorage.getItem('jwt')}`;

    // response
    const response = await axios.get(
      `${url}/getWatchlist?id=${id}&${paramToken}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: WATCHLIST_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postAddWatchlist = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postAddWatchlist?id=${id}`,
      {},
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: WATCHLIST_GET_COUNT,
        payload: response.data.countData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
