import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import { SET_LOADING, ORDER_GET_ALL, ORDER_GET } from './types';

const url = apiBaseUrl + '/backend/order';

export const getAllOrder = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};

  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getAllOrder?${param}`, config);

    // dispatch
    dispatch({
      type: ORDER_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getOrder = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      // headers: {
      //   //Authorization: `Bearer ${localStorage.getOrder('jwt')}`,
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
    };

    // response
    const response = await axios.get(`${url}/getOrder?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: ORDER_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postSetFulfill = (id, data, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      // headers: {
      //   //Authorization: `Bearer ${localStorage.getOrder('jwt')}`,
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
    };

    // response
    const response = await axios.post(
      `${url}/postSetFulfill?id=${id}`,
      data,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      // dispatch
      dispatch({ type: ORDER_GET_ALL, payload: response.data.allOrderData });

      history.push('/order');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postSetCancell = (id, data, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      // headers: {
      //   //Authorization: `Bearer ${localStorage.getOrder('jwt')}`,
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
    };

    // response
    const response = await axios.post(
      `${url}/postSetCancell?id=${id}`,
      data,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      // dispatch
      dispatch({ type: ORDER_GET_ALL, payload: response.data.allOrderData });

      history.push('/order');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
