import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { postAddWatchlist } from '../../redux/frontend/action/watchlist';
import { postAddCart } from '../../redux/frontend/action/cart';

class Index extends Component {
  state = {
    // id: this.props.match.params.id,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /**
     * new Project in town ?
     */
    if (this.state.id !== this.props.match.params.id) {
      /* this.props.getPage(this.props.match.params.id);

      this.setState({
        id: this.props.match.params.id,
      }); */
    }
  }

  render() {
    const { product, store } = this.props;

    if (!product || !store.item) {
      return <>Loading..</>;
    }

    return (
      <>
        <Link
          className='text-decoration-none'
          to={'/product/' + product.slug + '/' + product.id}
        >
          <Card style={{ width: '100%' }}>
            <Card.Img
              variant='top'
              src={store.item.fileBaseUrl + '/' + product.imageThumbUrl}
            />
            <Card.Body>
              <Card.Title className='text-left text-truncate'>
                {product.name}
              </Card.Title>

              <Row>
                <Col xs='12' sm='12' md='7'>
                  <Card.Title
                    className='text-left color-orange price-block'
                    style={{ marginBottom: '3px' }}
                  >
                    {product.sellPriceLabel}
                  </Card.Title>
                </Col>
                <Col xs='12' sm='12' md='5'>
                  <Link
                    className='text-decoration-none'
                    onClick={(e) => this.props.handleAddToCart(e, product.id)}
                  >
                    <div className='product-cart-left'>
                      <i class='fa fa-shopping-cart' aria-hidden='true'></i>
                    </div>
                  </Link>

                  <Link
                    className='text-decoration-none'
                    onClick={(e) =>
                      this.props.handleAddToWishlist(e, product.id)
                    }
                  >
                    <div className='product-wish-right'>
                      <i class='fa fa-heart-o' aria-hidden='true'></i>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Link>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  { postAddCart, postAddWatchlist }
)(withRouter(Index));
