import React, { Component } from 'react';

import { connect } from 'react-redux';
import FormAdd from './form';
import Rearrange from './rearrange';
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import TopNav from '../top-nav';
class Index extends Component {
  render() {
    return (
      <>
        <TopNav activeLabel='collection-add' />

        <Row>
          <Col md='5'>
            <h4>Add Collection</h4>
            <div className='form-container'>
              <FormAdd />
            </div>
          </Col>

          <Col md='7'>
            <h4>Arrange Collection</h4>
            <div className='form-container'>
              <Rearrange />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(Index);
