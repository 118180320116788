import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import { SET_LOADING, MIGRATION_GET } from './types';

const url = apiBaseUrl + '/backend/migration';

export const getMigration = (history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];

    // response
    const response = await axios.get(
      `${url}/getMigration?domain=${domain}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: MIGRATION_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postRunMigration = () => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];

    // response
    const response = await axios.post(
      `${url}/postRunMigration?domain=${domain}`,
      {},
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({ type: MIGRATION_GET, payload: response.data.migrationData });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
