import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import item from '../../redux/frontend/reducer/item';

class Index extends Component {
  render() {
    const { productRow, itemId, activeLabel } = this.props;

    if (!productRow.item) return <></>;

    const product = productRow.item;
    return (
      <>
        <div className='plane-border-container'>
          <div
            class='nav flex-column nav-pills'
            id='v-pills-tab'
            role='tablist'
            aria-orientation='vertical'
          >
            <Link
              to={'/product/edit/' + product.id}
              className={
                activeLabel === 'edit-basic' ? 'nav-link active' : 'nav-link'
              }
            >
              Edit Basic Details
            </Link>

            <Link
              to={'/product/add-image/' + product.id}
              className={
                activeLabel === 'upload-image' ? 'nav-link active' : 'nav-link'
              }
            >
              Upload Images
            </Link>

            <Link
              to={'/product/add-to-collection/' + product.id}
              className={
                activeLabel === 'assign-collection'
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              Assign Collection
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  {}
)(Index);
