import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postEditCollection } from '../../../redux/backend/action/collection';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FormAdd extends Component {
  state = {
    collection: '',
  };

  componentDidMount() {}

  componentWillReceiveProps(recievedProps) {
    const collection = recievedProps.collection.item;
    if (collection) {
      this.setState({
        id: collection.id,
        name: collection.name,
        content: collection.content,
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    }
  };

  handleChangeEditorDescription = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data });

    this.setState({ content: data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.postEditCollection(this.state.id, this.state, this.props.history);
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md='6'>
              <div className='form-group'>
                <label>Name: </label>
                <input
                  type='text'
                  name='name'
                  className='form-control form-control-sm'
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>
            </Col>

            <Col md='12'>
              <div className='form-group'>
                <label>Content: </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={this.state.content}
                  onInit={(editor) => {
                    // set min-height
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        'min-height',
                        '300px',
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={this.handleChangeEditorDescription}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
              </div>
            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collection: state.collectionReducer.collection,
});

export default connect(
  mapStateToProps,
  { postEditCollection }
)(withRouter(FormAdd));
