import React, { Component } from 'react';
import { Carousel, Card, CardGroup, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
// import { postAddWatchlist } from '../../../redux/frontend/action/watchlist';
// import { postAddCart } from '../../../redux/frontend/action/cart';

class Index extends Component {
  // handleAddToCart = (e, id) => {
  //   e.preventDefault();
  //   this.props.postAddCart(id);
  //   console.log('cart here ', id);
  // };

  // handleAddToWishlist = (e, id) => {
  //   e.preventDefault();
  //   this.props.postAddWatchlist(id);
  //   console.log('wishlist here', id);
  // };

  render() {
    const { data, store } = this.props;
    if (!data || !data.items || !store.item) return <></>;

    let fullWidth = data.fullWidth;
    let height = data.height;
    height = height ? height + 'px' : '300px';

    const items = data.items;

    return (
      <>
        <div
          style={
            fullWidth === 'no'
              ? { maxWidth: '90rem', margin: '0px auto' }
              : { margin: '0px auto' }
          }
        >
          {items.length > 0 && (
            <>
              <Carousel>
                {items.map((item, index) => {
                  return (
                    <Carousel.Item>
                      {item.externalLink && (
                        <>
                          <a href={item.externalLink} target='_blank'>
                            <img
                              style={{ height: height }}
                              className='d-block w-100'
                              src={store.item.fileBaseUrl + item.imageSrc}
                              alt='First slide'
                            />
                            <Carousel.Caption>
                              <h3>{item.heading}</h3>
                              <p>{item.subHeading}</p>
                            </Carousel.Caption>
                          </a>
                        </>
                      )}

                      {!item.externalLink && (
                        <>
                          <Link to={item.link}>
                            <img
                              style={{ height: height }}
                              className='d-block w-100'
                              src={store.item.fileBaseUrl + item.imageSrc}
                              alt='First slide'
                            />
                            <Carousel.Caption>
                              <h3>{item.heading}</h3>
                              <p>{item.subHeading}</p>
                            </Carousel.Caption>
                          </Link>
                        </>
                      )}
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
