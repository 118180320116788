import {
  SUBMITTING_STARTED,
  SUBMITTING_DONE,
  WATCHLIST_GET_ALL,
  WATCHLIST_GET,
  WATCHLIST_GET_COUNT,
  SET_LOADING,
} from '../action/types';

const initialState = {
  watchlist: [],
  watchlistListing: [],
  countWatchlist: '',
  loading: true,
  submitting: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTING_STARTED: {
      return {
        ...state,
        submitting: true,
      };
    }

    case SUBMITTING_DONE: {
      return {
        ...state,
        submitting: false,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case WATCHLIST_GET_ALL: {
      return {
        ...state,
        watchlistListing: action.payload,
        loading: false,
      };
    }

    case WATCHLIST_GET: {
      return {
        ...state,
        watchlist: action.payload,
        loading: false,
      };
    }

    case WATCHLIST_GET_COUNT: {
      return {
        ...state,
        countWatchlist: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};
