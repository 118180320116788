import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import {
  SET_LOADING,
  MENU_GET_ALL,
  MENU_GET,
  TEMPLATE_MENU_GET_ALL,
} from './types';

const url = apiBaseUrl + '/frontend/menu';

export const getAllMenu = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};
  
  try {
    // headers
    const config = {};

    // get
    const response = await axios.get(`${url}/getAllMenu?${param}`, config);

    // dispatch
    dispatch({
      type: MENU_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getMenu = () => async (dispatch) => {
  try {
    // headers
    const config = {
      /*  headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }, */
    };

    // get
    const response = await axios.get(`${url}/getMenu`, config);

    // dispatch
    dispatch({
      type: TEMPLATE_MENU_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};
