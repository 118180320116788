import React, { Component } from "react";
import { Carousel, Card, CardGroup, Button, Row, Col } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { postAddWatchlist } from "../../../redux/frontend/action/watchlist";
import { postAddCart } from "../../../redux/frontend/action/cart";

class Index extends Component {
  handleAddToCart = (e, id) => {
    e.preventDefault();
    this.props.postAddCart(id);
    console.log("cart here ", id);
  };

  handleAddToWishlist = (e, id) => {
    e.preventDefault();
    this.props.postAddWatchlist(id);
    console.log("wishlist here", id);
  };

  render() {
    const { data, store } = this.props;
    if (!data || !data.items || !store.item) return <></>;

    let heading = data.heading;
    let headerAlignment = data.headerAlignment;
    headerAlignment = headerAlignment ? headerAlignment : "left";

    let includePrice = data.includePrice;
    includePrice = includePrice ? includePrice : "no";

    let noOfItemPerRow = data.noOfItemPerRow;
    noOfItemPerRow = noOfItemPerRow ? noOfItemPerRow : 4;

    let height = data.height;
    let heightStyle = height ? height + "px" : "100px";

    let showMoreLabel = data.showMoreLabel;
    let showMoreLink = data.showMoreLink;

    let items = data.items;

    let wd = 2;
    let imageHeight = "200px";

    if (noOfItemPerRow == 2) wd = 6;
    else if (noOfItemPerRow == 3) wd = 4;
    else if (noOfItemPerRow == 4) wd = 3;
    else if (noOfItemPerRow == 6) wd = 2;

    if (noOfItemPerRow == 2) imageHeight = "300px";
    else if (noOfItemPerRow == 3) imageHeight = "200px";
    else if (noOfItemPerRow == 4) imageHeight = "200px";
    else if (noOfItemPerRow == 6) imageHeight = "200px";

    /* get from db if set */
    imageHeight = data.imageHeight ? data.imageHeight + "px" : imageHeight;

    return (
      <>
        <div style={{ maxWidth: "90rem", margin: "0px auto" }}>
          <h1 className={"text-" + headerAlignment}>{heading}</h1>
        </div>

        {/* SECTION */}
        <div
          style={{ maxWidth: "90rem", margin: "0px auto" }}
          className={"home-section-product-list-md-" + wd}
        >
          {items.length > 0 && (
            <Row>
              {items.map((item, index) => {
                return (
                  <Col md={wd} xs="6" style={{ marginBottom: "20px" }}>
                    <Card>
                      <Link to={item.link} className="text-decoration-none">
                        <Card.Img
                          variant="top"
                          src={store.item.fileBaseUrl + item.imageSrc}
                          style={{ height: imageHeight }}
                        />
                      </Link>
                      <Card.Body>
                        <Card.Title className="text-left text-truncate title-block">
                          <Link to={item.link} className="text-decoration-none">
                            {item.name}
                          </Link>
                        </Card.Title>

                        <Row>
                          <Col xs="12" sm="12" md="7">
                            {includePrice === "yes" && (
                              <Card.Title
                                className="text-left price-block color-orange"
                                style={{ marginBottom: "3px" }}
                              >
                                {item.price}
                              </Card.Title>
                            )}
                          </Col>
                          <Col xs="12" sm="12" md="5">
                            <Link
                              onClick={(e) =>
                                this.handleAddToCart(e, item.productId)
                              }
                            >
                              <div className="product-cart-left">
                                <i
                                  class="fa fa-shopping-cart"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </Link>

                            <Link
                              onClick={(e) =>
                                this.handleAddToWishlist(e, item.productId)
                              }
                            >
                              <div className="product-wish-right">
                                <i class="fa fa-heart-o" aria-hidden="true"></i>
                              </div>
                            </Link>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}

              {/* show more link */}
              {showMoreLabel && showMoreLink && (
                <>
                  <Col md="12" className="text-right">
                    <Link
                      to={showMoreLink}
                      className="btn btn-dark btn-small view-more-btn"
                      style={{
                        marginTop: "-15px",
                      }}
                    >
                      {showMoreLabel}
                    </Link>
                  </Col>
                </>
              )}
            </Row>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  { postAddCart, postAddWatchlist }
)(withRouter(Index));
