import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Index extends Component {
  render() {
    const { activeLabel, store } = this.props;

    if(store.item && store.item.disableCollectionFilter > 0){
      return <></>
    }

    return (
      <>
        <Row>
          <Col style={{ textAlign: 'center', marginBottom: '20px' }}>
            <ButtonGroup aria-label='Basic example'>
              <Button
                variant={
                  activeLabel == 'collection-add'
                    ? 'secondary active'
                    : 'secondary'
                }
              >
                <Link
                  to='/collection/add'
                  style={{
                    color: '#fff',
                    textDecoration: 'none',
                    textTransform: 'capitalize',
                  }}
                >
                  Add/Manage Collection
                </Link>
              </Button>

              <Button
                variant={
                  activeLabel == 'collection-assign-filter'
                    ? 'secondary active'
                    : 'secondary'
                }
              >
                <Link
                  to='/collection/add-filter/id='
                  style={{
                    color: '#fff',
                    textDecoration: 'none',
                    textTransform: 'capitalize',
                  }}
                >
                  Assign Filters
                </Link>
              </Button>

              <Button
                variant={
                  activeLabel == 'all-filter' ? 'secondary active' : 'secondary'
                }
              >
                <Link
                  to='/filter'
                  style={{
                    color: '#fff',
                    textDecoration: 'none',
                    textTransform: 'capitalize',
                  }}
                >
                  All Filters
                </Link>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  {}
)(Index);
