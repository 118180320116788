import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postEditStore } from '../../../redux/backend/action/store';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FormAdd extends Component {
  state = {
    store: '',
  };

  componentDidMount() {}

  componentWillReceiveProps(recievedProps) {
    const store = recievedProps.store.item;
    if (store) {
      this.setState({
        id: store.id,
        name: store.name,
        address: store.address,
        email: store.email,
        mobile: store.mobile,
        phone: store.phone,
        website: store.website,
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    } else if (name === 'address') {
      this.setState({ address: value });
    } else if (name === 'email') {
      this.setState({ email: value });
    } else if (name === 'mobile') {
      this.setState({ mobile: value });
    } else if (name === 'phone') {
      this.setState({ phone: value });
    } else if (name === 'website') {
      this.setState({ website: value });

    } else if (name === 'disable_product') {
      this.setState({ disable_product: e.target.checked });

    } else if (name === 'disable_order') {
      this.setState({ disable_order: e.target.checked });

    } else if (name === 'disable_order') {
      this.setState({ disable_order: e.target.checked });

    } else if (name === 'disable_customer') {
      this.setState({ disable_customer: e.target.checked });

    } else if (name === 'disable_watchlist') {
      this.setState({ disable_watchlist: e.target.checked });

    } else if (name === 'disable_cart') {
      this.setState({ disable_cart: e.target.checked });

    } else if (name === 'disable_collection_filter') {
      this.setState({ disable_collection_filter: e.target.checked });

    } else if (name === 'disable_global_search') {
      this.setState({ disable_global_search: e.target.checked });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.postEditStore(this.state.id, this.state, this.props.history);
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md='6'>
              <div className='form-group'>
                <label>Name: </label>
                <input
                  type='text'
                  name='name'
                  className='form-control form-control-sm'
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='6'>
              <div className='form-group'>
                <label>Address: </label>
                <input
                  type='text'
                  name='address'
                  className='form-control form-control-sm'
                  value={this.state.address}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='6'>
              <div className='form-group'>
                <label>Email: </label>
                <input
                  type='text'
                  name='email'
                  className='form-control form-control-sm'
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='6'>
              <div className='form-group'>
                <label>Mobile: </label>
                <input
                  type='text'
                  name='mobile'
                  className='form-control form-control-sm'
                  value={this.state.mobile}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='6'>
              <div className='form-group'>
                <label>Phone: </label>
                <input
                  type='text'
                  name='phone'
                  className='form-control form-control-sm'
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='6'>
              <div className='form-group'>
                <label>Website: </label>
                <input
                  type='text'
                  name='website'
                  className='form-control form-control-sm'
                  value={this.state.website}
                  onChange={this.handleChange}
                />
              </div>
              {/* <br />
              <br />


              <div className='form-group'>
                <label></label>
                <input
                  type='checkbox'
                  name='disable_product'
                  id='disable_product'
                  value={this.state.disable_product}
                  onChange={this.handleChange}
                />
                <label for='disable_product' style={{marginLeft: '5px'}}>Disable Product</label>
              </div>


              <div className='form-group'>
                <label></label>
                <input
                  type='checkbox'
                  name='disable_order'
                  id='disable_order'
                  value={this.state.disable_order}
                  onChange={this.handleChange}
                />
                <label for='disable_order' style={{marginLeft: '5px'}}>Disable Order</label>
              </div>


              <div className='form-group'>
                <label></label>
                <input
                  type='checkbox'
                  name='disable_customer'
                  id='disable_customer'
                  value={this.state.disable_customer}
                  onChange={this.handleChange}
                />
                <label for='disable_customer' style={{marginLeft: '5px'}}>Disable Customer</label>
              </div>


              <div className='form-group'>
                <label></label>
                <input
                  type='checkbox'
                  name='disable_watchlist'
                  id='disable_watchlist'
                  value={this.state.disable_watchlist}
                  onChange={this.handleChange}
                />
                <label for='disable_watchlist' style={{marginLeft: '5px'}}>Disable Watchlist</label>
              </div>


              <div className='form-group'>
                <label></label>
                <input
                  type='checkbox'
                  name='disable_cart'
                  id='disable_cart'
                  value={this.state.disable_cart}
                  onChange={this.handleChange}
                />
                <label for='disable_cart' style={{marginLeft: '5px'}}>Disable Cart</label>
              </div>


              <div className='form-group'>
                <label></label>
                <input
                  type='checkbox'
                  name='disable_collection_filter'
                  id='disable_collection_filter'
                  value={this.state.disable_collection_filter}
                  onChange={this.handleChange}
                />
                <label for='disable_collection_filter' style={{marginLeft: '5px'}}>Disable Filter</label>
              </div>


              <div className='form-group'>
                <label></label>
                <input
                  type='checkbox'
                  name='disable_global_search'
                  id='disable_global_search'
                  value={this.state.disable_global_search}
                  onChange={this.handleChange}
                />
                <label for='disable_global_search' style={{marginLeft: '5px'}}>Disable Global Search</label>
              </div> */}


            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});

export default connect(
  mapStateToProps,
  { postEditStore }
)(withRouter(FormAdd));
