import React, { Component } from 'react';

import { connect } from 'react-redux';
import FormEdit from './formEdit';
// import {} from '../../../redux/action/user'
class Index extends Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <h4>Change Password</h4>
        <div className='form-container'>
          <FormEdit />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(Index);
