import React, { Component } from 'react';

import { connect } from 'react-redux';
import Detail from './detail';
import { getItem, postDeleteItem } from '../../../redux/backend/action/item';
import { Row, Col } from 'react-bootstrap';

import Images from './image';
import DescriptionView from './description';
import { Link, withRouter } from 'react-router-dom';

class Index extends Component {
  componentDidMount() {
    this.props.getItem(this.props.match.params.id);
  }

  handleDeleteItem = (e) => {
    e.preventDefault();

    if (window.confirm('Are you sure, you want to delete?')) {
      this.props.postDeleteItem(
        { id: this.props.match.params.id },
        this.props.history
      );
    }
  };

  render() {
    const { productRow } = this.props;
    if (!productRow) return <>Loading..</>;

    const product = productRow.item;

    return (
      <>
        <h4>
          Product
          {product && (
            <>
              <small> #{product.code}</small>
            </>
          )}
        </h4>

        <Row>
          {/* images */}
          <Col md='5'>
            <div className='form-container'>
              <Images />

              {/* description */}
              <DescriptionView />
            </div>
          </Col>

          {/* detail */}
          <Col md='7'>
            <div className='form-container'>
              <Detail />
            </div>

            <div className='mt-15'>
              <Link
                onClick={(e) => this.handleDeleteItem(e)}
                className='btn btn-danger'
              >
                DELETE
              </Link>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  { getItem, postDeleteItem }
)(withRouter(Index));
