import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FormAdd extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <>
        <Row>
          <Col md='6'>
            <div className='form-group'>
              <label>Name: </label>
              <input
                type='text'
                name='name'
                className='form-control form-control-sm'
                value={this.props.state.name}
                onChange={this.props.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md='8'>
            <div className='form-group'>
              <label>Content: </label>

              <CKEditor
                editor={ClassicEditor}
                data={this.props.state.description}
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={this.props.handleChangeEditorDescription}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md='2'>
            <div className='form-group'>
              <label>Quantity: </label>
              <input
                type='text'
                name='quantity'
                className='form-control form-control-sm'
                value={this.props.state.quantity}
                onChange={this.props.handleChange}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(FormAdd));
