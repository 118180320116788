import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col, Form } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { submitRegister } from '../../redux/frontend/action/register';

class Index extends Component {
  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    } else if (name === 'email') {
      this.setState({ email: value });
    } else if (name === 'password') {
      this.setState({ password: value });
    } else if (name === 'repeatPassword') {
      this.setState({ repeatPassword: value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.submitRegister(this.state, this.props.history);
  };

  render() {
    const { submitting } = this.props;

    return (
      <>
        <h2>REGISTER</h2>

        <Form>
          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              name='name'
              type='text'
              placeholder='Enter name'
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name='email'
              type='email'
              placeholder='Enter email'
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group controlId='formBasicPassword'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              name='password'
              type='password'
              placeholder='Password'
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group controlId='formBasicPassword'>
            <Form.Label>Repeat Password</Form.Label>
            <Form.Control
              name='repeatPassword'
              type='password'
              placeholder='Repeat password'
              onChange={this.handleChange}
            />
          </Form.Group>

          {submitting && (
            <>
              <Button variant='btn btn-outline-success' type='submit' disabled>
                Register
              </Button>
            </>
          )}
          {!submitting && (
            <>
              <Button
                variant='btn btn-outline-success'
                type='submit'
                onClick={(e) => this.handleSubmit(e)}
              >
                Register
              </Button>
            </>
          )}
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  submitting: state.registerReducer.submitting,
});
export default connect(
  mapStateToProps,
  { submitRegister }
)(withRouter(Index));
