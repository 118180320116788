import {
  SUBMITTING_STARTED,
  SUBMITTING_DONE,
  SECTION_GET_ALL,
  DEFAULT_SECTION_GET_ALL,
  SECTION_GET,
  SET_LOADING,
  SET_PREVIEW_GENERATE_WAITING,
} from '../action/types';

const initialState = {
  section: [],
  sectionListing: [],
  defaultSectionListing: [],
  loading: true,
  submitting: true,
  isJsPreviewGenerateWaiting: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTING_STARTED: {
      return {
        ...state,
        submitting: true,
      };
    }

    case SUBMITTING_DONE: {
      return {
        ...state,
        submitting: false,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_PREVIEW_GENERATE_WAITING: {
      return {
        ...state,
        isJsPreviewGenerateWaiting: action.payload,
      };
    }

    case DEFAULT_SECTION_GET_ALL: {
      return {
        ...state,
        defaultSectionListing: action.payload,
        loading: false,
      };
    }

    case SECTION_GET_ALL: {
      return {
        ...state,
        sectionListing: action.payload,
        loading: false,
      };
    }

    case SECTION_GET: {
      return {
        ...state,
        section: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};
