import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import { SET_LOADING, SECTION_GET_ALL, SECTION_GET } from './types';

const url = apiBaseUrl + '/frontend/section';

export const getAllHomeSection = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};

  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(
      `${url}/getAllHomeSection?${param}`,
      config
    );

    // dispatch
    dispatch({
      type: SECTION_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};
export const getFooter = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};

  try {
    // headers
    const config = {};

    // get
    const response = await axios.get(`${url}/getFooter?${param}`, config);

    // dispatch
    dispatch({
      type: SECTION_GET,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getAllSection = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};
  
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getAllSection?${param}`, config);

    // dispatch
    dispatch({
      type: SECTION_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getSection = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.get(`${url}/getSection?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: SECTION_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const getCountSection = (data) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getCountSection`, config);

    // dispatch
    // dispatch({ type: GET_COUNT_LAB_REPORTS, payload: response.data });
  } catch (err) {}
};
