import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getAllItemByCollection } from '../../redux/frontend/action/item';

import queryString from 'query-string';

class Index extends Component {
  state = {
    page: '',
  };

  handleFilterResult = (param) => {
    // change url
    this.props.history.push(this.props.location.pathname + '?' + param);

    // api call here
    this.props.getAllItemByCollection(this.props.match.params.id, param);
  };

  handleRemove = (e, value, name) => {
    e.preventDefault();

    var checked = false;

    let urlParam = queryString.parse(this.props.location.search);

    var curValue = urlParam[name];
    var toAdd = '_' + value + '_';

    // add to url if not exists
    if (typeof curValue != 'undefined' && curValue.includes(toAdd) === false) {
      curValue = curValue + toAdd;
    }

    // if undefined url value
    if (typeof curValue == 'undefined') {
      curValue = toAdd;
    }

    // add value
    urlParam[name] = curValue;

    let param = new URLSearchParams(urlParam).toString();

    if (checked === false) {
      param = param.replace('_' + value + '_', '');
    }

    console.log('newparm', param);
    // param = param.replace('__', '_');

    this.handleFilterResult(param);
  };

  render() {
    // const { collection, itemListing } = this.props;
    // if (!collection || !itemListing.items) {
    //   return <>eeee</>;
    // }

    let filters = queryString.parse(this.props.location.search);

    const AppliedFilters = ({ data }) =>
      Object.entries(filters).map(([key, valString]) => {
        var result = valString.split('_');

        return (
          <span key={key}>
            {result.map((value, ind) => {
              var displayFilter = value.replace('-s', 's'); // levi-s to levis
              displayFilter = displayFilter.replace('-', ' '); //light-blue to light blue

              displayFilter = displayFilter
                .toLowerCase()
                .replace(/\b[a-z]/g, function(letter) {
                  return letter.toUpperCase();
                });

              if (value) {
                return (
                  <span
                    style={{
                      border: '1px solid #ccc',
                      marginRight: '5px',
                      padding: '5px 10px',
                      borderRadius: '15px',
                    }}
                  >
                    <Link
                      to='#'
                      onClick={(e) => this.handleRemove(e, value, key)}
                    >
                      {displayFilter}
                      <span style={{ marginLeft: '5px', color: '#666' }}>
                        X
                      </span>
                    </Link>
                  </span>
                );
              }
            })}
          </span>
        );
      });

    return (
      <>
        {filters && (
          <div className='applied-filters mb-20'>
            <AppliedFilters filters={filters} />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collection: state.itemReducer.collection,
  itemListing: state.itemReducer.itemListing,
});
export default connect(
  mapStateToProps,
  { getAllItemByCollection }
)(withRouter(Index));
