import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getCustomer } from '../../../redux/backend/action/customer';
import Detail from './detail';

class Index extends Component {
  componentDidMount() {
    this.props.getCustomer(this.props.match.params.id);
  }

  render() {
    const { customer } = this.props;

    return (
      <>
        <h4>
          Customer <small>: {customer.item && <>{customer.item.name}</>}</small>
        </h4>

        <div className='plane-border-container'>
          <Detail />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customerReducer.customer,
});
export default connect(
  mapStateToProps,
  { getCustomer }
)(Index);
