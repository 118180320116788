import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getMigration } from '../../../redux/backend/action/migration';
import Detail from './detail';

class Index extends Component {
  componentDidMount() {
    this.props.getMigration();
  }

  render() {
    const { migration } = this.props;

    return (
      <>
        <h4>Database Migration Checker</h4>

        <div className='plane-border-container'>
          <Detail />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  migration: state.migrationReducer.migration,
});
export default connect(
  mapStateToProps,
  { getMigration }
)(Index);
