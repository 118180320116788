import React, { useState } from 'react';

import { Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { postAddWatchlist } from '../../redux/frontend/action/watchlist';
import { postCart } from '../../redux/frontend/action/cart';

import Image from './image';
import FilterList from './filterList';
import Quantity from "./quantity";

const Index = (props) => {  
  const [cartForm, setCartForm] = useState({quantity: 1});

  const validatePostingCart = () => {
    const emptyOptions = [];
    for (let data in cartForm) {
      if(!cartForm[data]) {
        emptyOptions.push(data)
      }
    }

    return emptyOptions;
  }

  const handleAddToCart = () => {
    const emptyOptions = validatePostingCart();

    console.log('--- emptyOptions',emptyOptions)
    if(emptyOptions?.length) {
      toast.error(`Please select ${emptyOptions.join(", ")}.`);
      return;
    } 
    
    props.postCart(props.itemId, cartForm, props.history);
  };
  const handleAddToWatchlist = () => {
    props.postAddWatchlist(props.itemId);
  };

    const { productRow } = props;
    if (!productRow.item) return <>Loading..</>;

    return (
      <>
        <div className='detail' style={{ background: '#fff' }}>
          <div className='row'>
            <div className='col-md-7'>
              <Image itemId={props.itemId} />
            </div>
            <div className='col-md-5'>
              <div className='row'>
                <div className='col-md-12'>
                  {/* title */}
                  <h3 className='proxima-nova-bold'>{productRow.item.name}</h3>
                  {/* /. title */}

                  {/* price */}
                  <div
                    className='proxima-nova-bold price-block'
                    style={{ fontSize: '30px' }}
                  >
                    {productRow.item.sellPriceLabel}
                  </div>
                  {/* /. price */}

                  <br />

                  {/* option */}
                  <FilterList setCartForm={setCartForm} cartForm={cartForm} />
                  {/* /. option */}

                  <dl class='row'>
                    <dt
                      className='col-sm-2 proxima-nova-light text-uppercase'
                      style={{ width: '55px' }}
                    > Quantity</dt>

                    <dd className='col-sm-10 proxima-nova-bold text-uppercase'>

                    <Quantity setCartForm={setCartForm} cartForm={cartForm} />

                    
                    </dd>

                  </dl>

                  <dl class='row'>
                    <dt class='col-md-9 proxima-nova-light'>
                      <Button
                        onClick={handleAddToCart}
                        className='btn btn-primary btn-block proxima-nova-bold mb-5'
                      >
                        Add to Cart
                      </Button>
                    </dt>
                    <dd class='col-md-3 proxima-nova-bold'>
                      <Button
                        onClick={handleAddToWatchlist}
                        className='btn btn-outline-primary btn-block proxima-nova-bold'
                      >
                        <i
                          class='fa fa-heart-o font-16 center'
                          aria-hidden='true'
                        ></i>
                        &nbsp;
                      </Button>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  { postCart, postAddWatchlist }
)(withRouter(Index));
