// https://appdividend.com/2018/11/11/react-crud-example-mern-stack-tutorial/

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  NavLink,
} from "react-router-dom";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import logo from "./logo.svg";
import "./App.css";
import "./assets/css/frontend/styles.css";
import "./assets/css/frontend/margin-padding.css";

import frontendStore from "./redux/frontend/store";
import backendStore from "./redux/backend/store";
import { Provider } from "react-redux";
import Login from "./feature-backend/site/login/index";
import Logout from "./feature-backend/site/logout/index";

import NavStoreFrontend from "./config/navStoreFrontend";

import setDbHeader from "./utils/setDbHeader";

import { ProtectedRouteFrontend } from "./config/protectedRouteFrontend";
import { ToastContainer } from "react-toastify";

import StoreDetail from "./feature-frontend/storeDetail";
const App = () => {
  /* set domain to connect db in api */
  var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
  setDbHeader(domain);

  return (
    <>
      <Provider store={frontendStore}>
        <ToastContainer
          autoClose={5000}
          position="bottom-right"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          draggable
        />

        <StoreDetail />

        {/* <Router basename={'/'}> */}
        {/* <Router basename={'/eztoz/prod/'}> */}
        <Router basename={process.env.PUBLIC_URL}>
          <Route path="/" component={NavStoreFrontend} />
        </Router>
      </Provider>
    </>
  );
};

export default App;
