import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import { SET_LOADING, PAGE_GET_ALL, PAGE_GET, COLLECTION_GET } from './types';

const url = apiBaseUrl + '/frontend/page';

export const getPage = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      // headers: {
      //   //Authorization: `Bearer ${localStorage.getPage('jwt')}`,
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
    };

    // response
    const response = await axios.get(`${url}/getPage?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: PAGE_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
