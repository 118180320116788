import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import FooterContent from './content';

class Index extends Component {
  componentDidMount() {}

  render() {
    const { data } = this.props;
    if (!data) {
      return <>Loading..</>;
    }

    return (
      <>
        <>
          <div className='proxima-nova'>
            <FooterContent data={data} />
          </div>
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
