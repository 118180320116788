import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Search from './filtering';

class Index extends Component {
  // state = {};

  componentDidMount() {}

  render() {
    const { store } = this.props;
    if (!store.item) return <>Loading..</>;

    /* width */
    let logoWidth = store.item.logoWidth
      ? store.item.logoWidth + 'px'
      : '200px';

    /* height */
    let logoHeight = store.item.logoHeight
      ? store.item.logoHeight + 'px'
      : '200px';

    return (
      <>
        <div className='ml-15 mt-30 mr-15 mb-30'>
          <Row>
            {/* logo */}
            <Col md='3' style={{ margin: '-25px', textAlign: 'center' }}>
              <Link to='#'>
                {store.item.logoSrc && (
                  <>
                    <img
                      src={store.item.fileBaseUrl + store.item.logoSrc}
                      width={logoWidth}
                      height={logoHeight}
                    />
                  </>
                )}
              </Link>
            </Col>

            {/* search */}
            <Col
              md='5'
              className='text-center'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div style={{ width: '100%' }}>
                <Search />
              </div>
            </Col>

            {/* links */}
            <Col
              md='4'
              className='mt-10 pr-20 text-left header-quick-link'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Link to='#' style={{ marginRight: '15px' }}>
                <i class='fa fa-heart-o' aria-hidden='true'></i> Watchlist
              </Link>
              <Link to='#' style={{ marginRight: '15px' }}>
                <i class='fa fa-server' aria-hidden='true'></i> Order
              </Link>
              <Link to='#' style={{ marginRight: '15px' }}>
                <i class='fa fa-shopping-cart' aria-hidden='true'></i> Cart
              </Link>

              <Link to='#'>
                <i class='fa fa-unlock-alt' aria-hidden='true'></i> Login
              </Link>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  {}
)(Index);
