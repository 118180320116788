// import {createStore,applyMiddleware} from 'redux'
// import logger from 'redux-logger'
// import thunk from 'redux-thunk'
// import allReducer from './reducer'

//export default createStore(allReducer,applyMiddleware(logger,thunk))

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';
import combineReducer from './combineReducer';
// export default createStore(allReducer, applyMiddleware(thunk))

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

export default createStore(
  combineReducer,
  composeEnhancers(applyMiddleware(thunk))
);
