import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppBackend from './AppBackend';
import AppFrontend from './AppFrontend';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';

var url = window.location.pathname;
var isBackend = url.includes('/admin');

ReactDOM.render(
  <BrowserRouter>
    {/* BACKEND */}
    {isBackend === true && <AppBackend />}

    {/* FRONTEND */}
    {isBackend === false && <AppFrontend />}
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
