import React, { Component } from 'react';

import { connect } from 'react-redux';
import FormAdd from './form';
class Index extends Component {
  render() {
    return (
      <>
        <h4>
          Page <small>Create</small>
        </h4>

        <div className='plane-border-container'>
          <FormAdd />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(Index);
