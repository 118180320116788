import React, { Component } from 'react';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { getStore } from '../../redux/frontend/action/store';
// import LaunchModal from './launchModal'

class Menu extends Component {
  state = {};
  componentDidMount() {
    this.props.getStore();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /**
     * new Project in town ?
     */
    if (!this.state.store) {
      this.props.getStore();

      /* set document title */
      document.title = this.props.store.item.name;

      this.setState({
        store: this.props.store,
      });
    }
  }

  render() {
    const { store } = this.props;

    if (!store.item) {
      return <div>Loading store..</div>;
    }

    return (<>
      {/* <LaunchModal pricingImageSrc={store.item.pricingImageSrc}/> */}
    </>);
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  { getStore }
)(Menu);
