import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import { SET_LOADING, CUSTOMER_GET_ALL, CUSTOMER_GET } from './types';

const url = apiBaseUrl + '/backend/customer';

export const getAllCustomer = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};

  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getAllCustomer?${param}`, config);

    // dispatch
    dispatch({
      type: CUSTOMER_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getCustomer = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      // headers: {
      //   Authorization: `Bearer ${localStorage.getCustomer('jwt')}`,
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
    };

    // response
    const response = await axios.get(`${url}/getCustomer?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: CUSTOMER_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
