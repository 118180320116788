import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

const Index = ({cartForm, setCartForm}) => {
  function incrementCount() {
    cartForm.quantity += 1;
    setCartForm({...cartForm})
  }

  function decrementCount() {
    if(cartForm.quantity > 1) {
      cartForm.quantity -= 1;
      setCartForm({...cartForm})
    }
  }
  
  return (
    <div className="App">

  <InputGroup className="mb-3" style={{width: "110px"}}>
    <Button className="prepend" variant="outline-secondary" id="button-addon1" onClick={decrementCount}>-</Button>
    <Form.Control
      aria-label="Example text with button addon"
      aria-describedby="basic-addon"
      readOnly
      value={cartForm.quantity}
      className="bg-none text-center"
    />
    <Button className="append" variant="outline-secondary" id="button-addon1" onClick={incrementCount}>+</Button>
  </InputGroup>
    </div>
  );
};

export default Index;
