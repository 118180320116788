import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postEditItem } from '../../../redux/backend/action/item';
import { getAllItemImage } from '../../../redux/backend/action/itemImage';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import FormBasic from './formBasic';
import FormPricing from './formPricing';
import FilterOption from './filterOption';

class FormAdd extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {
    // this.props.getAllItemImage(this.props.match.params.id);
  }

  componentWillReceiveProps(recievedProps) {
    const item = recievedProps.item.item;
    if (item) {
      this.setState({
        id: item.id,
        name: item.name,
        description: item.description,
        content: item.content,
        quantity: item.quantity,
        sellPrice: item.sellPrice,
        compareSellPrice: item.compareSellPrice,
        costPrice: item.costPrice,
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    } else if (name === 'quantity') {
      this.setState({ quantity: value });
    } else if (name === 'sellPrice') {
      this.setState({ sellPrice: value });
    } else if (name === 'compareSellPrice') {
      this.setState({ compareSellPrice: value });
    } else if (name === 'costPrice') {
      this.setState({ costPrice: value });
    }
  };

  handleChangeEditorDescription = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data });

    this.setState({ description: data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postEditItem(this.state.id, this.state, this.props.history);
  };

  render() {
    const { item } = this.props;

    if (item.length == 0) {
      return <>Loading..</>;
    }

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md='6'>
              {/* <h4>
                Edit Product
                {this.props.item.item && (
                  <>
                    <small> #{this.props.item.item.code}</small>
                  </>
                )}
              </h4> */}
              <div className='form-container'>
                <FormBasic
                  state={this.state}
                  handleChange={this.handleChange}
                  handleChangeEditorDescription={
                    this.handleChangeEditorDescription
                  }
                />
              </div>

              <div className='form-group'>
                <button
                  type='submit'
                  id='btn_submit'
                  onClick={(e) => this.handleSubmit(e)}
                  style={{ marginTop: '10px' }}
                  className='btn-success btn'
                >
                  Submit
                </button>
              </div>
            </Col>

            <Col md='6'>
              
              <div className='form-container'>
              <h4 style={{ marginBottom: '20px' }}>Pricing</h4>
                <FormPricing
                  state={this.state}
                  handleChange={this.handleChange}
                />
              </div>

              <h4 style={{ marginTop: '20px' }}>Filters</h4>
              <div className='form-container'>
                <FilterOption />
              </div>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  item: state.itemReducer.item,
  itemImageListing: state.itemImageReducer.itemImageListing,
});

export default connect(
  mapStateToProps,
  { postEditItem, getAllItemImage }
)(withRouter(FormAdd));
