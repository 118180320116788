import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import { SET_LOADING, ITEMIMAGE_GET_ALL, ITEMIMAGE_GET } from './types';

const url = apiBaseUrl + '/backend/itemimage';

export const postSort = (id, data, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        ////Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(`${url}/postSort?id=${id}`, data, config);

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      // dispatch
      dispatch({
        type: ITEMIMAGE_GET_ALL,
        payload: response.data.itemImageListingData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const getAllItemImage = (id, data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};

  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(
      `${url}/getAllItemImage?id=${id}&${param}`,
      config
    );

    // dispatch
    dispatch({
      type: ITEMIMAGE_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getItemImage = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        // //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.get(`${url}/getItemImage?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: ITEMIMAGE_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const deleteItemImage = (id, data, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        ////Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/deleteItemImage?id=${id}`,
      data,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      dispatch({
        type: ITEMIMAGE_GET_ALL,
        payload: response.data.itemImageListingData,
      });
      // history.push('/itemimage');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postAddItemImage = (formData) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postAddItemImage`,
      formData,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch({
      //   type: ITEMIMAGE_GET_ALL,
      //   payload: response.data.itemImageListingData,
      // });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postEditItemImage = (id, formData, history) => async (
  dispatch
) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postEditItemImage?id=${id}`,
      formData,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      history.push('/itemimage');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const getCountItemImage = (data) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getCountItemImage`, config);

    // dispatch
    // dispatch({ type: GET_COUNT_LAB_REPORTS, payload: response.data });
  } catch (err) {}
};
