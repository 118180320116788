import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import {
  SET_LOADING,
  MENU_GET_ALL,
  MENU_GET,
  TEMPLATE_MENU_GET_ALL,
} from './types';

const url = apiBaseUrl + '/backend/menu';

export const getAllMenu = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};

  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getAllMenu?${param}`, config);

    // dispatch
    dispatch({
      type: MENU_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getMenu = () => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getMenu`, config);

    // dispatch
    dispatch({
      type: TEMPLATE_MENU_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const postAddMenu = (formData, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    console.log('formData', formData);

    // response
    const response = await axios.post(`${url}/postAddMenu`, formData, config);

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: MENU_GET_ALL,
        payload: response.data.menuListingData,
      });

      // dispatch
      dispatch({
        type: TEMPLATE_MENU_GET_ALL,
        payload: response.data.menuTreedata,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postDeleteMenu = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(`${url}/postDeleteMenu?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: MENU_GET_ALL,
        payload: response.data.menuListingData,
      });

      // dispatch
      dispatch({
        type: TEMPLATE_MENU_GET_ALL,
        payload: response.data.menuTreedata,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postSortMenu = (data, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(`${url}/postSortMenu`, data, config);

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      // dispatch
      dispatch({
        type: MENU_GET_ALL,
        payload: response.data.menuListingData,
      });

      // dispatch
      dispatch({
        type: TEMPLATE_MENU_GET_ALL,
        payload: response.data.menuTreeData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
