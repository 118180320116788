import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import item from '../../redux/frontend/reducer/item';

class Index extends Component {
  render() {
    return (
      <>
        <h4>Add Product</h4>
        <div className='plane-border-container'>
          <div
            class='nav flex-column nav-pills'
            id='v-pills-tab'
            role='tablist'
            aria-orientation='vertical'
          >
            <Link to='#' className='nav-link active'>
              Add Basic Details
            </Link>

            <Link to='#' className='nav-link'>
              Upload Images
            </Link>

            <Link to='#' className='nav-link'>
              Assign Collection
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(Index);
