import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import {
  SET_LOADING,
  SET_PREVIEW_GENERATE_WAITING,
  DEFAULT_SECTION_GET_ALL,
  SECTION_GET_ALL,
  SECTION_GET,
} from './types';

const url = apiBaseUrl + '/backend/section';

export const getFooter = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};

  try {
    // headers
    const config = {
      headers: {
        //////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getFooter?${param}`, config);

    // dispatch
    dispatch({
      type: SECTION_GET,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getAllHomeSection = () => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getAllHomeSection`, config);

    // dispatch
    dispatch({
      type: SECTION_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};
export const getAllDefaultHomeSection = () => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getAllDefaultHomeSection`, config);

    // dispatch
    dispatch({
      type: DEFAULT_SECTION_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getAllSection = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};
  
  try {
    // headers
    const config = {
      headers: {
        //////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getAllSection?${param}`, config);

    // dispatch
    dispatch({
      type: SECTION_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getSection = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.get(`${url}/getSection?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: SECTION_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postGenerateJSPreview = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // dispatch
    dispatch({
      type: SET_PREVIEW_GENERATE_WAITING,
      payload: true,
    });

    // response
    const response = await axios.post(
      `${url}/postGenerateJSPreview?id=${id}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      // dispatch
      dispatch({
        type: SET_PREVIEW_GENERATE_WAITING,
        payload: false,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postGenerateJSPublish = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postGenerateJSPublish?id=${id}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postSortSection = (data, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(`${url}/postSortSection`, data, config);

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      // dispatch
      dispatch({
        type: SECTION_GET_ALL,
        payload: response.data.homeSectionListingData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postSectionTemplate = (formData, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postSectionTemplate`,
      formData,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      //history.push('/product');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postAddSection = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(`${url}/postAddSection?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: SECTION_GET_ALL,
        payload: response.data.homeSectionListingData,
      });

      // redirect
      history.push(
        '/setting/template/section/' + response.data.addedSection.id
      );
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postDuplicateSection = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postDuplicateSection?id=${id}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: SECTION_GET_ALL,
        payload: response.data.homeSectionListingData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postEditSectionName = (id, formData, history) => async (
  dispatch
) => {
  try {
    // headers
    const config = {
      headers: {
        //////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postEditSectionName?id=${id}`,
      formData,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: SECTION_GET_ALL,
        payload: response.data.homeSectionListingData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postDeleteSection = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postDeleteSection?id=${id}`,
      { 'x-authorization': `${localStorage.getItem('jwt')}` },
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: SECTION_GET_ALL,
        payload: response.data.homeSectionListingData,
      });

      // redirect
      history.push('/setting/template');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postEditSection = (id, formData, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        ////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postEditSection?id=${id}`,
      formData,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      history.push('/product');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
