import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import {
  SET_LOADING,
  ORDER_GET_ALL,
  ORDER_GET,
  ORDER_GET_COUNT,
} from './types';

const url = apiBaseUrl + '/frontend/order';

export const getCountOrderByCustomer = () => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // token param
    var paramToken = `x-authorization=${localStorage.getItem('jwt')}`;

    // response
    const response = await axios.get(
      `${url}/getCountOrderByCustomer?${paramToken}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({
        type: ORDER_GET_COUNT,
        payload: response.data,
      });
    }
  } catch (err) {}
};

export const getAllOrder = () => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // token param
    var paramToken = `x-authorization=${localStorage.getItem('jwt')}`;

    // response
    const response = await axios.get(
      `${url}/getAllOrder?${paramToken}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: ORDER_GET_ALL, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const getOrder = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // token param
    var paramToken = `x-authorization=${localStorage.getItem('jwt')}`;

    // response
    const response = await axios.get(
      `${url}/getOrder?id=${id}&${paramToken}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: ORDER_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postAddOrder = (history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postAddOrder`,
      { 'x-authorization': `${localStorage.getItem('jwt')}` },
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: ORDER_GET_COUNT,
        payload: response.data.countData,
      });
      history.push('/order');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postChangeQuantity = (id, data, history) => async (dispatch) => {
  try {
    // token
    // let token = localStorage ? localStorage.getItem('jwt') : 'rrr';
    // console.log('toknnn', token);

    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    data['x-authorization'] = `${localStorage.getItem('jwt')}`;

    // response
    const response = await axios.post(
      `${url}/postChangeQuantity?id=${id}`,
      data,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({ type: ORDER_GET_ALL, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const deleteOrder = (id, history) => async (dispatch) => {
  try {
    // token
    // let token = localStorage ? localStorage.getItem('jwt') : 'rrr';
    // console.log('toknnn', token);

    // headers
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/deleteOrder`,
      { id, 'x-authorization': `${localStorage.getItem('jwt')}` },
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({ type: ORDER_GET_ALL, payload: response.data });

      // dispatch
      dispatch({
        type: ORDER_GET_COUNT,
        payload: response.data.countData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
