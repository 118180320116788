import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../../constant/api";

import { SET_LOADING, PAGE_GET_ALL, PAGE_GET } from "./types";

const url = apiBaseUrl + "/backend/page";

export const getAllPage = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};

  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    // get
    const response = await axios.get(`${url}/getAllPage?${param}`, config);

    // dispatch
    dispatch({
      type: PAGE_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getPage = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      // headers: {
      //   //Authorization: `Bearer ${localStorage.getPage('jwt')}`,
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
    };

    // response
    const response = await axios.get(`${url}/getPage?id=${id}`, config);

    if (response.data.result === "SUCCESS") {
      dispatch({ type: PAGE_GET, payload: response.data });
    } else if (response.data.result === "ERROR") {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postAddPage = (formData, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    // response
    const response = await axios.post(`${url}/postAddPage`, formData, config);

    if (response.data.result === "SUCCESS") {
      toast.success(response.data.message);
      history.push("/page");
    } else if (response.data.result === "ERROR") {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postEditPage = (id, formData, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    // response
    const response = await axios.post(
      `${url}/postEditPage?id=${id}`,
      formData,
      config
    );

    if (response.data.result === "SUCCESS") {
      toast.success(response.data.message);
      history.push("/page");
    } else if (response.data.result === "ERROR") {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const deletePage = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    // response
    const response = await axios.post(
      `${url}/deletePage?id=${id}`,
      { id, "x-authorization": `${localStorage.getItem("jwt")}` },
      config
    );

    if (response.data.result === "SUCCESS") {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: PAGE_GET_ALL,
        payload: response.data.pageListingData,
      });

      history.push("/page");
    } else if (response.data.result === "ERROR") {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
