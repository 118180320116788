import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  getAllOrder,
  postChangeQuantity,
  deleteOrder,
} from '../../../redux/frontend/action/order';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import TableItem from './tableItems';

class ListTable extends Component {
  state = {
    page: '',
  };

  handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete it?')) {
      this.props.deleteOrder(id);
    }
  };

  render() {
    const { orderListing } = this.props;

    if (!orderListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <Accordion
          defaultActiveKey='0'
          style={{ borderBottom: '1px solid #dedede' }}
        >
          {orderListing.items.map((order, index) => {
            return (
              <>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey={index}>
                    <Row>
                      <Col md='6'>
                        Date: {order.date}
                        <div>Item count ({order.itemCount})</div>
                        <div>
                          <b>Total: {order.totalLabel}</b>
                        </div>
                      </Col>

                      <Col md='3'>
                        <b>{order.statusLabel}</b>

                        {/* remark */}
                        {order.remark && <div>Remark: {order.remark}</div>}
                      </Col>

                      <Col md='3' className='text-right'>
                        <Link className='btn btn-outline-success btn-sm'>
                          Detail
                        </Link>
                      </Col>
                    </Row>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey={index}>
                    <Card.Body>
                      <TableItem orderItems={order.orderItems} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </>
            );
          })}
        </Accordion>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orderListing: state.orderReducer.orderListing,
});
export default connect(
  mapStateToProps,
  { getAllOrder, postChangeQuantity, deleteOrder }
)(ListTable);
