import {
  REGISTER_SUBMIT,
  SUBMITTING_STARTED,
  SUBMITTING_DONE,
} from '../action/types';

const initialState = {
  data: [],
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTING_STARTED: {
      return {
        ...state,
        submitting: true,
      };
    }

    case SUBMITTING_DONE: {
      return {
        ...state,
        submitting: false,
      };
    }
    case REGISTER_SUBMIT: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }

    default:
      return state;
  }
};
