import axios from 'axios';
import { apiBaseUrl } from '../../../constant/api';

import { push } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

import { browserHistory } from 'react-router';
import { LOGIN_SUBMIT } from './types';

const api_url = apiBaseUrl + '/backend/account';

export const submitLogin = (data, history) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${api_url}/login`,
        {
          data: data,
          baseUrl: window.location.pathname.replace('/login', ''),
        },

        // axios.post(`${api_url}/login`,
        // {'name':name, 'password':password},
        /* NOTE: for cors put urlencoded NOT json */
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .then((response) => {
        var response = response.data;

        if (response.result === 'SUCCESS') {
          /* store in local storage */
          localStorage.setItem('currentAdmin', JSON.stringify(response.data));
          localStorage.setItem('jwt', response.token);

          // logout customer during admin login to avoid any conflict
          localStorage.removeItem('currentCustomer');

          history.push('/dashboard');

          dispatch({ type: LOGIN_SUBMIT, payload: { payload: response } });
        } else if (response.result === 'ERROR') {
          toast.error(response.message);
        }
      })
      .then(() => {
        // history.push(`/index`)
      })
      .catch((e) => console.log(e));

    //dispatch({type: LOGIN_SUBMIT, payload: {'payload': []}})
  };
};

export const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('currentCustomer');
  localStorage.removeItem('currentAdmin');
  localStorage.removeItem('token');
  // currentUserSubject.next(null);
};
