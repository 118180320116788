import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postSectionTemplate } from '../../../../redux/backend/action/sectionTemplate';

import { Accordion, Card, Button } from 'react-bootstrap';
import Select from 'react-select';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import InputColorPicker from './inputColorPicker';
import { apiBaseUrl } from '../../../../constant/api';
const editorImageUploadUrl = apiBaseUrl + '/backend/config/postEditorUpload';

class Index extends Component {
  state = {
    triggerPost: false,
  };
  handleChange = (e, data) => {
    let section = this.props.section;

    let formData = new FormData();
    formData.append('value', e.target.value);
    formData.append('name', e.target.name);
    formData.append('id', data.id);

    if (data.postNow && this.state.triggerPost == true) {
      console.log('posting api');
      this.setState({
        triggerPost: false,
      });

      this.props.postSectionTemplate(section.id, formData);
    } else {
      this.setState({
        triggerPost: true,
      });
    }
  };
  handleChangeColor = (color, item) => {
    let section = this.props.section;

    let formData = new FormData();
    formData.append('value', color);
    formData.append('name', item.name);
    formData.append('id', item.id);

    this.setState({
      triggerPost: false,
    });

    this.props.postSectionTemplate(section.id, formData);
  };

  handleChangeEditorContent = (event, editor, data) => {
    let section = this.props.section;
    const editorContent = editor.getData();
    //console.log({ event, editor, editorContent, data });

    let formData = new FormData();
    formData.append('value', editorContent);
    formData.append('name', data.name);
    formData.append('id', data.id);

    if (data.postNow && this.state.triggerPost == true) {
      console.log('posting api');
      this.setState({
        triggerPost: false,
      });

      this.props.postSectionTemplate(section.id, formData);
    } else {
      console.log('here');

      this.setState({
        triggerPost: true,
      });
    }
  };

  handleImageChange = (e, data) => {
    let section = this.props.section;

    let imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append('image', imageFile);
    formData.append('value', e.target.value);
    formData.append('name', e.target.name);
    formData.append('id', data.id);

    // console.log('formData', formData);
    this.props.postSectionTemplate(section.id, formData);
  };

  handleChangeDropdown = (e, data) => {
    const section = this.props.section;

    let formData = new FormData();
    formData.append('value', JSON.stringify(e));
    formData.append('name', data.name);
    formData.append('id', data.id);

    this.props.postSectionTemplate(section.id, formData);
  };

  handleChangeCheckbox = (e, data) => {
    if (e.isChecked) {
      console.log('checked');
    } else {
      console.log('UN-checked');
    }
    console.log(e);
    console.log(e.target.name);
    console.log(e.target.value);
    // const section = this.props.section;

    // let formData = new FormData();
    // formData.append('value', JSON.stringify(e));
    // formData.append('name', data.name);
    // formData.append('id', data.id);

    // this.props.postSectionTemplate(section.id, formData);
  };

  hexToRgb = (hex) => {
    if (hex == null || !hex.includes('#')) {
      return { r: '', g: '', b: '' };
    }

    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: '', g: '', b: '' };
  };

  render() {
    const { item, store, index } = this.props;

    return (
      <>
        {item.isHeading === true && (
          <>
            <h4>{item.name}</h4>
          </>
        )}

        <>
          {/* input-productSelect */}
          {item.inputType === 'color' && (
            <div className='mb-20'>
              {item.name}

              <InputColorPicker
                item={item}
                handleChangeColor={this.handleChangeColor}
                colorR={this.hexToRgb(item.content).r}
                colorG={this.hexToRgb(item.content).g}
                colorB={this.hexToRgb(item.content).b}
              />
            </div>
          )}

          {/* input-text */}
          {item.inputType === 'text' && (
            <div className='mb-20'>
              {item.name}
              <input
                type='text'
                name={'input' + item.id + item.inputName}
                key={item.id}
                className='form-control form-control-sm'
                defaultValue={item.content}
                onChange={(e) =>
                  this.handleChange(e, {
                    id: item.id,
                  })
                }
                onBlur={(e) => {
                  this.handleChange(e, {
                    id: item.id,
                    postNow: true,
                  });
                }}
              />
            </div>
          )}

          {/* input-dropdown */}
          {item.inputType === 'dropdown' && (
            <div className='mb-20'>
              {item.name}
              <Select
                name={'input' + item.id}
                defaultValue={{
                  label: item.contentJson ? item.contentJson.label : '',
                  value: item.contentJson ? item.contentJson.value : '',
                }}
                options={item.dropdownOptions}
                // options={[
                //   { label: 'bbb', value: '11' },
                //   { label: 'aaa', value: '22' },
                // ]}
                onChange={(e) =>
                  this.handleChangeDropdown(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />
            </div>
          )}

          {/* input-textArea */}
          {item.inputType === 'textArea' && (
            <div className='mb-20'>
              {item.name}
              <CKEditor
                editor={ClassicEditor}
                data={item.content}
                config={{
                  ckfinder: {
                    // Upload the images to the server using the CKFinder QuickUpload command.
                    uploadUrl:
                      editorImageUploadUrl +
                      '?command=QuickUpload&type=Images&responseType=json',
                  },
                }}
                // onInit={(editor) => {
                //   // You can store the "editor" and use when it is needed.
                // }}
                // onChange={this.handleChangeEditorContent}
                onChange={(e, editor) =>
                  this.handleChangeEditorContent(e, editor, {
                    id: item.id,
                    name: item.name,
                  })
                }
                onBlur={(e, editor) => {
                  this.handleChangeEditorContent(e, editor, {
                    id: item.id,
                    name: item.name,
                    postNow: true,
                  });
                }}
                onFocus={(event, editor) => {
                  // console.log('Focus.', editor);
                }}
              />
            </div>
          )}

          {/* input-image */}
          {item.inputType === 'image' && (
            <div className='mb-20'>
              {item.name}

              {item.content && (
                <div>
                  <img
                    src={store.item.fileBaseUrl + item.content}
                    style={{ maxWidth: '100px', marginBottom: '10px' }}
                  />
                </div>
              )}

              <input
                type='file'
                name={item.inputName}
                key={item.id}
                className=''
                onChange={(e) =>
                  this.handleImageChange(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />
            </div>
          )}

          {/* input-productSelect */}
          {item.inputType === 'productSelect' && (
            <div className='mb-20'>
              {item.name}
              <Select
                name={'input' + item.id}
                defaultValue={item.contentJson}
                options={item.dropdownOptions}
                onChange={(e) =>
                  this.handleChangeDropdown(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />
            </div>
          )}

          {/* input-collectionSelect */}
          {item.inputType === 'collectionSelect' && (
            <div className='mb-20' style={{ minHeight: '350px' }}>
              {item.name}
              <Select
                name={'input' + item.id}
                defaultValue={item.contentJson}
                options={item.dropdownOptions}
                onChange={(e) =>
                  this.handleChangeDropdown(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />

              {/* input-checkbox */}
              {/* {item.inputType === 'checkbox' && (
            <div className='mb-20'>
              <input
                type='checkbox'
                name={'input' + item.id}
                checked
                key={item.id}
                id={item.id}
                className=''
                defaultValue={item.content}
                onChange={(e) =>
                  this.handleChangeCheckbox(e, {
                    id: item.id,
                    name: item.name,
                  })
                }
              />
              <label for={item.id} className='ml-3'>
                {item.name}
              </label>
            </div>
          )} */}

              {/* <input
                type='file'
                name={'input' + item.id}
                key={item.id}
                className=''
                defaultValue={item.content}
                onChange={(e) =>
                  this.handleChange(e, {
                    key: item.id,
                    name: 'input' + item.id,
                  })
                }
              /> */}
            </div>
          )}
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  { postSectionTemplate }
)(Index);
