import React, { Component } from 'react';

import { connect } from 'react-redux';
import FormAdd from './form';
// import {} from '../../../redux/action/customer'

import AddProductNav from '../nav-new-product';

class Index extends Component {
  render() {
    return (
      <>
        <div class='row'>
          <div class='col-2'>
            <AddProductNav />
          </div>

          <div class='col-10'>
            {/* <h4>
              Edit Basic Details<small></small>
            </h4> */}

            <div class='tab-content' id='v-pills-tabContent'>
              <FormAdd />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(Index);
