import React, { Component } from 'react';
import { Carousel, Card, CardGroup, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import ImageWithTextLeft from './imageWithTextLeft';
import ImageWithTextRight from './imageWithTextRight';

class Index extends Component {
  createMarkup = (text) => {
    return { __html: text };
  };

  render() {
    const { data, store } = this.props;
    if (!data || !data.items || !store) return <></>;

    let heading = data.heading;
    let headerAlignment = data.headerAlignment;
    headerAlignment = headerAlignment ? headerAlignment : 'left';

    let imageAlignment = data.imageAlignment;
    imageAlignment = imageAlignment ? imageAlignment : 'left';

    let height = data.height;
    let heightStyle = height ? height : '100px';
    let width = data.width;
    let widthStyle = width ? width : '100px';
    let text = data.text;

    let items = data.items;
    let imageCount = items.length;

    let wd = 1;
    if (imageCount == 1) wd = 12;
    else if (imageCount == 2) wd = 6;
    else if (imageCount == 3) wd = 4;
    else if (imageCount == 4) wd = 3;
    else if (imageCount == 5) wd = 2;
    else if (imageCount > 5) wd = 1;

    return (
      <>
        <div style={{ maxWidth: '75rem', margin: '0px auto' }}>
          <h1 className={'text-' + headerAlignment}>{heading}</h1>
        </div>

        {/* left image */}
        {imageAlignment === 'left' && <ImageWithTextLeft data={data} />}

        {/* right image */}
        {imageAlignment === 'right' && <ImageWithTextRight data={data} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
