import React, { Component } from 'react';

import { connect } from 'react-redux';
import { deleteCollectionItem } from '../../../redux/backend/action/item';

import { Modal, Button, Row, Col, Form, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

class FormAdd extends Component {
  state = {};

  handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure, you want to delete?')) {
      this.props.deleteCollectionItem(id);
    }
  };
  render() {
    const { itemCollectionListing } = this.props;

    if (!itemCollectionListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <ListGroup>
          {itemCollectionListing.items &&
            itemCollectionListing.items.map((collectionItem, colIndex) => {
              var collection = collectionItem.collection;
              var levelJson = collection.levelJson;

              var levelJsonP = JSON.parse(levelJson);

              return (
                <>
                  <ListGroup.Item>
                    {levelJsonP == '' ? '' + collection.name : ''}
                    {levelJsonP ? levelJsonP.join(' > ') : ''}

                    <Link
                      to=''
                      className='btn btn-outline-danger btn-sm pull-right'
                      onClick={(e) => this.handleDelete(e, collectionItem.id)}
                    >
                      Delete
                    </Link>
                  </ListGroup.Item>
                </>
              );
            })}
        </ListGroup>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  itemCollectionListing: state.itemReducer.itemCollectionListing,
});

export default connect(
  mapStateToProps,
  { deleteCollectionItem }
)(withRouter(FormAdd));
