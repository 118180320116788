import React, { Component } from 'react';
// import {Router, Route, Switch} from 'react-router'
import { isLoggedIn, loggedInCustomer, handleLogout } from '../../../src/common';

import { BrowserRouter as Router, Link } from 'react-router-dom';

class Logout extends Component {
  componentDidMount() {
    handleLogout();
    this.props.history.push('/login');
  }

  render() {
    return <>Logout screen</>;
  }
}

export default Logout;
