import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col, Form } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { postVerify } from '../../redux/frontend/action/login';

import Header from '../header';
import Login from './login';
import Register from './register';

class Index extends Component {
  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    this.setState({
      verifyCode: value,
      encodeData: this.props.match.params.data,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postVerify(this.state, this.props.history);
  };

  render() {
    // const currentCustomer = localStorage.getItem('currentCustomer');

    return (
      <>
        <Header />

        {/* if loggedin, redirect to my-order */}
        {/* {currentCustomer && <Redirect to='/order' />} */}

        <div className='main-container' style={{ width: '100%' }}>
          <div style={{ maxWidth: '75rem', margin: '0px auto' }}>
            <h2 className='mb-30'>REGISTER</h2>

            <Row>
              <Col md='7'>
                <Form>
                  <Form.Group>
                    <Form.Label>Verification Code</Form.Label>
                    <Form.Control
                      name='code'
                      type='text'
                      placeholder='Enter code'
                      onChange={this.handleChange}
                    />
                  </Form.Group>

                  <Button
                    variant='btn btn-outline-success'
                    type='submit'
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  { postVerify }
)(withRouter(Index));
