import React, { Component } from 'react';

export default class Index extends Component {
  render() {
    const { data } = this.props;
    if (!data) return <></>;

    let height = data.height;
    let heightStyle = height ? height + 'px' : '0px';

    return (
      <>
        <div className='plane-border-container' style={{ padding: '0' }}>
          <div style={{ height: heightStyle, background: '#fff' }}></div>
        </div>
      </>
    );
  }
}
