import React, { Component } from 'react';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { getStore } from '../../redux/frontend/action/store';
import Radium, { Style } from 'radium';
// import LaunchModal from './launchModal'

class Index extends Component {
  state = {};
  componentDidMount() {
    this.props.getStore();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /**
     * new Project in town ?
     */
    if (!this.state.store) {
      this.props.getStore();

      /* set document title */
      document.title = this.props.store.item.name;

      this.setState({
        store: this.props.store,
      });
    }
  }

  render() {
    const { store } = this.props;

    if (!store.item) {
      return <></>;
    }

    let linkColor = store.item.linkColor;
    linkColor = linkColor ? linkColor : '#007bff';

    let priceColor = store.item.priceColor;
    priceColor = priceColor ? priceColor : '#e28a07';

    let headerBackgroundColor = store.item.headerBackgroundColor;
    headerBackgroundColor = headerBackgroundColor
      ? headerBackgroundColor
      : '#ECE3E3';

    let pageBackgroundColor = store.item.pageBackgroundColor;
    pageBackgroundColor = pageBackgroundColor ? pageBackgroundColor : '#ECE3E3';

    return (
      <>
        <Style
          scopeSelector='body'
          rules={{
            '.header-block': {
              background: headerBackgroundColor,
            },
            a: {
              color: linkColor + ' !important',
            },
            'a:visited': {
              color: '#999',
            },
            '.price-block': {
              color: priceColor,
            },
            '.view-more-btn': {
              color: '#fff !important',
            },
          }}
        />
        {/* <LaunchModal pricingImageSrc={store.item.pricingImageSrc}/> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  { getStore }
)(Index);
