import React, { Component } from 'react';

import { connect } from 'react-redux';
import FormEdit from './form';
import { getItem } from '../../../redux/backend/action/item';

import ManageNav from '../nav';
class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };
  componentDidMount() {
    this.props.getItem(this.props.match.params.id);
  }

  render() {
    
    const { productRow } = this.props;
    if (!productRow.item) return <>Loading..</>;

    const product = productRow.item;

    return (
      <>
        <h4>Edit: <small>{product.name} (#{product.code})</small></h4>
        <div class='row'>
          <div class='col-2'>
            <ManageNav itemId={this.state.id} activeLabel='edit-basic' />
          </div>

          <div class='col-10'>
            <div class='tab-content' id='v-pills-tabContent'>
              <FormEdit />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  { getItem }
)(Index);
