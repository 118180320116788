import React, { useState, useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead'; // ES2015
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { apiBaseUrl } from '../../constant/api';
import axios from 'axios';

const url = apiBaseUrl + '/frontend/item';

const AsyncExample = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [redirectUrl, redirectNow] = useState('');

  const handleSearch = (query) => {
    setIsLoading(true);

    /* set domain to connect db in api */
    var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];

    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-DOMAIN': domain,
      },
    };

    // fetch(`${SEARCH_URI}?q=${query}+in:login&page=1&per_page=50`)
    fetch(`${url}/getAllItem?query=${query}`, config)
      .then((resp) => resp.json())
      .then(({ items }) => {
        const options = items.map((i) => ({
          // avatar_url: i.avatar_url,
          id: i.id,
          name: i.name,
          slug: i.slug,
        }));

        setOptions(options);
        setIsLoading(false);
      });
  };
  const renderRedirect = (e) => {
    console.log('item', e);
    const itemId = e[0].id;
    const slug = e[0].slug;
    redirectNow('/product/' + slug + '/' + itemId);
  };

  return (
    <>
      {redirectUrl && <Redirect push to={redirectUrl} />}

      <AsyncTypeahead
        id='async-example'
        isLoading={isLoading}
        labelKey='name'
        minLength={3}
        onSearch={handleSearch}
        options={options}
        placeholder='Search...'
        onChange={(e) => renderRedirect(e)}
        renderMenuItemChildren={(option, props) => (
          <>
            {/* <img
              alt={option.login}
              src={option.avatar_url}
              style={{
                height: '24px',
                marginRight: '10px',
                width: '24px',
              }}
            />*/}
            <span>{option.name}</span>
          </>
        )}
      />
    </>
  );
};

export default AsyncExample;
