import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAllCart } from '../../redux/frontend/action/cart';
import { postAddOrder } from '../../redux/frontend/action/order';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// import history from '../../../history';

class ListTable extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to place order?')) {
      this.props.postAddOrder(this.props.history);
    }
  };

  render() {
    const { cartListing } = this.props;

    if (!cartListing.items) {
      return <div>No data found</div>;
    }

    const cartSummary = cartListing.cartSummary;

    return (
      <>
        <div
          style={{
            background: 'rgb(239 239 239)',
            padding: '10px',
            marginBottom: '3px',
          }}
        >
          <h3>Order Summary</h3>
          <Row>
            <Col md='6'>
              Sut-total
              <div style={{ fontSize: '12px' }}>
                (Total item: {cartSummary.itemCount})
              </div>
            </Col>
            <Col md='6' className='text-right'>
              {cartSummary.subTotalLabel}
            </Col>
          </Row>

          <Row className='mt-15'>
            <Col md='12'>
              <div
                className='text-muted font-italic'
                style={{ fontSize: '12px' }}
              >
                Shipping charge is excluded
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col md='12'>
            {cartListing.items.length == 0 && (
              <>
                <Button className='btn btn-block btn-primary' disabled>
                  PLACE ORDER
                </Button>
              </>
            )}

            {cartListing.items.length > 0 && (
              <>
                <Button
                  className='btn btn-block btn-primary'
                  onClick={(e) => this.handleSubmit(e)}
                >
                  PLACE ORDER
                </Button>
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartListing: state.cartReducer.cartListing,
});
export default connect(
  mapStateToProps,
  { getAllCart, postAddOrder }
)(withRouter(ListTable));
