import React, { Component } from 'react';
import { Carousel, Card, CardGroup, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { postAddWatchlist } from '../../../redux/frontend/action/watchlist';
import { postAddCart } from '../../../redux/frontend/action/cart';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class Index extends Component {
  handleAddToCart = (e, id) => {
    e.preventDefault();
    this.props.postAddCart(id);
    console.log('cart here ', id);
  };

  handleAddToWishlist = (e, id) => {
    e.preventDefault();
    this.props.postAddWatchlist(id);
    console.log('wishlist here', id);
  };

  render() {
    const { data, store } = this.props;
    if (!data || !data.items || !store.item) return <></>;

    let heading = data.heading;
    let headerAlignment = data.headerAlignment;
    headerAlignment = headerAlignment ? headerAlignment : 'left';

    let height = data.height;
    let heightStyle = height ? height + 'px' : '100px';

    let slidesToShow = data.slidesToShow;
    slidesToShow = slidesToShow ? slidesToShow : 6;

    let autoPlaySpeed = data.autoPlaySpeed;
    autoPlaySpeed = autoPlaySpeed ? autoPlaySpeed : 7000;

    let autoPlay = data.autoPlay;
    autoPlay = autoPlay === 'no' ? false : true;

    let showDots = data.showDots;
    showDots = showDots === 'yes' ? true : false;

    let showMoreLabel = data.showMoreLabel;
    let showMoreLink = data.showMoreLink;

    let slideItems = data.items;

    console.log('imageSlider', data);

    const settingsProductSlider = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 7000,
      cssEase: 'linear',
    };

    return (
      <>
        <div style={{ maxWidth: '90rem', margin: '0px auto' }}>
          <h1 className={'text-' + headerAlignment}>{heading}</h1>
        </div>

        <div style={{ maxWidth: '90rem', margin: '0px auto' }}>
          {slideItems.length > 0 && (
            <Slider
              {...settingsProductSlider}
              {...{
                dots: showDots,
                slidesToShow: parseInt(slidesToShow),
                slidesToScroll: 1,
                autoplay: autoPlay,
                autoplaySpeed: parseInt(autoPlaySpeed),
              }}
            >
              {slideItems.map((item, index) => {
                return (
                  <>
                    {item.externalLink && (
                      <a href={item.externalLink} target='_blank'>
                        <img
                          className={'height-' + heightStyle}
                          style={{
                            maxWidth: '100%',
                            margin: '10px',
                            padding: '2%',
                          }}
                          src={store.item.fileBaseUrl + item.imageSrc}
                        />
                      </a>
                    )}

                    {!item.externalLink && (
                      <Link to={item.link}>
                        <img
                          className={'height-' + heightStyle}
                          style={{
                            maxWidth: '100%',
                            margin: '10px',
                            padding: '2%',
                          }}
                          src={store.item.fileBaseUrl + item.imageSrc}
                        />
                      </Link>
                    )}
                  </>
                );
              })}
            </Slider>
          )}

          {slideItems.length > 0 && (
            <>
              {/* show more link */}
              {showMoreLabel && showMoreLink && (
                <>
                  <Col md='12' className='text-right'>
                    <Link
                      to={showMoreLink}
                      className='btn btn-dark btn-small view-more-btn'
                      style={{
                        marginTop: '-5px',
                        marginRight: '-15px',
                      }}
                    >
                      {showMoreLabel}
                    </Link>
                  </Col>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  { postAddCart, postAddWatchlist }
)(withRouter(Index));
