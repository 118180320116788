import React, { Component } from 'react';
import { Carousel, Card, CardGroup, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

class Index extends Component {
  createMarkup = (text) => {
    return { __html: text };
  };

  render() {
    const { data, store } = this.props;
    if (!data || !store) return <></>;

    let heading = data.heading;
    let headerAlignment = data.headerAlignment;
    headerAlignment = headerAlignment ? headerAlignment : 'left';

    let imageAlignment = data.imageAlignment;

    let height = data.height;
    height = height ? height + 'px' : '200px';

    let width = data.width;
    width = width ? width + 'px' : '400px';

    let text = data.text;
    let textTopMargin = data.textTopMargin;
    textTopMargin = textTopMargin ? textTopMargin + 'px' : '0';

    let items = data.items;
    let imageCount = items.length;

    let wd = 1;
    if (imageCount == 1) wd = 12;
    else if (imageCount == 2) wd = 6;
    else if (imageCount == 3) wd = 4;
    else if (imageCount == 4) wd = 3;
    else if (imageCount == 5) wd = 2;
    else if (imageCount > 5) wd = 1;

    return (
      <>
        {/* right image */}
        <div style={{ maxWidth: '75rem', margin: '0px auto' }}>
          <Row>
            <Col className='md-6 overflow-auto'>
              <div style={{ marginTop: textTopMargin, textAlign: 'center' }}>
                <div
                  dangerouslySetInnerHTML={this.createMarkup(text)}
                  className='editor'
                ></div>
              </div>
            </Col>
            <Col className={'md-6'}>
              <Row className='m-0'>
                {items.map((item, index) => {
                  return (
                    <Col className={'p-0 md-' + wd}>
                      <Link to='#'>
                        <div className='image-block'>
                          <img
                            src={store.item.fileBaseUrl + item.imageSrc}
                            style={{
                              maxWidth: '100%',
                              height: height,
                              width: width,
                            }}
                            className='image-border-8'
                          />
                        </div>
                      </Link>

                      {/* {item.externalLink && (
                        <>
                          <a href={item.externalLink} target='_blank'>
                            <div className='image-block'>
                              <img
                                src={store.item.fileBaseUrl + item.imageSrc}
                                style={{
                                  maxWidth: '100%',
                                  height: height,
                                  width: width,
                                }}
                              />
                            </div>
                          </a>
                        </>
                      )}

                      {!item.externalLink && (
                        <>
                          <Link to={item.link}>
                            <div className='image-block'>
                              <img
                                src={store.item.fileBaseUrl + item.imageSrc}
                                style={{
                                  maxWidth: '100%',
                                  height: height,
                                  width: width,
                                }}
                              />
                            </div>
                          </Link>
                        </>
                      )} */}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
