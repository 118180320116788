import axios from 'axios';
import { apiBaseUrl } from '../../../constant/api';

import { push } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

import { browserHistory } from 'react-router';
import { LOGIN_SUBMIT, SUBMITTING_STARTED, SUBMITTING_DONE } from './types';

const url = apiBaseUrl + '/frontend/account';

export const submitLogin = (data, history) => {
  return (dispatch, getState) => {
    /* submitting started */
    dispatch({ type: SUBMITTING_STARTED, payload: true });

    axios
      .post(
        `${url}/login`,
        {
          data: data,
          baseUrl: window.location.pathname.replace('/login', ''),
        },

        // axios.post(`${api_url}/login`,
        // {'name':name, 'password':password},
        /* NOTE: for cors put urlencoded NOT json */
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .then((response) => {
        /* submitting done */
        dispatch({ type: SUBMITTING_DONE, payload: true });

        if (response.data.result === 'SUCCESS') {
          /* store in local storage */
          localStorage.setItem(
            'currentCustomer',
            JSON.stringify(response.data.tokenUser)
          );
          localStorage.setItem('jwt', response.data.token);

          // logout admin during customer login to avoid any conflict
          localStorage.removeItem('currentAdmin');
          
          /* console */
          var currentCustomer = localStorage.getItem('currentCustomer');
          console.log('here is currentCustomer', currentCustomer);

          dispatch({ type: LOGIN_SUBMIT, payload: { payload: response } });

          history.push('/order');
        } else if (response.data.result === 'ERROR') {
          toast.error(response.data.message);
        }
      })
      .then(() => {
        // history.push(`/index`)
      })
      .catch((e) => console.log(e));

    //dispatch({type: LOGIN_SUBMIT, payload: {'payload': []}})
  };
};

export const postVerify = (data, history) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${url}/postVerify`,
        data,

        /* NOTE: for cors put urlencoded NOT json */
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .then((response) => {
        if (response.data.result === 'SUCCESS') {
          toast.success(response.data.message);

          /* store in local storage */
          localStorage.setItem(
            'currentCustomer',
            JSON.stringify(response.data.tokenUser)
          );
          localStorage.setItem('jwt', response.data.token);

          // logout admin during customer login to avoid any conflict
          localStorage.removeItem('currentAdmin');

          // dispatch
          dispatch({ type: LOGIN_SUBMIT, payload: { payload: response } });

          history.push('/order');
        } else if (response.data.result === 'ERROR') {
          toast.error(response.data.message);
        }
      })
      .then(() => {
        // history.push(`/index`)
      })
      .catch((e) => console.log(e));
  };
};

export const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('currentCustomer');
  localStorage.removeItem('currentAdmin');
  localStorage.removeItem('token');
  // currentCustomerSubject.next(null);
};
