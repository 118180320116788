import React, { Component } from 'react';
import { ListGroup, Card, Button, Row, Col, Accordion } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getAllDefaultHomeSection,
  postAddSection,
} from '../../../redux/backend/action/section';

import { Link } from 'react-router-dom';

class Index extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    this.props.getAllDefaultHomeSection();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /**
     * new Project in town ?
     */
    if (
      this.state.items.length == 0 &&
      this.props.defaultSectionListing.items
    ) {
      console.log('trigger');
      this.setState({
        items: this.props.defaultSectionListing.items,
      });
    }
  }

  handleAdd = (e, id) => {
    e.preventDefault();

    this.props.postAddSection(id, this.props.history);
  };

  render() {
    const { defaultSectionListing, store } = this.props;

    if (!defaultSectionListing.items || !store.item) {
      return <div>Loading..</div>;
    }

    console.log('defaultSectionListing.items', defaultSectionListing.items);
    return (
      <>
        <Accordion>
          <Card bg='warning' style={{ borderBottom: '1px solid #e4ad0c' }}>
            <Accordion.Toggle as={Card.Header} eventKey='0' bg='warning'>
              <b>Add Section:</b>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              {/* <Card.Body> */}
              <div style={{ marginBottom: '0' }}>
                <ListGroup>
                  {defaultSectionListing.items.map((defaultSection, index) => {

                    {/* if product disabled, do-not show template */}
                    if(defaultSection.type === 'product-list' && store.item.disableProduct > 0 ){
                      return <></>
                    }

                    return (
                      <>
                        <ListGroup.Item variant='warning'>
                          {defaultSection.name}

                          <Row>
                            <Col md='9'>
                              <img
                                style={{ maxWidth: '100%' }}
                                src={`${store.item.fileBaseUrl}/../template/defaultSection/${defaultSection.type}.png`}
                                // src={`${store.item.baseUrl}/ecom/api/app/cdn/template/defaultSection/${defaultSection.type}.png`}
                              />
                            </Col>
                            <Col md='3'>
                              <span className='pull-right'>
                                <Link
                                  to='#'
                                  onClick={(e) =>
                                    this.handleAdd(e, defaultSection.id)
                                  }
                                  style={{ color: '#4c4006' }}
                                >
                                  <i class='fa fa-plus' aria-hidden='true'></i>{' '}
                                  ADD
                                </Link>
                              </span>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </>
                    );
                  })}
                </ListGroup>
              </div>
              {/* </Card.Body> */}
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
  defaultSectionListing: state.sectionReducer.defaultSectionListing,
});
export default connect(
  mapStateToProps,
  { getAllDefaultHomeSection, postAddSection }
)(withRouter(Index));
