import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllCustomer } from '../../../redux/backend/action/customer';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import history from '../../../history';
import PaginateTable from '../../../components/PaginateTable';

class ListTable extends Component {
  state = {
    customer: '',
  };

  handlePageChange = (page) => {
    this.setState({ Customer_page: page });

    /* get all pages */
    let param = this.state;

    /* send current page immediately as state can't get current value */
    param.Customer_page = page;

    this.reloadGrid(param);
  };

  handleFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    /* get all customers */
    let param = this.state;

    if (name === 'filterName') {
      this.setState({ filterName: value });
      param.filterName = value;
    } else if (name === 'filterAddress') {
      this.setState({ filterAddress: value });
      param.filterAddress = value;
    } else if (name === 'filterEmail') {
      this.setState({ filterEmail: value });
      param.filterEmail = value;
    } else if (name === 'filterMobile') {
      this.setState({ filterMobile: value });
      param.filterMobile = value;
    } else if (name === 'filterPhone') {
      this.setState({ filterPhone: value });
      param.filterPhone = value;
    }

    this.reloadGrid(param);
  };

  reloadGrid = (param) => {
    this.props.getAllCustomer(param);
  };

  render() {
    const { customerListing } = this.props;

    if (!customerListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <Table responsive size='sm' className='tbl-list-view'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Created At</th>
              <th></th>
            </tr>

            <tr className='customers' style={{ background: '#E3EAF2' }}>
              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterName'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterAddress'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterEmail'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterMobile'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterPhone'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                {/* <input
                  type='text'
                  name='customerStatus'
                  className='form-control'
                  onChange={this.handleFilterChange}
                /> */}
              </th>

              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {customerListing.items.map((item, index) => {
              return (
                <>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.address}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile}</td>
                    <td>{item.phone}</td>
                    <td>{item.status}</td>

                    <td>{item.createdAt}</td>
                    <td align='right'>
                      <Link
                        className='btn btn-small btn-outline-default'
                        to={'/customer/view/' + item.id}
                        style={{ marginLeft: '5px' }}
                      >
                        View
                      </Link>{' '}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>

        <PaginateTable
          listing={this.props.customerListing}
          activeCustomer={this.state.customer}
          handlePageChange={this.handlePageChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerListing: state.customerReducer.customerListing,
});
export default connect(
  mapStateToProps,
  { getAllCustomer }
)(ListTable);
