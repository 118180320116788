import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  NavLink,
} from "react-router-dom";

import { Row, Col, Nav, Navbar, Brand, NavDropdown } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

import Clock from "react-digital-clock";
import Moment from "react-moment";
import { isLoggedIn, loggedInAdmin } from "../common";

import SectionView from "../feature-backend/section/view";

// user
import UserList from "../feature-backend/user/list";
import UserAdd from "../feature-backend/user/create";
import UserView from "../feature-backend/user/view";
import UserEdit from "../feature-backend/user/edit";
import UserChangePassword from "../feature-backend/user/change-password";

// item
import ItemList from "../feature-backend/item/list";
import ItemAdd from "../feature-backend/item/create";
import ItemEdit from "../feature-backend/item/edit";
import ItemAddImage from "../feature-backend/item/add-image";
import ItemAddToCollection from "../feature-backend/item/add-collection";
import ItemView from "../feature-backend/item/view";

// collection
import CollectionList from "../feature-backend/collection/list";
import CollectionAdd from "../feature-backend/collection/create";
import CollectionEdit from "../feature-backend/collection/edit";
import CollectionAddFilter from "../feature-backend/collection/add-filter";
import CollectionAddContent from "../feature-backend/collection/add-content";

// filter
import FilterList from "../feature-backend/filter/list";
import FilterAdd from "../feature-backend/filter/create";
import FilterEdit from "../feature-backend/filter/edit";
import FilterOptionAdd from "../feature-backend/filter/createOption";

// page
import PageList from "../feature-backend/page/list";
import PageAdd from "../feature-backend/page/create";
import PageEdit from "../feature-backend/page/edit";

// Setting-template
import SettingTemplate from "../feature-backend/setting/template";
import SettingTemplateSection from "../feature-backend/setting/template/setup";
import SettingTemplateMenu from "../feature-backend/setting/template/menu";
import SettingTemplateHeader from "../feature-backend/setting/template/header";
import SettingTemplateGeneral from "../feature-backend/setting/template/general";

// customer
import CustomerList from "../feature-backend/customer/list";
import CustomerView from "../feature-backend/customer/view";

// order
import OrderList from "../feature-backend/order/list";
import OrderSetFulfill from "../feature-backend/order/set-fulfilled";
import OrderSetCancell from "../feature-backend/order/set-cancelled";

// store
import StoreView from "../feature-backend/store/view";
import StoreEdit from "../feature-backend/store/edit";

// migration
import MigrationView from "../feature-backend/migration/view";

// route
import { ProtectedRouteBackend } from "./protectedRouteBackend";

import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { connect } from "react-redux";

class Index extends Component {
  render() {
    const { store } = this.props;
    let user = loggedInAdmin();

    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Row>
                  <Col md="3">
                    {store.item && store.item.logoSrc && (
                      <div style={{ minWidth: "250px" }}>
                        <img
                          width="80px"
                          src={store.item.fileBaseUrl + store.item.logoSrc}
                        />
                      </div>
                    )}
                  </Col>
                  <Col md="9" style={{ marginTop: "14px" }}>
                    {window.location.hostname === "localhost" && (
                      <>
                        <a
                          className="nav-link"
                          href="http://localhost:3090/"
                          target="_blank"
                        >
                          Online Store
                        </a>
                      </>
                    )}

                    {window.location.hostname !== "localhost" && (
                      <>
                        <a
                          className="nav-link"
                          href={
                            "https://" +
                            /:\/\/([^\/]+)/.exec(window.location.href)[1]
                          }
                          target="_blank"
                        >
                          Online Store
                        </a>
                      </>
                    )}
                  </Col>
                </Row>
              </li>

              {/* <li className='nav-item'>
              <a
                className='nav-link'
                href='http://localhost:3090/home-preview'
                target='_blank'
              >
                Preview
              </a>
            </li> */}

              <li className="nav-item nav-clock">
                <div className="system-calendar-clock-block">
                  <Moment format="D MMM, YYYY" date={new Date()} />
                  <Clock />
                </div>
              </li>

              <li
                className="nav-item"
                style={{
                  position: "absolute",
                  right: "0",
                  marginRight: "245px",
                }}
              >
                <NavDropdown title={user.name} id="nav-dropdown">
                  <NavDropdown.Item eventKey="4.1">
                    <NavLink
                      exact
                      activeClassName=""
                      className="nav-link"
                      to={"/user/profile"}
                    >
                      Profile
                    </NavLink>
                    <NavLink
                      exact
                      activeClassName=""
                      className="nav-link"
                      to="/logout"
                    >
                      Logout
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>
              </li>
            </ul>
          </div>
        </nav>

        <Navbar
          className="mb-20"
          collapseOnSelect
          expand="lg"
          bg="primary"
          variant="dark"
        >
          {/* <Navbar.Brand href='#home'>React-Bootstrap</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {store.item && !store.item.disableOrder && (
                <NavLink
                  exact
                  activeClassName="selected"
                  className="nav-link"
                  to="/order"
                >
                  Orders
                </NavLink>
              )}

              {store.item && !store.item.disableProduct && (
                <NavLink
                  exact
                  activeClassName="selected"
                  className="nav-link"
                  to="/product"
                >
                  Products
                </NavLink>
              )}

              <NavLink
                exact
                activeClassName="selected"
                className="nav-link"
                to="/collection/add"
              >
                Collection
              </NavLink>

              <NavLink
                exact
                activeClassName="selected"
                className="nav-link"
                to="/page"
              >
                Page
              </NavLink>

              {store.item && !store.item.disableCustomer && (
                <NavLink
                  exact
                  activeClassName="selected"
                  className="nav-link"
                  to="/customer"
                >
                  Customer
                </NavLink>
              )}
            </Nav>

            <Nav>
              <NavDropdown
                title="Setting"
                id="collasible-nav-dropdown"
                style={{ marginRight: "200px" }}
              >
                <NavLink
                  exact
                  activeClassName="selected"
                  className="dropdown-item"
                  to="/setting/template"
                >
                  Online Store Setting
                </NavLink>

                <NavDropdown.Divider />

                <NavLink
                  exact
                  activeClassName="selected"
                  className="dropdown-item"
                  to="/store"
                >
                  General Setting
                </NavLink>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/* / */}
        <ProtectedRouteBackend exact path="/" component={ItemList} />

        {/* setting-template */}
        <ProtectedRouteBackend
          exact
          path="/setting/template"
          component={SettingTemplate}
        />

        {/* setting-section */}
        <ProtectedRouteBackend
          exact
          path="/setting/template/section/:id"
          component={SettingTemplateSection}
        />

        {/* general */}
        <ProtectedRouteBackend
          exact
          path="/setting/template/general"
          component={SettingTemplateGeneral}
        />

        {/* header */}
        <ProtectedRouteBackend
          exact
          path="/setting/template/header"
          component={SettingTemplateHeader}
        />

        {/* menu */}
        <ProtectedRouteBackend
          exact
          path="/setting/template/menu"
          component={SettingTemplateMenu}
        />

        {/* user */}
        <ProtectedRouteBackend exact path="/user/profile" component={UserView} />
        <ProtectedRouteBackend exact path="/user/edit-profile" component={UserEdit} />
        <ProtectedRouteBackend
          exact
          path="/user/change-password"
          component={UserChangePassword}
        />
        <ProtectedRouteBackend exact path="/user/add" component={UserAdd} />
        <ProtectedRouteBackend exact path="/user" component={UserList} />

        {/* product */}
        <ProtectedRouteBackend exact path="/product/add" component={ItemAdd} />
        <ProtectedRouteBackend exact path="/product/edit/:id" component={ItemEdit} />
        <ProtectedRouteBackend
          exact
          path="/product/add-image/:id"
          component={ItemAddImage}
        />
        <ProtectedRouteBackend
          exact
          path="/product/add-to-collection/:id"
          component={ItemAddToCollection}
        />
        <ProtectedRouteBackend exact path="/product/view/:id" component={ItemView} />
        <ProtectedRouteBackend exact path="/product" component={ItemList} />
        <ProtectedRouteBackend exact path="/dashboard" component={ItemList} />

        {/* collection */}
        <ProtectedRouteBackend exact path="/collection" component={CollectionAdd} />

        {/* collection */}
        <ProtectedRouteBackend
          exact
          path="/collection/add"
          component={CollectionAdd}
        />

        {/* <ProtectedRouteBackend exact path='/collection' component={CollectionList} /> */}
        <ProtectedRouteBackend
          exact
          path="/collection/edit/:id"
          component={CollectionEdit}
        />

        <ProtectedRouteBackend
          exact
          path="/collection/add-filter/:id"
          component={CollectionAddFilter}
        />

        <ProtectedRouteBackend
          exact
          path="/collection/add-content/:id"
          component={CollectionAddContent}
        />

        {/* filter */}
        <ProtectedRouteBackend exact path="/filter/add" component={FilterAdd} />
        <ProtectedRouteBackend exact path="/filter" component={FilterList} />
        <ProtectedRouteBackend exact path="/filter/edit/:id" component={FilterEdit} />
        <ProtectedRouteBackend
          exact
          path="/filter/option/add/:id"
          component={FilterOptionAdd}
        />

        {/* page */}
        <ProtectedRouteBackend exact path="/page/add" component={PageAdd} />
        <ProtectedRouteBackend exact path="/page" component={PageList} />
        <ProtectedRouteBackend exact path="/page/edit/:id" component={PageEdit} />

        {/* section */}
        <ProtectedRouteBackend
          exact
          path="/section/view/:id"
          component={SectionView}
        />

        {/* customer */}
        <ProtectedRouteBackend exact path="/customer" component={CustomerList} />
        <ProtectedRouteBackend
          exact
          path="/customer/view/:id"
          component={CustomerView}
        />

        {/* store */}
        <ProtectedRouteBackend exact path="/store" component={StoreView} />
        <ProtectedRouteBackend exact path="/store/edit" component={StoreEdit} />

        {/* order */}
        <ProtectedRouteBackend exact path="/order" component={OrderList} />
        <ProtectedRouteBackend
          exact
          path="/order/set-fulfill/:id"
          component={OrderSetFulfill}
        />

        <ProtectedRouteBackend
          exact
          path="/order/set-cancell/:id"
          component={OrderSetCancell}
        />

        {/* migration */}
        <ProtectedRouteBackend
          exact
          path="/superadminmigration"
          component={MigrationView}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  {}
)(Index);
