import React, { Component } from 'react';
import { Carousel, Card, CardGroup, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

class Index extends Component {

  createMarkup = (text) => {
    return { __html: text };
  };

  render() {
    const { data, store } = this.props;
    if (!data || !data.items || !store.item) return <></>;

    let heading = data.heading;
    let headerAlignment = data.headerAlignment;
    headerAlignment = headerAlignment ? headerAlignment : 'left';

    let textAlignment = data.textAlignment;
    textAlignment = textAlignment ? textAlignment : 'center';
    
    let height = data.height;
    height = height ? height + 'px' : '100px';

    let showMoreLabel = data.showMoreLabel;
    let showMoreLink = data.showMoreLink;

    let items = data.items;
    let imageCount = items.length;

    let wd = 1;
    if (imageCount == 1) wd = 12;
    else if (imageCount == 2) wd = 6;
    else if (imageCount == 3) wd = 4;
    else if (imageCount == 4) wd = 3;
    else if (imageCount == 5) wd = 2;
    else if (imageCount > 5) wd = 1;

    return (
      <>
        <div style={{ maxWidth: '90rem', margin: '0px auto' }}>
          <h1 className={'text-' + headerAlignment}>{heading}</h1>
        </div>

        <div style={{ maxWidth: '90rem', margin: '0px auto' }}>
          {items.length > 0 && (
            <Row>
              {items.map((item, index) => {
                return (
                  <Col className={'md-' + wd}>
                    {item.externalLink && (
                      <>
                        <a href={item.externalLink} target='_blank'>
                          <div className='image-block'>
                            <img
                              src={store.item.fileBaseUrl + item.imageSrc}
                              style={{
                                maxWidth: '100%',
                                width: '100%',
                                height: height,
                              }}
                              className='image-border-8'
                            />
                          </div>
                        </a>
                      </>
                    )}

                    {!item.externalLink && (
                      <>
                        <Link to={item.link}>
                          <div className='image-block'>
                            <img
                              src={store.item.fileBaseUrl + item.imageSrc}
                              style={{
                                maxWidth: '100%',
                                width: '100%',
                                height: height,
                              }}
                              className='image-border-8'
                            />
                          </div>
                        </Link>

                        <div
                          dangerouslySetInnerHTML={this.createMarkup(
                            item.text
                          )}
                          className={'text-' + textAlignment + ' editor pl-5'}
                        ></div>
                        
                      </>
                    )}
                  </Col>
                );
              })}

              {/* show more link */}
              {showMoreLabel && showMoreLink && (
                <>
                  <Col md='12' className='text-right'>
                    <Link
                      to={showMoreLink}
                      className='btn btn-dark btn-small view-more-btn'
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      {showMoreLabel}
                    </Link>
                  </Col>
                </>
              )}
            </Row>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
