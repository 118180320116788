
import React from 'react';
import {Route, Redirect} from 'react-router-dom';

export const ProtectedRouteFrontend = ({component: Component, ...rest}) => {
  const currentCustomer = localStorage.getItem('currentCustomer');
  return <Route {...rest} render={(props)=>{
      return currentCustomer ? <Component {...props} /> : <Redirect to="/login" />
  }} />

}