import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getCollection } from '../../../redux/backend/action/collection';
import FormEdit from './form';

class Index extends Component {
  componentDidMount() {
    this.props.getCollection(this.props.match.params.id);
  }

  render() {
    return (
      <>
        <h4>
          Collection <small>Content</small>
        </h4>

        <div className='plane-border-container'>
          <FormEdit />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collection: state.collectionReducer.collection,
});
export default connect(
  mapStateToProps,
  { getCollection }
)(Index);
