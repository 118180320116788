import React, { Component } from 'react';
import { Carousel, Card, CardGroup, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getAllSimilarItem } from '../../redux/frontend/action/item';
import { postAddWatchlist } from '../../redux/frontend/action/watchlist';
import { postAddCart } from '../../redux/frontend/action/cart';
import ProductThumb from '../components/productThumb';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class Index extends Component {
  componentDidMount() {
    this.props.getAllSimilarItem(this.props.itemId);
  }

  handleAddToCart = (e, id) => {
    e.preventDefault();
    this.props.postAddCart(id);
    console.log('cart here ', id);
  };

  handleAddToWishlist = (e, id) => {
    e.preventDefault();
    this.props.postAddWatchlist(id);
    console.log('wishlist here', id);
  };

  render() {
    const { itemListing, store } = this.props;
    if (!store.item) return <></>;

    if (!itemListing.items) return <></>;

    let imageHeight = '200px';

    return (
      <>
        {itemListing.items.length > 0 && (
          <>
            <div
              className='mt-35'
              style={{ maxWidth: '75rem', margin: '0px auto' }}
            >
              <h1 className={'text-left'}>You may also like</h1>
            </div>

            <div style={{ maxWidth: '75rem', margin: '0px auto' }}>
              <Row>
                {itemListing.items.map((item, index) => {
                  return (
                    <Col md='3' sm='3' xs='6' style={{ marginBottom: '20px' }}>
                      <ProductThumb
                        product={item}
                        handleAddToCart={this.handleAddToCart}
                        handleAddToWishlist={this.handleAddToWishlist}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
  itemListing: state.itemReducer.itemListing,
});
export default connect(
  mapStateToProps,
  { getAllSimilarItem, postAddCart, postAddWatchlist }
)(withRouter(Index));
