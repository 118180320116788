import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col, Form } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import Header from '../header';
import Login from './login';
import Register from './register';

class Index extends Component {
  render() {
    const currentCustomer = localStorage.getItem('currentCustomer');

    //  700 x 5 = 3500
    //  2van x 5
    //  400 x 5 = 2000

    return (
      <>
        <Header />

        {/* if loggedin, redirect to my-order */}
        {currentCustomer && <Redirect to='/order' />}

        <div className='main-container' style={{ width: '100%' }}>
          <div style={{ maxWidth: '75rem', margin: '0px auto' }}>
            <Row>
              <Col md='5'>
                <Login />
              </Col>
              <Col md='7'>
                <Register />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
