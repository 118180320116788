// https://appdividend.com/2018/11/11/react-crud-example-mern-stack-tutorial/

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  NavLink,
} from 'react-router-dom';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from 'react-bootstrap';
import logo from './logo.svg';
import './App.css';

import frontendStore from './redux/frontend/store';
import backendStore from './redux/backend/store';
import { Provider } from 'react-redux';
import Login from './feature-backend/site/login/index';
import Logout from './feature-backend/site/logout/index';

import NavStoreBackend from './config/navStoreBackend';
import NavStoreFrontend from './config/navStoreFrontend';

import setDbHeader from './utils/setDbHeader';

/* importing style */
import './assets/css/styles.css';
import './assets/css/template.css';
import './assets/css/frontend/margin-padding.css';


import { ProtectedRouteBackend } from './config/protectedRouteBackend';
import { ToastContainer } from 'react-toastify';
import StoreDetail from './feature-backend/storeDetail';

const App = () => {
  /* set domain to connect db in api */
  var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
  setDbHeader(domain);

  return (
    <>
      <Provider store={backendStore}>
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <ToastContainer
            autoClose={5000}
            position='bottom-left'
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnHover
            draggable
          />

          <StoreDetail />

          {/* <Router basename={'/admin'}> */}
          {/* <Router basename={'/eztoz/prod/admin'}> */}
          <Router basename={process.env.PUBLIC_URL + '/admin'}>
            <Route exact path='/login' component={Login} />
            <ProtectedRouteBackend exact path='/logout' component={Logout} />
            <ProtectedRouteBackend path='/' component={NavStoreBackend} />
          </Router>
        </div>
      </Provider>
    </>
  );
};

export default App;
