import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';

import { connect } from 'react-redux';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import TableItem from './tableItems';

class ListTable extends Component {
  state = {
    order: '',
  };

  render() {
    const { orderRow } = this.props;

    if (!orderRow.item) {
      return <div>Loading..</div>;
    }

    const order = orderRow.item;

    return (
      <>
        <Accordion
          defaultActiveKey='0'
          style={{ borderBottom: '1px solid #dedede' }}
        >
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={1}>
              <Row>
                <Col md='4'>
                  Date: {order.date}
                  <div>Item count ({order.itemCount})</div>
                  <div>
                    <b>Total: {order.totalLabel}</b>
                  </div>
                </Col>

                <Col md='4'>
                  {order.customer && (
                    <>
                      Customer: <b>{order.customer.name}</b>
                      <div>{order.customer.address}</div>
                      <div>{order.customer.email}</div>
                      <div>{order.customer.mobile}</div>
                      <div>{order.customer.phone}</div>
                    </>
                  )}
                </Col>

                <Col md='2'>
                  <b>{order.statusLabel}</b>

                  {/* remark */}
                  {order.remark && <div>Remark: {order.remark}</div>}
                </Col>

                <Col md='2' className='text-right'>
                  <Row>
                    <Col md='9' className='pl-0'>
                      <Link className='btn btn-outline-success btn-sm'>
                        Detail
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <TableItem orderItems={order.orderItems} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orderRow: state.orderReducer.order,
});
export default connect(
  mapStateToProps,
  {}
)(ListTable);
