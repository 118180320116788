import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  getAllCollectionDropdown,
  postEditCollection,
  postAddCollectionFilter,
  deleteCollectionFilter,
} from '../../../redux/backend/action/collection';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FormEdit extends Component {
  state = {
    mainCollection: [],
    name: '',
    description: '',
  };

  componentDidMount() {
    this.props.getAllCollectionDropdown();
  }

  componentWillReceiveProps(recievedProps) {
    const collection = recievedProps.collection.item;
    if (collection) {
      this.setState({
        id: collection.id,
        name: collection.name,
        description: collection.description,
        mainCollection: collection.mainCollectionData,
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    }
  };

  handleChangeDropdown = (e) => {
    this.setState({
      mainCollection: e,
    });

    console.log(e);
  };

  handleChangeDropdownFilter = (e) => {
    console.log(e);
    this.props.postAddCollectionFilter(this.props.match.params.id, e);
  };
  handleChangeEditorDescription = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data });

    this.setState({ description: data });
  };
  handleDeleteCollectionFilter = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete this item?')) {
      this.props.deleteCollectionFilter(id);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postEditCollection(
      this.props.match.params.id,
      this.state,
      this.props.history
    );
  };

  render() {
    const {
      collection,
      collectionDropdownListing,
      filterListing,
      collectionFilterListing,
    } = this.props;

    var filterData = [{ label: '-', value: '' }];
    if (filterListing.items) {
      filterListing.items.map((filter, filterIndex) => {
        var filterItem = { label: filter.name, value: filter.id };

        filterData.push(filterItem);
      });
    }
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md='12'>
              <h4>Edit Collection</h4>
              <div className='form-container'>
                <Row>
                  <Col md='6'>
                    <div className='form-group'>
                      <label>Main Collection: </label>
                      <Select
                        name={'parentId'}
                        value={{
                          label: this.state.mainCollection.label,
                          value: this.state.mainCollection.value,
                        }}
                        options={collectionDropdownListing.dropdownOptions}
                        // options={[
                        //   { label: 'bbb', value: '11' },
                        //   { label: 'aaa', value: '22' },
                        // ]}
                        onChange={(e) => this.handleChangeDropdown(e)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md='6'>
                    <div className='form-group'>
                      <label>Name: </label>
                      <input
                        type='text'
                        name='name'
                        className='form-control form-control-sm'
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md='8'>
                    <div className='form-group'>
                      <label>Content: </label>

                      <CKEditor
                        editor={ClassicEditor}
                        data={this.state.description}
                        onInit={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);
                        }}
                        onChange={this.handleChangeEditorDescription}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className='mt-15'>
            <Col md='12'>
              <h4>Add Filters</h4>
              <div className='form-container'>
                <Row>
                  <Col md='6'>
                    <div className='form-group'>
                      <label>Select Filter: </label>

                      <Select
                        name={'parentId'}
                        options={filterData}
                        onChange={(e) => this.handleChangeDropdownFilter(e)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md='6'>
                    <div className='form-group' style={{ width: '300px' }}>
                      <label>
                        <b>Added Filters: </b>
                      </label>

                      {collectionFilterListing.items && (
                        <>
                          {collectionFilterListing.items.map(
                            (collectionFilter, addedFilterIndex) => {
                              return (
                                <>
                                  <div className='mb-10'>
                                    <b>{collectionFilter.name}</b>

                                    <span className='pull-right'>
                                      <Link
                                        onClick={(e) =>
                                          this.handleDeleteCollectionFilter(
                                            e,
                                            collectionFilter.id
                                          )
                                        }
                                      >
                                        Delete
                                      </Link>
                                    </span>

                                    {/*  */}
                                    {collectionFilter.filterOptions.map(
                                      (option, optionIndex) => {
                                        return (
                                          <>
                                            <div className='ml-20'>
                                              {option.name}
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                    {/*  */}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collection: state.collectionReducer.collection,
  collectionDropdownListing: state.collectionReducer.collectionDropdownListing,
  filterListing: state.filterReducer.filterListing,
  collectionFilterListing: state.collectionReducer.collectionFilterListing,
});

export default connect(
  mapStateToProps,
  {
    getAllCollectionDropdown,
    postEditCollection,
    postAddCollectionFilter,
    deleteCollectionFilter,
  }
)(withRouter(FormEdit));
