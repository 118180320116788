import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getStore } from '../../../redux/backend/action/store';
import FormEdit from './form';

class Index extends Component {
  componentDidMount() {
    this.props.getStore(this.props.match.params.id);
  }

  render() {
    return (
      <>
        <h4>
          Store <small>Edit</small>
        </h4>

        <div className='plane-border-container'>
          <FormEdit />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  { getStore }
)(Index);
