import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import {
  SET_LOADING,
  SECTION_TEMPLATE_GET_ALL,
  SECTION_TEMPLATE_GET,
} from './types';

const url = apiBaseUrl + '/backend/sectiontemplate';

export const postSectionTemplate = (id, data) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        ////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postSectionTemplate?id=${id}`,
      data,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({
        type: SECTION_TEMPLATE_GET,
        payload: response.data.sectionTemplateListingData.section,
      });

      dispatch({
        type: SECTION_TEMPLATE_GET_ALL,
        payload: response.data.sectionTemplateListingData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const removeSectionTemplate = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        ////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/removeSectionTemplate?id=${id}`,
      { id, 'x-authorization': `${localStorage.getItem('jwt')}` },
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch({
      //   type: SECTION_TEMPLATE_GET_ALL,
      //   payload: response.data,
      // });

      // dispatch
      dispatch({
        type: SECTION_TEMPLATE_GET,
        payload: response.data.sectionTemplateListingData.section,
      });

      // dispatch
      dispatch({
        type: SECTION_TEMPLATE_GET_ALL,
        payload: response.data.sectionTemplateListingData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const addMoreTemplate = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        ////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/addMoreTemplate?id=${id}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({
        type: SECTION_TEMPLATE_GET_ALL,
        payload: response.data,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const getAllSectionTemplate = (id) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(
      `${url}/getAllSectionTemplate?id=${id}`,
      config
    );

    // dispatch
    dispatch({
      type: SECTION_TEMPLATE_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getSectionTemplate = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        ////Authorization: `Bearer ${localStorage.getSection('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.get(
      `${url}/getSectionTemplate?id=${id}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: SECTION_TEMPLATE_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
