import React, { Component } from "react";
import "react-table/react-table.css";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getMenu } from "../../redux/backend/action/menu";

import { Nav, Navbar, Brand } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import MenuCollection from "./collection";
import Radium, { Style } from "radium";

class Menu extends Component {
  state = {
    page: "",
  };
  componentDidMount() {
    this.props.getMenu();
  }

  render() {
    const { templateMenuListing, store } = this.props;

    if (!store.item) {
      return <div>Loading..</div>;
    }

    if (!templateMenuListing.menu) {
      return <div>No data found</div>;
    }

    const mainMenuList = templateMenuListing.menu;

    let menuBackgroundColor = store.item.menuBackgroundColor;
    menuBackgroundColor = menuBackgroundColor ? menuBackgroundColor : "#2a3a3d";

    let menuTextColor = store.item.menuTextColor;
    menuTextColor = menuTextColor ? menuTextColor : "#FFFFFF";

    let menuTextAlignmentCss =
      store.item.menuTextAlignment === "center" ? "0 auto !important" : "";

    const getParentCollectionMenu = (menu, indexMenu) => {
      let collectionWithChild = true;
      const collectionSlug = "menu" + indexMenu + "_child0_slug";
      const collectionId = "menu" + indexMenu + "_child0_id";

      if (menu.collectionId > 0) {
        const keyChild1 = "menu" + indexMenu + "_child1";
        const Child1List = menu.collectionId && templateMenuListing[keyChild1];
        if (!Child1List) {
          collectionWithChild = false;
        }
      }

      return {
        collectionWithChild,
        collectionSlug,
        collectionId,
      };
    };

    return (
      <>
        <div style={{ textAlign: "center" }}>
          <Style
            scopeSelector=".nav-menu"
            rules={{
              background: menuBackgroundColor,
              color: "red",
              a: {
                color: menuTextColor + " !important",
              },
              "a.dropdown-item": {
                color: "#666 !important",
              },
              "a:visited": {
                color: "#999",
              },
              ".nav-alignment": {
                margin: menuTextAlignmentCss,
              },
            }}
          />

          <Navbar collapseOnSelect expand="lg" className="nav-menu">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="nav-alignment">
                {mainMenuList.map((menu, indexMenu) => {
                  // check collection has no child, if so then menu will have no dropdown
                  const parentCollectionMenu = getParentCollectionMenu(
                    menu,
                    indexMenu
                  );

                  return (
                    <>
                      {/* Internal Link */}
                      {menu.internalLink !== "" && (
                        <>
                          <NavLink
                            exact
                            activeClassName="selected"
                            className="nav-link main-menu-nav-pill"
                            to="#"
                          >
                            {menu.name}
                          </NavLink>
                        </>
                      )}

                      {/* External Link */}
                      {menu.externalLink !== "" && (
                        <>
                          {/* <a href='#' className='nav-link main-menu-nav-pill'>{menu.name}</a> */}
                          <NavLink
                            exact
                            activeClassName="selected"
                            className="nav-link main-menu-nav-pill"
                            to="#"
                          >
                            {menu.name}
                          </NavLink>
                        </>
                      )}

                      {/* PAGE */}
                      {menu.pageId > 0 && (
                        <>
                          <NavLink
                            exact
                            activeClassName="selected"
                            className="nav-link main-menu-nav-pill"
                            to="#"
                          >
                            {templateMenuListing["page"][indexMenu]["name"]}
                          </NavLink>
                        </>
                      )}

                      {/* PRODUCT */}
                      {menu.itemId > 0 && (
                        <>
                          <NavLink
                            exact
                            activeClassName="selected"
                            className="nav-link main-menu-nav-pill"
                            to="#"
                          >
                            {templateMenuListing["product"][indexMenu]["name"]}
                          </NavLink>
                        </>
                      )}

                      {/* COLLECTION with child */}
                      {menu.collectionId > 0 &&
                        parentCollectionMenu.collectionWithChild && (
                          <NavDropdownMenu
                            title={menu.name}
                            className="main-menu-nav-pill"
                            id="collapsible-nav-dropdown"
                          >
                            <MenuCollection indexMenu={indexMenu} />
                          </NavDropdownMenu>
                        )}

                      {/* COLLECTION with no child */}
                      {menu.collectionId > 0 &&
                        !parentCollectionMenu.collectionWithChild > 0 && (
                          <NavLink
                            exact
                            activeClassName="selected"
                            className="nav-link main-menu-nav-pill"
                            to="#"
                          >
                            {menu.name}
                          </NavLink>
                        )}
                    </>
                  );
                })}
              </Nav>
              {/*  */}
            </Navbar.Collapse>
          </Navbar>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
  templateMenuListing: state.menuReducer.templateMenuListing,
});
export default connect(
  mapStateToProps,
  { getMenu }
)(Menu);
