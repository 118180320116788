import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postAddItem } from '../../../redux/backend/action/item';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import FormBasic from './formBasic';
import FormPricing from './formPricing';

class FormAdd extends Component {
  state = {
    formData: [],
  };

  componentDidMount() {}

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    } else if (name === 'quantity') {
      this.setState({ quantity: value });
    } else if (name === 'sellPrice') {
      this.setState({ sellPrice: value });
    } else if (name === 'compareSellPrice') {
      this.setState({ compareSellPrice: value });
    } else if (name === 'costPrice') {
      this.setState({ costPrice: value });
    }
  };

  handleChangeEditorDescription = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data });

    this.setState({ description: data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postAddItem(this.state, this.props.history);
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md='6'>
              <h4>Add Product</h4>
              <div className='form-container'>
                <FormBasic
                  state={this.state}
                  handleChange={this.handleChange}
                  handleChangeEditorDescription={
                    this.handleChangeEditorDescription
                  }
                />
              </div>
            </Col>

            <Col md='6'>
              <h4 style={{ marginTop: '20px' }}>Pricing</h4>
              <div className='form-container'>
                <FormPricing
                  state={this.state}
                  handleChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  { postAddItem }
)(withRouter(FormAdd));
