import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
// import { getAllItem } from '../../../redux/backend/action/item';

import { Link } from 'react-router-dom';

import SectionList from './sectionList';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {
    // this.props.getAllItem();
  }

  render() {
    // const { itemListing } = this.props;

    // if (!itemListing) {
    //   return <div>Loading..</div>;
    // }

    return (
      <div>
        <h4>
          Setting <small>Store template</small>
        </h4>

        <Row>
          <Col className='col-md-3'>
            <SectionList activeId='' />
          </Col>
          <Col className='col-md-9'>
            <div className='plane-border-container'>
              <h3>Select "Section" from sidebar.</h3>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //   itemListing: state.itemReducer.itemListing,
});
export default connect(
  mapStateToProps,
  {}
)(Index);
