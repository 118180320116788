import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  getCollection,
  getAllCollectionFilter,
} from '../../../redux/backend/action/collection';
import { getAllFilter } from '../../../redux/backend/action/filter';

import { Row, Col } from 'react-bootstrap';
import AddFilterForm from './form';
import TreeCollection from './treeCollection';

import TopNav from '../top-nav';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {
    if (this.props.match.params.id !== 'id=') {
      this.props.getCollection(this.state.id);
      this.props.getAllCollectionFilter(this.state.id);
    }
    this.props.getAllFilter();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /* first time load, set sate treeData */
    if (
      this.state.id !== this.props.match.params.id &&
      this.props.match.params.id !== 'id='
    ) {
      this.props.getCollection(this.props.match.params.id);
      this.props.getAllCollectionFilter(this.props.match.params.id);

      this.setState({
        id: this.props.match.params.id,
      });
    }
  }

  render() {
    const { collection } = this.props;

    if (!collection.item)
      return (
        <>
          <TopNav activeLabel='collection-assign-filter' />

          <Row>
            <Col md='6'>
              <h4>Collections</h4>
              <div className='form-container'>
                <TreeCollection />
              </div>
            </Col>
            <Col md='6'>
              <h4>Add Filter</h4>
              <div className='form-container bold'>
                Please choose Collection
              </div>
            </Col>
          </Row>
        </>
      );

    return (
      <>
        <TopNav activeLabel='collection-assign-filter' />

        <Row>
          <Col md='6'>
            <h4>Collections</h4>
            <div className='form-container'>
              <TreeCollection collectionId={this.state.id} />
            </div>
          </Col>
          <Col md='6'>
            <h4>
              Add Filters{' '}
              <small>
                {' '}
                >> {collection.item && <>{collection.item.name}</>}
              </small>
            </h4>
            <div className='form-container'>
              <AddFilterForm />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filterListing: state.filterReducer.filterListing,
  collection: state.collectionReducer.collection,
  collectionFilterListing: state.collectionReducer.collectionFilterListing,
});
export default connect(
  mapStateToProps,
  { getCollection, getAllFilter, getAllCollectionFilter }
)(Index);
