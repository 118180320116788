import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getItem } from '../../../redux/backend/action/item';

import { Card, Button, Row, Col } from 'react-bootstrap';

import ImageUploader from './imageUploader';
import ImageList from './list';

import ManageNav from '../nav';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {
    this.props.getItem(this.state.id);
  }

  render() {
    const { productRow } = this.props;
    if (!productRow.item) return <>Loading..</>;

    const product = productRow.item;

    return (
      <>
      <h4>Upload Image: <small>{product.name}  (#{product.code})</small></h4>

        <div class='row'>
          <div class='col-2'>
            <ManageNav itemId={this.state.id} activeLabel='upload-image' />
          </div>

          <div class='col-10'>
           
            <div class='tab-content' id='v-pills-tabContent'>
              <Row>
                <Col md='6'>
                  <div className='form-container'>
                    <ImageUploader itemId={this.state.id} />
                  </div>
                </Col>
                <Col md='6'>
                  <ImageList itemId={this.state.id} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  { getItem }
)(Index);
