import axios from 'axios';
import { apiBaseUrl } from '../../../constant/api';

import { push } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

import { browserHistory } from 'react-router';
import { REGISTER_SUBMIT, SUBMITTING_STARTED, SUBMITTING_DONE } from './types';

const url = apiBaseUrl + '/frontend/account';

export const submitRegister = (data, history) => {
  return (dispatch, getState) => {
    /* submitting started */
    dispatch({ type: SUBMITTING_STARTED, payload: true });

    axios
      .post(
        `${url}/register`,
        {
          data: data,
          baseUrl: window.location.pathname.replace('/register', ''),
        },

        /* NOTE: for cors put urlencoded NOT json */
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .then((response) => {
        /* submitting done */
        dispatch({ type: SUBMITTING_DONE, payload: true });

        if (response.data.result === 'SUCCESS') {
          toast.success(response.data.message);

          history.push(
            '/registration-verification/' + response.data.encodedData
          );

          dispatch({ type: REGISTER_SUBMIT, payload: { payload: response } });
        } else if (response.data.result === 'ERROR') {
          toast.error(response.data.message);
        }
      })
      .then(() => {
        // history.push(`/index`)
      })
      .catch((e) => console.log(e));
  };
};

export const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('currentCustomer');
  localStorage.removeItem('token');
  // currentUserSubject.next(null);
};
