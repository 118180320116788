import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Accordion, Card, Button } from 'react-bootstrap';
// import Detail from './detail';
import {} from '../../../redux/backend/action/section';

import Select from 'react-select';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Index extends Component {
  render() {
    const { item, index } = this.props;

    return (
      <>
        <h4>Sectionloop #</h4>

        <div className='form-container'>
          {/* loop-text */}
          <div>
            {item.map((itemChild, indexChild) => {
              return (
                <>
                  {/* input-text */}

                  <div>
                    {itemChild.inputType === 'text' && (
                      <>
                        {itemChild.name}
                        <input
                          type='text'
                          name={'input' + itemChild.id}
                          key={indexChild}
                          className='form-control form-control-sm'
                          defaultValue={itemChild.content}
                          onChange={(e) =>
                            this.handleChange(e, {
                              key: indexChild,
                              name: 'input' + itemChild.id,
                            })
                          }
                        />
                      </>
                    )}
                  </div>

                  {/* input-text */}
                  <div>
                    {itemChild.inputType === 'textArea' && (
                      <>
                        {itemChild.name}
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.content}
                          onInit={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={this.props.handleChangeEditorDescription}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />
                      </>
                    )}
                  </div>
                </>
              );
            })}
          </div>

          {/* input-dropdown */}
          <div>
            {/* {item.json.formInput &&
                    item.json.formInput.type === 'dropdown' && (
                      <>
                        {item.json.formInput.label}
                        <Select
                          name={item.json.formInput.name}
                          // defaultValue={{
                          //   label: item.jsonformInput.value,
                          //   value: item.formInput.value,
                          // }}
                          options={[{ value: 'aa', label: 'ddd' }]}
                          onChange={(e) =>
                            this.handleChangeDropdown(e, {
                              key: index,
                              name: item.json.formInput.name,
                            })
                          }
                        />
                      </>
                    )} */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(Index);
