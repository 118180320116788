// export constants

export const SET_LOADING = 'SET_LOADING';
export const SET_PREVIEW_GENERATE_WAITING = 'SET_PREVIEW_GENERATE_WAITING';

export const SUBMITTING_STARTED = 'SUBMITTING_STARTED';
export const SUBMITTING_DONE = 'SUBMITTING_DONE';

export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';

// item
export const ITEM_GET_ALL = 'ITEM_GET_ALL';
export const ITEM_GET = 'ITEM_GET';
export const ITEM_COLLECTION_GET_ALL = 'ITEM_COLLECTION_GET_ALL';

// itemimage
export const ITEMIMAGE_GET_ALL = 'ITEMIMAGE_GET_ALL';
export const ITEMIMAGE_GET = 'ITEMIMAGE_GET';

// template menu
export const TEMPLATE_MENU_GET_ALL = 'TEMPLATE_MENU_GET_ALL';

// section
export const SECTION_GET = 'SECTION_GET';
export const SECTION_GET_ALL = 'SECTION_GET_ALL';
export const DEFAULT_SECTION_GET_ALL = 'DEFAULT_SECTION_GET_ALL';

// section template
export const SECTION_TEMPLATE_GET = 'SECTION_TEMPLATE_GET';
export const SECTION_TEMPLATE_GET_ALL = 'SECTION_TEMPLATE_GET_ALL';

// collection
export const COLLECTION_GET_ALL = 'COLLECTION_GET_ALL';
export const COLLECTION_GET = 'COLLECTION_GET';
export const COLLECTION_FILTER_GET_ALL = 'COLLECTION_FILTER_GET_ALL';
export const COLLECTION_TREE_GET_ALL = 'COLLECTION_TREE_GET_ALL';
export const COLLECTION_DROPDOWN_GET_ALL = 'COLLECTION_DROPDOWN_GET_ALL';

// filter
export const FILTER_GET_ALL = 'FILTER_GET_ALL';
export const FILTER_GET = 'FILTER_GET';

// page
export const PAGE_GET_ALL = 'PAGE_GET_ALL';
export const PAGE_GET = 'PAGE_GET';

// menu
export const MENU_GET_ALL = 'MENU_GET_ALL';
export const MENU_GET = 'MENU_GET';

// customer
export const CUSTOMER_GET_ALL = 'CUSTOMER_GET_ALL';
export const CUSTOMER_GET = 'CUSTOMER_GET';

// order
export const ORDER_GET_ALL = 'ORDER_GET_ALL';
export const ORDER_GET = 'ORDER_GET';

// store
export const STORE_GET = 'STORE_GET';

// migration
export const MIGRATION_GET = 'MIGRATION_GET';

// OLD
// OLD
// OLD
// OLD
// OLD
// OLD
// OLD

// stock
export const STOCK_GET_ALL = 'STOCK_GET_ALL';
export const STOCK_GET = 'STOCK_GET';

// supplier
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const GET_PURCHASE_BILLS = 'GET_PURCHASE_BILLS';
export const GET_PAYMENT = 'GET_PAYMENT';
export const GET_ALL_SUPPLIER_PAYMENTS = 'GET_ALL_SUPPLIER_PAYMENTS';
export const GET_LEDGER = 'GET_LEDGER';

// purchase
export const PURCHASE_GET_DRAFT_BILL = 'PURCHASE_GET_DRAFT_BILL';
export const PURCHASE_GET_PRINT = 'PURCHASE_GET_PRINT';
export const PURCHASE_GET_ALL = 'PURCHASE_GET_ALL';
export const PURCHASE_GET_BILL = 'PURCHASE_GET_BILL';

// sales
export const SALES_GET_DRAFT_BILL = 'SALES_GET_DRAFT_BILL';
export const SALES_GET_PRINT = 'SALES_GET_PRINT';
export const SALES_GET_ALL = 'SALES_GET_ALL';
export const SALES_GET_BILL = 'SALES_GET_BILL';
export const SALES_GET_GRAPH = 'SALES_GET_GRAPH';
export const SALES_PENDING_PAYMENT_GET_GRAPH =
  'SALES_PENDING_PAYMENT_GET_GRAPH';
export const SALES_FUTURE_PAYMENT_GET_GRAPH = 'SALES_FUTURE_PAYMENT_GET_GRAPH';

// batch
export const BATCH_GET_ALL = 'BATCH_GET_ALL';
export const BATCH_GET = 'BATCH_GET';

// vehicle
export const VEHICLE_GET_ALL = 'VEHICLE_GET_ALL';
export const VEHICLE_GET = 'VEHICLE_GET';

// dashbaord
export const GET_SALES_GRAPH = 'GET_SALES_GRAPH';
export const GET_SUMMARY_COUNT = 'GET_SUMMARY_COUNT';

// vehicle batch
export const VEHICLE_BATCH_GET_ALL = 'VEHICLE_BATCH_GET_ALL';
export const VEHICLE_BATCH_GET = 'VEHICLE_BATCH_GET';

// user/staff
export const USER_GET_ALL = 'USER_GET_ALL';
export const USER_GET = 'USER_GET';

// sales item
export const SALESITEM_GET_ALL = 'SALESITEM_GET_ALL';
export const SALESITEM_PENDING_PAYMENT_GET_ALL =
  'SALESITEM_PENDING_PAYMENT_GET_ALL';
export const SALESITEM_FUTURE_PAYMENT_GET_ALL =
  'SALESITEM_FUTURE_PAYMENT_GET_ALL';
export const SALESITEM_GET = 'SALESITEM_GET';
export const SALESITEM_PENDING_PAYMENT_GET_COUNT =
  'SALESITEM_PENDING_PAYMENT_GET_COUNT';
export const SALESITEM_FUTURE_PAYMENT_GET_COUNT =
  'SALESITEM_FUTURE_PAYMENT_GET_COUNT';

export const RECEIPT_GET_ALL = 'RECEIPT_GET_ALL';
export const RECEIPT_GET = 'RECEIPT_GET';
