import React, { Component, useState } from 'react';
import { Carousel, Image } from 'react-bootstrap';

import ImageGallery from 'react-image-gallery';
import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Index extends Component {
  render() {
    const { productRow } = this.props;
    if (!productRow.item) return <>Loading..</>;

    return (
      <>
        <ImageGallery items={productRow.item.itemImages} 
          thumbnailPosition = 'left'
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
