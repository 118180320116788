import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postAddUser } from '../../../redux/backend/action/user';
import { getAllVehicle } from '../../../redux/backend/action/vehicle';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

class FormAdd extends Component {
  state = {
    formData: [],
  };

  componentDidMount() {
    this.props.getAllVehicle();
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    } else if (name === 'address') {
      this.setState({ address: value });
    } else if (name === 'phone') {
      this.setState({ phone: value });
    } else if (name === 'mobile') {
      this.setState({ mobile: value });
    } else if (name === 'email') {
      this.setState({ email: value });
    }
  };

  handleChangeVehicle = (selectedOptionMode) => {
    this.setState({ vehicleId: selectedOptionMode.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postAddUser(this.state, this.props.history);
  };

  render() {
    let vehicleOptions = [];

    if (this.props.vehicleListing && this.props.vehicleListing.items) {
      vehicleOptions = this.props.vehicleListing.items.map(function(item, i) {
        return { value: item.id, label: item.name };
      });
    }

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>Name: </label>
                <input
                  type='text'
                  name='name'
                  className='form-control'
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>
            </Col>

            {/* <Col md='6'>
              <div className='form-group'>
                <label>Vehicle: </label>

                <Select
                  name='vehicleId'
                  options={vehicleOptions}
                  onChange={this.handleChangeVehicle}
                />
              </div>
            </Col> */}
          </Row>

          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>Address: </label>
                <input
                  type='text'
                  name='address'
                  className='form-control'
                  value={this.state.address}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>Phone: </label>
                <input
                  type='text'
                  name='phone'
                  className='form-control'
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='8'>
              <div className='form-group'>
                <label>Mobile: </label>
                <input
                  type='text'
                  name='mobile'
                  className='form-control'
                  value={this.state.mobile}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  vehicleListing: state.vehicleReducer.vehicleListing,
});

export default connect(
  mapStateToProps,
  { postAddUser, getAllVehicle }
)(withRouter(FormAdd));
