import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Accordion, Card, Button } from 'react-bootstrap';
// import Detail from './detail';
import {
  getSection,
  postSectionTemplate,
  getAllHomeSection,
  postGenerateJSPreview,
  postGenerateJSPublish,
  postDeleteSection,
  postEditSectionName,
  postDuplicateSection,
} from '../../../../redux/backend/action/section';

import {
  getAllSectionTemplate,
  addMoreTemplate,
  removeSectionTemplate,
} from '../../../../redux/backend/action/sectionTemplate';

import Select from 'react-select';
import { Link, withRouter } from 'react-router-dom';


import InputItem from './inputItem';

import Slideshow from './section/slideshow';
import ImageSlider from './section/imageSlider';
import LineBreaker from './section/lineBreaker';
import RowImages from './section/rowImages';
import ProductList from './section/productList';
import ImageWithText from './section/imageWithText';
import Testimonial from './section/testimonial';
// import PreviewSection from './preview';
// import Footer from './section/footer';

class Index extends Component {
  state = {
    sectionName: '',
  };
  componentDidMount() {
    this.props.getAllHomeSection();
    this.props.getAllSectionTemplate(this.props.sectionId);

    console.log('this.props.location', window.location.href);
  }

  handlePreview = () => {
    this.props.postGenerateJSPreview(this.props.sectionId);

    if (this.props.isJsPreviewGenerateWaiting == false) {
      /* window.location.href =
        'http://localhost:3090/home-preview?id=' + this.props.sectionId; */
    }
  };

  handlePublish = () => {
    this.props.postGenerateJSPublish(this.props.sectionId);
  };

  handleDuplicate = () => {
    if (window.confirm('Are you sure you wish to DUPLICATE this section?')) {
      this.props.postDuplicateSection(this.props.sectionId, this.props.history);
    }
  };

  handleDelete = () => {
    if (window.confirm('Are you sure you wish to DELETE this section?')) {
      this.props.postDeleteSection(this.props.sectionId, this.props.history);
    }
  };

  removeSectionHandle = (e, id) => {
    e.preventDefault();
    console.log('id', id);

    this.props.removeSectionTemplate(id);
  };

  addMoreHandle = (e, id) => {
    e.preventDefault();
    console.log('id', id);

    this.props.addMoreTemplate(id);
  };

  handleChange = (e, data) => {
    var name = e.target.name;
    var value = e.target.value;

    console.log('update-text', name, value);
    this.props.postSectionTemplate(data);
  };

  handleChangeDropdown = (selectedOption, data) => {
    var name = data.name;
    var value = selectedOption.value;
    console.log('eeedrrr', data);
    console.log('update-dropdown', name, value);
  };

  handleChangeSectionName = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    this.setState({
      sectionName: value,
    });
  };

  handleSubmitName = (e) => {
    console.log('name', this.state.sectionName);
    e.preventDefault();

    this.props.postEditSectionName(this.props.sectionId, {
      sectionName: this.state.sectionName,
    });
  };

  render() {
    const { sectionTemplateListing } = this.props;
    const section = sectionTemplateListing.section;

    if (!sectionTemplateListing.items) {
      return <div>Loading..</div>;
    }

    return (
      <>

<div
              className='mb-20 text-right'
            >
        <Link
          onClick={this.handlePublish}
          className='btn btn-success mr-5'
        >
          PUBLISH TO STORE
        </Link>

        <Link
          onClick={this.handleDuplicate}
          className='btn btn-outline-default'
        >
          Duplicate
        </Link>
</div>

        <div
              className='plane-border-container'
              style={{ maxHeight: '550px', overflow: 'auto' }}
            >
        <h4
          style={
            section.type == 'footer'
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          {section.name}
        </h4>

        <h4
          style={
            section.type == 'footer'
              ? { display: 'none' }
              : { display: 'block' }
          }
        >
          <input
            type='text'
            name='sectionName'
            onChange={this.handleChangeSectionName}
            key={section.name}
            defaultValue={section.name}
          />
          <Link
            onClick={this.handleSubmitName}
            className='btn btn-outline-success'
            style={
              this.state.sectionName == ''
                ? { display: 'none' }
                : {
                    display: 'inline-block',
                    lineHeight: '20px',
                    marginTop: '-5px',
                    marginLeft: '7px',
                  }
            }
          >
            Save
          </Link>
        </h4>

        <div className='form-container'>
          {/* preview btn */}

          {sectionTemplateListing.items.map((item, index) => {
            return (
              <>
                {/* input-text */}
                <div>
                  {item.isLoop === false && (
                    <>
                      <InputItem item={item} section={section} />
                    </>
                  )}
                </div>
                {/* loop-text */}
                <div>
                  {item.isLoop === true && (
                    <>
                      {/* label */}
                      <div>
                        {item.isHeading === true && (
                          <>
                            <b>{item.name}</b>
                          </>
                        )}
                      </div>

                      {/*  */}
                      {/*  */}

                      {item.childItems.map((itemChild, indexChild) => {
                        return (
                          <>
                            {/* further loop */}
                            {itemChild.isLoop === true && (
                              <>
                                <Accordion>
                                  <Card>
                                    <Card.Header>
                                      <Accordion.Toggle
                                        as={Button}
                                        variant='link'
                                        eventKey={indexChild}
                                      >
                                        {indexChild + 1}) {itemChild.name}
                                      </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey={indexChild}>
                                      <Card.Body>
                                        {itemChild.childItems.map(
                                          (itemChildLast, indexChildLast) => {
                                            return (
                                              <>
                                                <InputItem
                                                  item={itemChildLast}
                                                  section={section}
                                                />

                                                {/*  */}

                                                {indexChildLast.isLoop ===
                                                  true && (
                                                  <>
                                                    {indexChildLast.childItems.map(
                                                      (
                                                        itemChildLastFinal,
                                                        indexChildLastFinal
                                                      ) => {
                                                        return (
                                                          <>
                                                            abc
                                                            {/* <InputItem
                                                              item={
                                                                itemChildLastFinal
                                                              }
                                                              section={section}
                                                            /> */}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                )}

                                                {/*  */}
                                              </>
                                            );
                                          }
                                        )}

                                        <div className='mt-10 text-right'>
                                          <Button
                                            class='btn btn-small btn-outline-danger'
                                            className='btn btn-small btn-outline-danger'
                                            onClick={(e) =>
                                              this.removeSectionHandle(
                                                e,
                                                itemChild.id
                                              )
                                            }
                                          >
                                            X Remove Section
                                          </Button>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </>
                            )}
                            {/* items */}
                            {itemChild.isLoop === false && (
                              <>
                                B-{itemChild.name}
                                <InputItem item={itemChild} section={section} />
                              </>
                            )}
                          </>
                        );
                      })}

                      {/*  */}
                      {/*  */}

                      <div className='mt-10 mb-30'>
                        <Button
                          className='btn btn-xs btn-outline-success'
                          onClick={(e) => this.addMoreHandle(e, item.id)}
                        >
                          + Add More
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                {/* input-dropdown */}
                <div>
                  {/* {item.json.formInput &&
                    item.json.formInput.type === 'dropdown' && (
                      <>
                        {item.json.formInput.label}
                        <Select
                          name={item.json.formInput.name}
                          // defaultValue={{
                          //   label: item.jsonformInput.value,
                          //   value: item.formInput.value,
                          // }}
                          options={[{ value: 'aa', label: 'ddd' }]}
                          onChange={(e) =>
                            this.handleChangeDropdown(e, {
                              key: index,
                              name: item.json.formInput.name,
                            })
                          }
                        />
                      </>
                    )} */}
                </div>
              </>
            );
          })}

          {section.type != 'footer' && (
            <>
              <Link
                onClick={this.handleDelete}
                className='btn btn-danger pull-right ml-5'
              >
                DELETE
              </Link>
            </>
          )}
        </div>
     </div>
     
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isJsPreviewGenerateWaiting: state.sectionReducer.isJsPreviewGenerateWaiting,
  sectionTemplateListing: state.sectionTemplateReducer.sectionTemplateListing,
});
export default connect(
  mapStateToProps,
  {
    getSection,
    getAllSectionTemplate,
    postSectionTemplate,
    addMoreTemplate,
    removeSectionTemplate,
    getAllHomeSection,
    postGenerateJSPreview,
    postGenerateJSPublish,
    postDeleteSection,
    postEditSectionName,
    postDuplicateSection,
  }
)(withRouter(Index));
