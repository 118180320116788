import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import { SET_LOADING, STORE_GET } from './types';

const url = apiBaseUrl + '/frontend/store';

export const getStore = (history) => async (dispatch) => {
  try {
    var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];

    // headers
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-API-KEY': `${localStorage.getItem('jwt')}`,
        'X-DOMAIN': domain,
      },
    };

    // response
    const response = await axios.get(`${url}/getStore`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: STORE_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
