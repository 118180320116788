import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Form from './form';
import SectionList from '../sectionList';
import Header from './preview';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        <h4>
          Setting <small>Header</small>
        </h4>

        <Row>
          <Col className='col-md-3'>
            <SectionList activeId='header' />
          </Col>
          <Col className='col-md-9'>
            <h4 className='text-left'>Preview</h4>
            <div className='form-container mb-20'>
              <Header />
            </div>

            <div className='plane-border-container'>
              <Row>
                <Col md='6'>
                  <h4 className='pl-15'>
                    Logo<small></small>
                  </h4>
                  <Form />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //   itemListing: state.itemReducer.itemListing,
});
export default connect(
  mapStateToProps,
  {}
)(Index);
