import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postRunMigration } from '../../../redux/backend/action/migration';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

class Index extends Component {
  state = {};

  componentDidMount() {}

  handleRunMigration = () => {
    this.props.postRunMigration();
  };

  render() {
    const { migration } = this.props;

    if (!migration) {
      return <>Loading..</>;
    }

    return (
      <>
        <dl class='row'>
          {migration.hasNewMigration === false && (
            <>
              <dt class='col-sm-12 proxima-nova-dark mb-10'>
                No new migration found.
              </dt>
            </>
          )}

          {migration.hasNewMigration === true && (
            <>
              <dt class='col-sm-12 proxima-nova-dark mb-10'>
                {migration.output['3']}
              </dt>
              <dt class='col-sm-12 proxima-nova-light mb-10'>
                {migration.output['4']}
              </dt>

              {/* RUN MIGRATION BUTTON */}
              <dt class='col-sm-12 mt-30'>
                <Link
                  onClick={this.handleRunMigration}
                  className='btn btn-success'
                >
                  Run Migration
                </Link>
              </dt>
            </>
          )}
        </dl>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  migration: state.migrationReducer.migration,
  store: state.storeReducer.store,
});

export default connect(
  mapStateToProps,
  { postRunMigration }
)(withRouter(Index));
