import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postAddMenu } from '../../../../redux/backend/action/menu';
import { getAllCollectionDropdown } from '../../../../redux/backend/action/collection';
import { getAllPage } from '../../../../redux/backend/action/page';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import DropdownProduct from './dropdownProductTypeahead';
import { withRouter } from 'react-router-dom';

class FormAdd extends Component {
  state = {
    newMenuName: '',
    newMenuType: { label: 'Collection', value: 'collection' },
    newMenuCollection: {},
    newMenuPage: {},
    templateMenuListing: [],
  };

  componentDidMount() {
    this.props.getAllCollectionDropdown();
    this.props.getAllPage();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    if (
      JSON.stringify(this.state.templateMenuListing) !==
      JSON.stringify(this.props.templateMenuListing)
    ) {
      this.setState({
        templateMenuListing: this.props.templateMenuListing,

        // form reset
        newMenuName: '',
        newMenuType: { label: 'Collection', value: 'collection' },
        newMenuCollection: {},
        newMenuPage: {},
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    console.log(name, value);
    if (name == 'newMenuName') {
      this.setState({
        newMenuName: value,
      });
    } else if (name == 'newInternalLink') {
      this.setState({
        newInternalLink: value,
      });
    } else if (name == 'newExternalLink') {
      this.setState({
        newExternalLink: value,
      });
    }
  };

  handleChangeEditorDescription = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data });

    this.setState({ description: data });
  };

  handleChangeDropdown = (e, name) => {
    console.log('name', name);
    console.log('data', e);

    if (name == 'newType') {
      this.setState({ newMenuType: e });
    } else if (name == 'newCollectionId') {
      this.setState({ newMenuCollection: e });
    } else if (name == 'newPageId') this.setState({ newMenuPage: e });
    else if (name == 'newMenuProductId') {
      this.setState({ newMenuProduct: { label: e[0].name, value: e[0].id } });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postAddMenu(this.state, this.props.history);
  };

  render() {
    const { collectionDropdownListing, pageListing } = this.props;

    const pageDropdownOptions = [];
    if (pageListing.items) {
      pageListing.items.map((page, index) => {
        pageDropdownOptions.push({ label: page.name, value: page.id });
      });

      console.log('pageDropdownOptions', pageDropdownOptions);
    }

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className='form-container'>
            <div className='form-group'>
              <Row>
                <Col md='12'>
                  <input
                    type='text'
                    name='newMenuName'
                    placeholder='Menu Name'
                    className='form-control form-control-sm'
                    value={this.state.newMenuName}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>

              <Row className='mt-15'>
                <Col md='5'>
                  <Select
                    name={'newType'}
                    value={this.state.newMenuType}
                    options={[
                      { label: 'Collection', value: 'collection' },
                      { label: 'Product', value: 'product' },
                      { label: 'Page', value: 'page' },
                      { label: 'Internal Link', value: 'internalLink' },
                      { label: 'External Link', value: 'externalLink' },
                    ]}
                    onChange={(e) => this.handleChangeDropdown(e, 'newType')}
                  />
                </Col>

                <Col md='7'>
                  <div
                    style={
                      this.state.newMenuType.value == 'collection'
                        ? { display: 'block' }
                        : { display: 'none' }
                    }
                  >
                    <Select
                      name={'newCollectionId'}
                      defaultValue={{
                        label: this.state.newMenuCollection.label,
                        value: this.state.newMenuCollection.value,
                      }}
                      options={collectionDropdownListing.dropdownOptions}
                      onChange={(e) =>
                        this.handleChangeDropdown(e, 'newCollectionId')
                      }
                    />
                  </div>

                  {/* internal link */}
                  <div
                    style={
                      this.state.newMenuType.value == 'internalLink'
                        ? { display: 'block' }
                        : { display: 'none' }
                    }
                  >
                    <input
                      type='text'
                      name='newInternalLink'
                      placeholder='e.g. /collection/mens/1'
                      className='form-control form-control-sm'
                      value={this.state.newInternalLink}
                      onChange={this.handleChange}
                    />
                  </div>

                  {/* external link */}
                  <div
                    style={
                      this.state.newMenuType.value == 'externalLink'
                        ? { display: 'block' }
                        : { display: 'none' }
                    }
                  >
                    <input
                      type='text'
                      name='newExternalLink'
                      placeholder='e.g. http://google.com'
                      className='form-control form-control-sm'
                      value={this.state.newExternalLink}
                      onChange={this.handleChange}
                    />
                  </div>

                  <DropdownProduct
                    selectedType={this.state.newMenuType.value}
                    handleChangeDropdown={this.handleChangeDropdown}
                  />

                  <div
                    style={
                      this.state.newMenuType.value == 'page'
                        ? { display: 'block' }
                        : { display: 'none' }
                    }
                  >
                    <Select
                      name={'newPageId'}
                      placeholder='Search...'
                      defaultValue={{
                        label: this.state.newMenuPage.label,
                        value: this.state.newMenuPage.value,
                      }}
                      options={pageDropdownOptions}
                      onChange={(e) =>
                        this.handleChangeDropdown(e, 'newPageId')
                      }
                    />
                  </div>

                  <div></div>
                  <div></div>
                  <div></div>
                </Col>
              </Row>
            </div>

            <div className='form-group'>
              <button
                type='submit'
                id='btn_submit'
                onClick={(e) => this.handleSubmit(e)}
                style={{ marginTop: '10px' }}
                className='btn-success btn'
              >
                ADD
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  templateMenuListing: state.templateReducer.templateMenuListing,
  collectionDropdownListing: state.collectionReducer.collectionDropdownListing,
  pageListing: state.pageReducer.pageListing,
});

export default connect(
  mapStateToProps,
  { getAllCollectionDropdown, getAllPage, postAddMenu }
)(withRouter(FormAdd));
