import axios from 'axios';

const setDbHeader = (domain) => {
  axios.defaults.headers.common['X-API-KEY'] = `${localStorage.getItem('jwt')}`;
  axios.defaults.headers.common['X-DOMAIN'] = domain;

  // sending client current url, used to validate on api after login in-case if user try to access other system by changing url domain info
  // axios.defaults.headers.common['x-url'] = window.location.pathname;
};

export default setDbHeader;
