import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

class FormAdd extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <>
        <Row>
          <Col md='3'>
            <div className='form-group'>
              <label>Price: </label>
              <input
                type='text'
                name='sellPrice'
                className='form-control form-control-sm'
                value={this.props.state.sellPrice}
                onChange={this.props.handleChange}
              />
            </div>
          </Col>

          <Col md='3'>
            <div className='form-group'>
              <label>Compare Price: </label>
              <input
                type='text'
                name='compareSellPrice'
                className='form-control form-control-sm'
                value={this.props.state.compareSellPrice}
                onChange={this.props.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md='3'>
            <div className='form-group'>
              <label>Cost Price: </label>
              <input
                type='text'
                name='costPrice'
                className='form-control form-control-sm'
                value={this.props.state.costPrice}
                onChange={this.props.handleChange}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(FormAdd));
