import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { getAllOrder } from '../../../redux/backend/action/order';

import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import history from '../../../history';
import PaginateTable from '../../../components/PaginateTable';

import TableItem from './tableItems';

class ListTable extends Component {
  state = {
    order: '',
  };

  // handleFilterChange = (order) => {
  //   this.setState({ drugGroup_order: order });

  //   /* get all orders */
  //   let param = this.state;

  //   /* send current order immediately as state can't get current value */
  //   param.Druggroup_order = order;

  //   this.reloadGrid(param);
  // };

  handleFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    /* get all orders */
    let param = this.state;

    if (name === 'orderCode') {
      this.setState({ orderCode: value });
      param.orderCode = value;
    } else if (name === 'orderName') {
      this.setState({ orderName: value });
      param.orderName = value;
    } else if (name === 'orderUnit') {
      this.setState({ orderUnit: value });
      param.orderUnit = value;
    } else if (name === 'orderQtyAlert') {
      this.setState({ orderQtyAlert: value });
      param.orderQtyAlert = value;
    }

    this.reloadGrid(param);
  };

  reloadGrid = (param) => {
    this.props.getAllOrder(param);
  };

  render() {
    const { orderListing } = this.props;

    if (!orderListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <Accordion
          defaultActiveKey='0'
          style={{ borderBottom: '1px solid #dedede' }}
        >
          {orderListing.items.map((order, index) => {
            return (
              <>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey={index}>
                    <Row>
                      <Col md='4'>
                        Date: {order.date}
                        <div>Item count ({order.itemCount})</div>
                        <div>
                          <b>Total: {order.totalLabel}</b>
                        </div>
                      </Col>

                      <Col md='4'>
                        {order.customer && (
                          <>
                            Customer: <b>{order.customer.name}</b>
                            <div>{order.customer.address}</div>
                            <div>{order.customer.email}</div>
                            <div>{order.customer.mobile}</div>
                            <div>{order.customer.phone}</div>
                          </>
                        )}
                      </Col>

                      <Col md='2'>
                        <b>{order.statusLabel}</b>

                        {/* remark */}
                        {order.remark && <div>Remark: {order.remark}</div>}
                      </Col>

                      <Col md='2' className='text-right'>
                        <Row>
                          {/*  */}
                          <Col md='9'>
                            {order.statusLabel == 'Pending' && (
                              <>
                                <div className='fulfill-order'>
                                  <Link
                                    to={'/order/set-fulfill/' + order.id}
                                    className='btn btn-success btn-small'
                                  >
                                    Set as Fulfilled
                                  </Link>
                                </div>

                                <div className='cancel-order'>
                                  <Link
                                    to={'/order/set-cancell/' + order.id}
                                    className='btn btn-danger btn-small mt-5'
                                    style={{ width: '110px' }}
                                  >
                                    Cancel Order
                                  </Link>
                                </div>
                              </>
                            )}
                          </Col>
                          {/*  */}

                          <Col md='3' className='pl-0'>
                            <Link className='btn btn-outline-success btn-sm'>
                              Detail
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey={index}>
                    <Card.Body>
                      <TableItem orderItems={order.orderItems} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </>
            );
          })}
        </Accordion>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orderListing: state.orderReducer.orderListing,
});
export default connect(
  mapStateToProps,
  { getAllOrder }
)(ListTable);
