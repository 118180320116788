import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  postAddCollectionFilter,
  deleteCollectionFilter,
} from '../../../redux/backend/action/collection';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FormEdit extends Component {
  componentDidMount() {}

  handleChangeDropdownFilter = (e) => {
    console.log(e);
    this.props.postAddCollectionFilter(this.props.match.params.id, e);
  };

  handleDeleteCollectionFilter = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete this item?')) {
      this.props.deleteCollectionFilter(id);
    }
  };

  render() {
    const {
      collection,
      collectionDropdownListing,
      filterListing,
      collectionFilterListing,
    } = this.props;

    /* if user go back & id='' then if page not reloaded, it should show 'please choose..' */
    if (!collection || this.props.match.params.id === 'id=')
      return (
        <>
          <b>Please choose Collection</b>
        </>
      );

    var filterData = [{ label: '-', value: '' }];
    if (filterListing.items) {
      filterListing.items.map((filter, filterIndex) => {
        var label = filter.name;

        /* internal name */
        if (filter.name !== filter.internalName && filter.internalName) {
          label = label + ' (' + filter.internalName + ')';
        }

        var filterItem = { label: label, value: filter.id };

        filterData.push(filterItem);
      });
    }

    console.log('cooooo', collection);
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row className='mt-15'>
            <Col md='12'>
              <Row>
                <Col md='6'>
                  <div className='form-group'>
                    <label>Select Filter: </label>

                    <Select
                      name={'parentId'}
                      options={filterData}
                      onChange={(e) => this.handleChangeDropdownFilter(e)}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md='6'>
                  <div className='form-group' style={{ width: '300px' }}>
                    <label>
                      <b>Added Filters: </b>
                    </label>

                    {collectionFilterListing.items && (
                      <>
                        {collectionFilterListing.items.map(
                          (collectionFilter, addedFilterIndex) => {
                            let filterLabel = collectionFilter.name;

                            {
                              /* with internalName */
                            }
                            if (
                              collectionFilter.name !==
                                collectionFilter.internalName &&
                              collectionFilter.internalName
                            ) {
                              filterLabel =
                                filterLabel +
                                ' (' +
                                collectionFilter.internalName +
                                ')';
                            }
                            return (
                              <>
                                <div className='mb-10'>
                                  <b>{filterLabel}</b>

                                  <span className='pull-right'>
                                    <Link
                                      onClick={(e) =>
                                        this.handleDeleteCollectionFilter(
                                          e,
                                          collectionFilter.id
                                        )
                                      }
                                    >
                                      Delete
                                    </Link>
                                  </span>

                                  {/*  */}
                                  {collectionFilter.filterOptions.map(
                                    (option, optionIndex) => {
                                      return (
                                        <>
                                          <div className='ml-20'>
                                            {option.name}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                  {/*  */}
                                </div>
                              </>
                            );
                          }
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collection: state.collectionReducer.collection,
  collectionDropdownListing: state.collectionReducer.collectionDropdownListing,
  filterListing: state.filterReducer.filterListing,
  collectionFilterListing: state.collectionReducer.collectionFilterListing,
});

export default connect(
  mapStateToProps,
  {
    postAddCollectionFilter,
    deleteCollectionFilter,
  }
)(withRouter(FormEdit));
