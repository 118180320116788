import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  postAddFilterOption,
  deleteFilterOption,
} from '../../../redux/backend/action/filter';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

class FormEdit extends Component {
  state = {
    name: '',
  };

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'name') {
      this.setState({ name: value });
    }
  };

  handleDeleteOption = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete this item?')) {
      this.props.deleteFilterOption(id);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.postAddFilterOption(
      this.props.match.params.id,
      this.state,
      this.props.history
    );
  };

  render() {
    const { filter } = this.props;

    if (!filter.item) {
      return <>Loading..</>;
    }
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row className='mt-15'>
            <Col md='12'>
              <h4>
                Add New Options
                <small className='ml-10'>{filter.item.name}</small>
              </h4>
              <div className='form-container'>
                <Row>
                  <Col md='6'>
                    <div className='form-group'>
                      <label>New Option: </label>

                      <input
                        type='text'
                        name='name'
                        className='form-control form-control-sm'
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md='6'>
                    <div className='form-group' style={{ width: '300px' }}>
                      <label>
                        <b>Added Option: </b>
                      </label>

                      {filter.item.filterOptions && (
                        <>
                          {filter.item.filterOptions.map(
                            (option, optionIndex) => {
                              return (
                                <>
                                  <div className='mb-10'>
                                    <b>{option.name}</b>

                                    <span className='pull-right'>
                                      <Link
                                        onClick={(e) =>
                                          this.handleDeleteOption(e, option.id)
                                        }
                                      >
                                        Delete
                                      </Link>
                                    </span>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className='form-group'>
            <button
              type='submit'
              id='btn_submit'
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: '10px' }}
              className='btn-success btn'
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filterReducer.filter,
});

export default connect(
  mapStateToProps,
  { postAddFilterOption, deleteFilterOption }
)(withRouter(FormEdit));
