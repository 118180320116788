import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getProfile, postEditUser } from '../../../redux/backend/action/user';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

class Index extends Component {
  state = {};

  componentDidMount() {
    this.props.getProfile();
  }

  componentWillReceiveProps(recievedProps) {
    const user = recievedProps.user;

    if (user) {
      this.setState({
        name: user.name,
        address: user.address,
        phone: user.phone,
        mobile: user.mobile,
        email: user.email,
        vehicleId: user.vehicleId,
        vehicleName: user.vehicleName,
      });
    }
  }

  render() {
    const { user } = this.props;

    console.log('usss', user);

    if (!user) {
      return <>Loading..</>;
    }

    return (
      <>
        <dl class='row'>
          <dt class='col-sm-1 proxima-nova-light'>Name</dt>
          <dd class='col-sm-10 proxima-nova-bold'>{user.name}</dd>
        </dl>

        <dl class='row'>
          <dt class='col-sm-1 proxima-nova-light'>Email</dt>
          <dd class='col-sm-10 proxima-nova-bold'>{user.email}</dd>
        </dl>

        <dl class='row'>
          <dt class='col-sm-1 proxima-nova-light'>Mobile</dt>
          <dd class='col-sm-10 proxima-nova-bold'>{user.mobile}</dd>
        </dl>

        <dl class='row'>
          <dt class='col-sm-1 proxima-nova-light'>Phone</dt>
          <dd class='col-sm-10 proxima-nova-bold'>{user.phone}</dd>
        </dl>

        <Link to={'/user/edit-profile'} className='btn btn-outline-default'>
          Edit
        </Link>

        <Link
          to={'/user/change-password'}
          className='btn btn-outline-success ml-10'
        >
          Change Password
        </Link>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user.item,
});

export default connect(
  mapStateToProps,
  { getProfile }
)(withRouter(Index));
