import React, {Component} from 'react'

import {connect} from 'react-redux'
import FormAdd from './formAdd'
// import {} from '../../../redux/action/user'
class Index extends Component {

    componentDidMount(){
    }
    componentWillMount(){
    }

    componentWillReceiveProps(recievedProps) {
    }

    render () {

        return (
            <>
                <h4>Add User</h4>
                <div className="form-container">
                    <FormAdd 
                    />
                </div>
            </>
        )
    }


}

const mapStateToProps = state => ({
})
export default connect(mapStateToProps,{})(Index)