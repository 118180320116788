import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllItem, postDeleteItem } from '../../../redux/backend/action/item';

import {
  DropdownButton,
  SplitButton,
  ButtonGroup,
  Dropdown,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import history from '../../../history';
import PaginateTable from '../../../components/PaginateTable';

import TreeCollection from '../../component/treeCollection';

class ListTable extends Component {
  state = {
    page: '',
  };

  handlePageChange = (page) => {
    this.setState({ page: page });
    /* get all filters */
    let param = this.state;

    /* send current filter immediately as state can't get current value */
    param.Item_page = page;

    this.reloadGrid(param);
  };

  handleFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    /* get all filters */
    let param = this.state;

    if (name === 'filterId') {
      this.setState({ filterId: value });
      param.filterId = value;
    } else if (name === 'filterName') {
      this.setState({ filterName: value });
      param.filterName = value;
    }

    this.reloadGrid(param);
  };

  reloadGrid = (param) => {
    this.props.getAllItem(param);
  };

  handleDeleteItem = (e, id) => {
    e.preventDefault();

    if (window.confirm('Are you sure, you want to delete?')) {
      this.props.postDeleteItem({ id: id }, this.props.history);
    }
  };

  render() {
    const { itemListing, store } = this.props;

    if (!store.item) {
      return <div></div>;
    }

    if (!itemListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <Table responsive size='sm' className='tbl-list-view'>
          <thead>
            <tr>
              <th style={{ width: '125px' }}></th>
              <th style={{ width: '115px' }} className='text-center'>
                ID
              </th>
              <th>Name</th>
              <th style={{ width: '315px' }}>Collection</th>
              <th style={{ width: '155px' }} className='text-center'>
                Quantity
              </th>
              <th style={{ width: '195px' }}>Created At</th>
              <th style={{ width: '55px' }}></th>
            </tr>
            <tr className='filters' style={{ background: '#E3EAF2' }}>
              <th style={{ verticalAlign: 'middle' }}></th>
              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterId'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}>
                <input
                  type='text'
                  name='filterName'
                  className='form-control'
                  onChange={this.handleFilterChange}
                />
              </th>

              <th style={{ verticalAlign: 'middle' }}></th>

              <th style={{ verticalAlign: 'middle' }}></th>

              <th></th>
              <th style={{ width: '125px' }}></th>
            </tr>
          </thead>
          <tbody>
            {itemListing.items.map((item, index) => {
              return (
                <>
                  <tr>
                    <td>
                      <img
                        style={{ width: '100px' }}
                        src={store.item.fileBaseUrl + item.imageUrl}
                      />
                    </td>
                    <td className='text-center'>{item.id}</td>
                    <td>{item.name}</td>
                    <td>
                      <TreeCollection product={item} />
                    </td>

                    <td className='text-center'>{item.quantity}</td>
                    <td>{item.createdAt}</td>
                    <td align='right'>
                      {/*  */}
                      {/*  */}
                      <DropdownButton
                        alignRight
                        title='Action'
                        id='dropdown-menu-align-right'
                        variant='secondary btn-small'
                        style={{ marginTop: '8px' }}
                      >
                        <NavLink
                          exact
                          activeClassName='selected'
                          className='dropdown-item'
                          to={'/product/view/' + item.id}
                        >
                          View
                        </NavLink>

                        <NavLink
                          exact
                          activeClassName='selected'
                          className='dropdown-item'
                          to={'/product/add-image/' + item.id}
                        >
                          Add Images
                        </NavLink>

                        <NavLink
                          exact
                          activeClassName='selected'
                          className='dropdown-item'
                          to={'/product/add-to-collection/' + item.id}
                        >
                          Add To Collection
                        </NavLink>

                        <NavLink
                          exact
                          activeClassName='selected'
                          className='dropdown-item'
                          to={'/product/edit/' + item.id}
                        >
                          Edit
                        </NavLink>

                        <NavLink
                          exact
                          activeClassName='selected'
                          className='dropdown-item'
                          onClick={(e) => this.handleDeleteItem(e, item.id)}
                        >
                          Delete
                        </NavLink>
                      </DropdownButton>
                      {/*  */}
                      {/*  */}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan='7'>
                      <hr />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>

        <PaginateTable
          listing={this.props.itemListing}
          activePage={this.state.page}
          handlePageChange={this.handlePageChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
  itemListing: state.itemReducer.itemListing,
});
export default connect(
  mapStateToProps,
  { getAllItem, postDeleteItem }
)(withRouter(ListTable));
