// dev
var url = "";

if (process.env.NODE_ENV === "production") {
  var str = window.location.hostname;

  // prod - LOCAL
  if (window.location.hostname === "localhost") {
    url = "http://localhost/eztoz/api/app";
  } else if (str.indexOf("mystore.eztoz") !== -1) {
    // accessing local network
    url = "http://localhost/eztoz/api/app";
  } else if (str.indexOf("ecom.prod") !== -1) {
    // accessing local network
    url = "http://localhost/eztoz/api/app";
  } else {
    // prod - ONLINE AWS SERVER
    url = "https://api.eztoz.com/eztoz/api/app";
    // url = "http://eztoz.com/eztoz/api/app";
    // url = 'http://sdb.aws.mybluehost.me/eztoz/api/app';
  }
}

export const apiBaseUrl = url;

/* local with proxy
NOTE: put in package.json
  "proxy": "http://api.pharmacy.localhost/index.php", 
*/
