import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

import FilterOption from './filterOption';
import TreeCollection from '../../component/treeCollection';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  render() {
    const { productRow } = this.props;
    if (!productRow.item) return <>Loading..</>;

    const product = productRow.item;

    return (
      <>
        <h3 className='proxima-nova-bold'>{product.name}</h3>

        <dl class='row'>
          <dt class='col-sm-2 proxima-nova-light'>Price</dt>
          <dd class='col-sm-10 proxima-nova-bold'>{product.sellPriceLabel}</dd>

          <dt class='col-sm-2 proxima-nova-light'>Compare Price</dt>
          <dd class='col-sm-10 proxima-nova-bold'>
            {product.compareSellPriceLabel}
          </dd>

          <dt class='col-sm-2 proxima-nova-light'>Quantity</dt>
          <dd class='col-sm-10 proxima-nova-bold'>{product.quantity}</dd>
        </dl>

        <hr />

        <h6 className='proxima-nova-bold'>Filters:</h6>
        <FilterOption />

        <hr />

        <h6 className='proxima-nova-bold'>Collections:</h6>
        <TreeCollection product={product} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
