import React, { Component } from 'react';

import { connect } from 'react-redux';
import { postEditStoreMenuTemplate } from '../../../../redux/backend/action/store';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import ColorInput from './inputColorPicker';

class Index extends Component {
  state = {
    selectedAlignment: {},
    menuBackgroundColor: '',
    menuTextColor: '',
    store: [],
  };

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /* first time load, set sate data */
    if (this.props.store.item && this.state.store.length === 0) {
      let store = this.props.store;

      /* ucword label */
      let alignmentLabel = store.item.menuTextAlignment;
      // alignmentLabel = alignmentLabel
      //   .toLowerCase()
      //   .replace(/(?<= )[^\s]|^./g, (a) => a.toUpperCase());

      this.setState({
        selectedAlignment: {
          label: alignmentLabel,
          value: store.item.menuTextAlignment,
        },
        menuBackgroundColor: store.item.menuBackgroundColor,
        menuTextColor: store.item.menuTextColor,
        store: store,
      });
    }
  }

  handleChangeDropdown = (selectedAlignment) => {
    this.setState({
      selectedAlignment,
    });

    var data = 'menuTextAlignment=' + selectedAlignment.value;
    var storeId = this.props.store.item.id;
    this.props.postEditStoreMenuTemplate(storeId, data, this.props.history);
  };

  handleChangeColor = (color, name) => {
    var data = '';
    if (name == 'menuBackgroundColor') {
      data = 'menuBackgroundColor=' + color;
    } else if (name == 'menuTextColor') {
      data = 'menuTextColor=' + color;
    }

    if (data) {
      var storeId = this.props.store.item.id;
      this.props.postEditStoreMenuTemplate(storeId, data, this.props.history);
    }
  };

  hexToRgb = (hex) => {
    if (hex == null || !hex.includes('#')) {
      return { r: '', g: '', b: '' };
    }

    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: '', g: '', b: '' };
  };

  render() {
    const { store } = this.props;

    if (!store.item) {
      return <>Loading..</>;
    }

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className='form-container'>
            <div className='form-group'>
              <Row>
                <Col md='12'>
                  Menu Alignment <br />
                  <Select
                    name={'input'}
                    value={this.state.selectedAlignment}
                    // defaultValue={{
                    //   label: store.item.menuBackgroundColorJson ? store.item.menuBackgroundColorJson.label : '',
                    //   value: store.item.menuBackgroundColorJson ? store.item.menuBackgroundColorJson.value : '',
                    // }}
                    options={[
                      { label: 'Center', value: 'center' },
                      { label: 'Left', value: 'left' },
                    ]}
                    onChange={(e) => this.handleChangeDropdown(e)}
                  />
                </Col>
              </Row>

              <Row className='mt-15'>
                <Col md='5'>
                  Background Color <br />
                  <ColorInput
                    name='menuBackgroundColor'
                    handleChangeColor={this.handleChangeColor}
                    colorR={this.hexToRgb(store.item.menuBackgroundColor).r}
                    colorG={this.hexToRgb(store.item.menuBackgroundColor).g}
                    colorB={this.hexToRgb(store.item.menuBackgroundColor).b}
                  />
                </Col>

                <Col md='5'>
                  Text Color <br />
                  <ColorInput
                    name='menuTextColor'
                    handleChangeColor={this.handleChangeColor}
                    colorR={this.hexToRgb(store.item.menuTextColor).r}
                    colorG={this.hexToRgb(store.item.menuTextColor).g}
                    colorB={this.hexToRgb(store.item.menuTextColor).b}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});

export default connect(
  mapStateToProps,
  { postEditStoreMenuTemplate }
)(withRouter(Index));
