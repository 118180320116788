import axios from 'axios';
import { toast } from 'react-toastify';
import { apiBaseUrl } from '../../../constant/api';

import {
  SET_LOADING,
  ITEM_GET_ALL,
  ITEM_GET,
  ITEM_COLLECTION_GET_ALL,
} from './types';

const url = apiBaseUrl + '/backend/item';

export const getAllItem = (data) => async (dispatch) => {
  // params
  const param = data ? new URLSearchParams(data).toString() : {};

  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getAllItem?${param}`, config);

    // dispatch
    dispatch({
      type: ITEM_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const getItem = (id, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.get(`${url}/getItem?id=${id}`, config);

    if (response.data.result === 'SUCCESS') {
      // dispatch
      dispatch({ type: ITEM_GET, payload: response.data });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postAddItem = (formData, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        // //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(`${url}/postAddItem`, formData, config);

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      history.push('/product/add-image/' + response.data.item.id);
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postEditItem = (id, formData, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        // //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postEditItem?id=${id}`,
      formData,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
      // history.push('/product');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const getAllItemCollection = (id) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        // //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(
      `${url}/getAllItemCollection?id=${id}`,
      config
    );

    // dispatch
    dispatch({
      type: ITEM_COLLECTION_GET_ALL,
      payload: response.data,
    });
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const deleteCollectionItem = (id) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(
      `${url}/deleteCollectionItem?id=${id}`,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      dispatch({
        type: ITEM_COLLECTION_GET_ALL,
        payload: response.data.collectionItemListingData,
      });
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {
    // dispatch error
    // dispatch({
    //   type: POST_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

export const postAddCollection = (id, formData, history) => async (
  dispatch
) => {
  try {
    // headers
    const config = {
      headers: {
        // //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postAddCollection?id=${id}`,
      formData,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      dispatch({ type: ITEM_GET, payload: response.data.itemData });

    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const postItemFilter = (id, formData, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postItemFilter?id=${id}`,
      formData,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};

export const getCountItem = (data) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // get
    const response = await axios.get(`${url}/getCountItem`, config);

    // dispatch
    // dispatch({ type: GET_COUNT_LAB_REPORTS, payload: response.data });
  } catch (err) {}
};

export const postDeleteItem = (formData, history) => async (dispatch) => {
  try {
    // headers
    const config = {
      headers: {
        // //Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // response
    const response = await axios.post(
      `${url}/postDeleteItem`,
      formData,
      config
    );

    if (response.data.result === 'SUCCESS') {
      toast.success(response.data.message);

      // dispatch
      dispatch({
        type: ITEM_GET_ALL,
        payload: response.data.listingData,
      });

      history.push('/product');
    } else if (response.data.result === 'ERROR') {
      toast.error(response.data.message);
    }
  } catch (err) {}
};
