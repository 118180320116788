import React, { Component } from "react";

import { connect } from "react-redux";
import { postEditPage } from "../../../redux/backend/action/page";

import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/lib/Creatable";
import AsyncSelect from "react-select/lib/Async";
import DatePicker from "react-datepicker";
import moment from "moment";

import { withRouter } from "react-router-dom";

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';

// ClassicEditor
//     .create( document.querySelector( '#editor' ), {
//         plugins: [ Essentials, Paragraph, Bold, Italic ],
//         toolbar: [ 'bold', 'italic' ]
//     } )
//     .then( editor => {
//         console.log( 'Editor was initialized', editor );
//     } )
//     .catch( error => {
//         console.error( error.stack );
//     } );

class FormAdd extends Component {
  state = {
    page: "",
    content: null,
  };

  componentDidMount() {}

  componentWillReceiveProps(recievedProps) {
    const page = recievedProps.page.item;
    if (page) {
      this.setState({
        id: page.id,
        name: page.name,
        content: page.content,
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === "name") {
      this.setState({ name: value });
    }
  };

  handleChangeEditorDescription = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data });

    this.setState({ content: data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.postEditPage(this.state.id, this.state, this.props.history);
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col md="6">
              <div className="form-group">
                <label>Name: </label>
                <input
                  type="text"
                  name="name"
                  className="form-control form-control-sm"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>
            </Col>

            <Col md="12">
              <div className="form-group">
                <label>Content: </label>

                {/* <textarea id='editor' name='aa'></textarea> */}

                <CKEditor
                  editor={ClassicEditor}
                  data={this.state.content}
                  onInit={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "min-height",
                        "300px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  config={{
                    toolbar: [
                      "undo",
                      "redo",
                      "bold",
                      "italic",
                      "blockQuote",
                      "ckfinder",
                      "imageTextAlternative",
                      "imageUpload",
                      "heading",
                      "imageStyle:full",
                      "imageStyle:side",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "mediaEmbed",
                      "insertTable",
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                    ],
                  }}
                  onChange={this.handleChangeEditorDescription}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </Col>
          </Row>

          <div className="form-group">
            <button
              type="submit"
              id="btn_submit"
              onClick={(e) => this.handleSubmit(e)}
              style={{ marginTop: "10px" }}
              className="btn-success btn"
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.pageReducer.page,
});

export default connect(
  mapStateToProps,
  { postEditPage }
)(withRouter(FormAdd));
