import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  NavLink,
} from "react-router-dom";

import Clock from "react-digital-clock";
import Moment from "react-moment";

// FRONTEND
import Homepage from "../feature-frontend/homepage";
import HomepagePreview from "../feature-frontend/homepage/preview";
import ProductView from "../feature-frontend/product";
import CollectionView from "../feature-frontend/collection";
import PageView from "../feature-frontend/page";
import VerifyCode from "../feature-frontend/verify-register";
import Watchlist from "../feature-frontend/watchlist";
import OrderList from "../feature-frontend/order/list";
import Cart from "../feature-frontend/cart";
import Login from "../feature-frontend/login";
import Logout from "../feature-frontend/logout";

// route
import { ProtectedRouteFrontend } from "./protectedRouteFrontend";

const NavPharmacy = () => {
  return (
    <>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/home" component={Homepage} />
      <Route exact path="/home-preview" component={HomepagePreview} />
      <Route path="/product/:slug/:id" component={ProductView} />
      <Route path="/collection/:slug/:id" component={CollectionView} />
      <Route path="/page/:slug/:id" component={PageView} />
      <Route exact path="/login" component={Login} />
      <Route path="/registration-verification/:data" component={VerifyCode} />
      <ProtectedRouteFrontend exact path="/watchlist" component={Watchlist} />
      <ProtectedRouteFrontend exact path="/order" component={OrderList} />
      <ProtectedRouteFrontend exact path="/cart" component={Cart} />
      <ProtectedRouteFrontend exact path="/logout" component={Logout} />
    </>
  );
};

export default NavPharmacy;
