import {
  SUBMITTING_STARTED,
  SUBMITTING_DONE,
  MENU_GET_ALL,
  MENU_GET,
  TEMPLATE_MENU_GET_ALL,
  SET_LOADING,
} from '../action/types';

const initialState = {
  menu: [],
  templateMenuListing: [],
  menuListing: [],
  loading: true,
  submitting: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTING_STARTED: {
      return {
        ...state,
        submitting: true,
      };
    }

    case SUBMITTING_DONE: {
      return {
        ...state,
        submitting: false,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case MENU_GET_ALL: {
      return {
        ...state,
        templateMenuListing: action.payload,
        loading: false,
      };
    }

    case MENU_GET: {
      return {
        ...state,
        menu: action.payload,
        loading: false,
      };
    }

    case TEMPLATE_MENU_GET_ALL: {
      return {
        ...state,
        templateMenuListing: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};
