import React, { Component } from 'react';
import { Card, Button, Row, Col, Accordion } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
// import {
//   getAllMenu,
//   postSort,
//   deleteItemImage,
// } from '../../../../redux/backend/action/menu';
import {
  getAllItemImage,
  postSort,
  deleteItemImage,
} from '../../../redux/backend/action/itemImage';

import { Link } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const DragHandle = sortableHandle(() => (
  <span className='Showcase__style__handle'>::</span>
));

const SortableItem = sortableElement(({ value, id, source, handleDelete }) => (
  <li className='SortableItem'>
    <DragHandle />
    <img width='84px' height='55px' src={source} />

    <span className='ml-20'>{value}</span>

    <div
      style={{
        right: '0',
        position: 'absolute',
        marginRight: '8px',
      }}
    >
      <div
        className='btn btn-danger btn-sm'
        onClick={(e) => handleDelete(e, id)}
      >
        Delete
      </div>
    </div>
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className='SortableList'>{children}</ul>;
});

class Index extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    this.props.getAllItemImage(this.props.itemId);
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    if (
      JSON.stringify(this.state.items) !==
      JSON.stringify(this.props.itemImageListing.items)
    ) {
      console.log('trigger');
      this.setState({
        items: this.props.itemImageListing.items,
      });
    }
  }

  handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure, you want to delete?')) {
      console.log(id);
      this.props.deleteItemImage(id);
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    console.log(oldIndex, newIndex);

    let sortedItems = arrayMove(this.state.items, oldIndex, newIndex);

    this.setState(({ items }) => ({
      items: sortedItems,
    }));

    this.props.postSort(this.props.itemId, sortedItems);
    console.log('sortedItems', sortedItems);
  };

  render() {
    const { items } = this.state;

    if (!items) {
      return <div>Loading..</div>;
    }

    return (
      <>
        <SortableContainer
          onSortEnd={this.onSortEnd}
          handleDelete={this.handleDelete}
          useDragHandle
        >
          {items.map((item, index) => (
            <>
              <SortableItem
                key={`item-${item.id}`}
                index={index}
                value={item.name}
                id={item.id}
                source={item.source}
                handleDelete={this.handleDelete}
              />
            </>
          ))}
        </SortableContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  itemImageListing: state.itemImageReducer.itemImageListing,
});
export default connect(
  mapStateToProps,
  { getAllItemImage, postSort, deleteItemImage }
)(Index);
