import React, { Component } from 'react';
import { Carousel, Card, CardGroup, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Nav, Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import Radium, { Style } from 'radium';

class Index extends Component {
  createMarkup = (text) => {
    return { __html: text };
  };

  render() {
    const { data } = this.props;
    if (!data) return <></>;

    let topContentPadding = data.topContentPadding
      ? data.topContentPadding + 'px'
      : '5px';

    let bottomContentPadding = data.bottomContentPadding
      ? data.bottomContentPadding + 'px'
      : '5px';

    let linkColor = data.linkColor ? data.linkColor : '#3CC0FF';

    let items = data.items;

    return (
      <>
        <Style
          scopeSelector='.footer-block'
          rules={{
            a: {
              color: linkColor,
            },
            'a:visited': {
              color: linkColor,
            },
          }}
        />

        <div className='footer-block'>
          {/* TOP SECTION */}
          {data.topContent && (
            <div
              style={{
                maxWidth: '100%',
                margin: '0px auto',
              }}
            >
              <div
                className='editor-content'
                style={{
                  width: '100%',
                  color: data.topContentTextColor,
                  background: data.topContentBackground,
                  textAlign: data.topContentAlignment,
                  padding: topContentPadding,
                }}
              >
                <p>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(data.topContent)}
                    className='editor'
                  ></div>
                </p>
              </div>
            </div>
          )}

          {/* MAIN SECTION */}
          <div style={{ maxWidth: '100%', margin: '0px auto' }}>
            <div
              className='footer-container editor-content'
              style={{
                color: data.rowContentTextColor,
                background: data.rowContentBackground,
              }}
            >
              <Row>
                {items.map((item, index) => {
                  return (
                    <Col
                      className='12'
                      style={{
                        borderRight: '1px solid ' + item.rightLineColor,
                      }}
                    >
                      <CardGroup>
                        <Card style={{ width: '100%', minHeight: '165px' }}>
                          <Card.Body
                            className='pl-10 pr-10'
                            style={{ textAlign: item.textAlignment }}
                          >
                            <Card.Text>
                              <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                  item.text
                                )}
                                className='editor'
                              ></div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </CardGroup>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>

          {/* BOTTOM SECTION */}
          {data.bottomContent && (
            <div style={{ maxWidth: '100%', margin: '0px auto' }}>
              <div
                className='editor-content'
                style={{
                  width: '100%',
                  color: data.bottomContentTextColor,
                  background: data.bottomContentBackground,
                  textAlign: data.bottomContentAlignment,
                  padding: bottomContentPadding,
                }}
              >
                <p>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      data.bottomContent
                    )}
                    className='editor'
                  ></div>
                </p>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
