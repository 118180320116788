import React, { Component } from 'react';
import { render } from 'react-dom';

import { connect } from 'react-redux';
import {
  getAllCollectionTree,
  postSortCollection,
  deleteCollection,
} from '../../../redux/backend/action/collection';

import { postAddCollection } from '../../../redux/backend/action/item';

import { withRouter, Link } from 'react-router-dom';

import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { Label } from 'reactstrap';

class Index extends Component {
  state = {
    treeData: {},
    dataLoaded: false,
  };
  componentDidMount() {
    this.props.getAllCollectionTree();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /* first time load, set sate treeData */
    if (this.state.dataLoaded === false) {
      var treeData = this.props.collectionTreeListing.items;
      this.setState({
        treeData: treeData,
        dataLoaded: true,
      });
    }
  }

  renderButton = (id, childrenCount) => {
    return (
      <>
        <Link
          to={'/collection/add-filter/' + id}
          className={
            this.props.collectionId === id
              ? 'btn btn-success btn-small'
              : 'btn btn-outline-success btn-small'
          }
          style={{ position: 'absolute', right: '5px' }}
        >
          Add Filter
        </Link>
      </>
    );
  };

  render() {
    return (
      <div style={{ height: 1000 }} className='hide-sort-tree-move'>
        <SortableTree
          // canDrop={canDrop}
          getNodeKey={({ node }) => node.id}
          treeData={this.state.treeData}
          onChange={(treeData) => this.handleSort(treeData)}
          generateNodeProps={({ node, path }) => ({
            title: (
              <>
                {node.title}
                {this.renderButton(node.id, node.children.length)}
              </>
            ),
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  collectionTreeListing: state.collectionReducer.collectionTreeListing,
});

export default connect(
  mapStateToProps,
  {
    getAllCollectionTree,
    postSortCollection,
    deleteCollection,
    postAddCollection,
  }
)(withRouter(Index));
