import React, { Component } from "react";
import { render } from "react-dom";

import { connect } from "react-redux";
import {
  getAllCollectionTree,
  postSortCollection,
  deleteCollection,
} from "../../../redux/backend/action/collection";
import { withRouter, Link } from "react-router-dom";

import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";

class Index extends Component {
  state = {
    treeData: {},
    dataLoaded: false,
  };
  componentDidMount() {
    this.props.getAllCollectionTree();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /* first time load, set sate treeData */
    if (this.state.dataLoaded === false) {
      var treeData = this.props.collectionTreeListing.items;
      this.setState({
        treeData: treeData,
        countList: this.props.collectionTreeListing.countCollection,
        dataLoaded: true,
      });
      console.log("treeSet", this.props.collectionTreeListing.items);
    }

    var countList = this.props.collectionTreeListing.countCollection;
    /* for newly added data, count is different */
    if (countList !== this.state.countList) {
      this.setState({
        treeData: this.props.collectionTreeListing.items,
        countList: countList,
      });
      console.log("trigger added len", countList);
    }

    console.log(countList, this.state.countList);
  }

  handleSort = (treeData) => {
    this.setState({ treeData });

    this.props.postSortCollection(this.state.treeData);
  };

  handleDelete = (e, id) => {
    e.preventDefault();

    if (window.confirm("Are you sure, you want to delete?")) {
      this.props.deleteCollection(id);
    }
  };
  renderDeleteButton = (id, childrenCount) => {
    return (
      <>
        {childrenCount === 0 && (
          <>
            <Link
              onClick={(e) => this.handleDelete(e, id)}
              className="btn btn-outline-danger btn-small pull-right"
              style={{ position: "absolute", right: "-141px" }}
            >
              X
            </Link>
            <Link
              to={"/collection/add-content/" + id}
              className="btn btn-outline-success btn-small pull-right"
              style={{ position: "absolute", right: "-108px" }}
            >
              Add Content
            </Link>
          </>
        )}
      </>
    );
  };

  render() {
    return (
      <div style={{ height: 1000 }}>
        <SortableTree
          // canDrop={canDrop}
          getNodeKey={({ node }) => node.id}
          treeData={this.state.treeData}
          onChange={(treeData) => this.handleSort(treeData)}
          generateNodeProps={({ node, path }) => ({
            title: (
              <>
                {node.title}
                <div className="italic font-9 text-muted pull-right ml-10">
                  {node.identifier}
                </div>
                {this.renderDeleteButton(node.id, node.children.length)}
              </>
            ),
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  collectionTreeListing: state.collectionReducer.collectionTreeListing,
});

export default connect(
  mapStateToProps,
  { getAllCollectionTree, postSortCollection, deleteCollection }
)(withRouter(Index));
