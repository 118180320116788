import React, { Component } from 'react';

import ReactImageMagnify from 'react-image-magnify';

import { Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

class Index extends Component {
  render() {
    const { productRow } = this.props;

    if (!productRow.item) return <>Loading..</>;

    const product = productRow.item;

    return (
      <>
        <nav aria-label='breadcrumb'>
          <ol class='breadcrumb bg-white mb-0 pt-0'>
            <li class='breadcrumb-item'>
              <Link to='/'>Home</Link>
            </li>

            {product.breadCrumbData.map((breadCrumb, index) => {
              return (
                <>
                  <li class='breadcrumb-item'>
                    <Link
                      to={`/collection/${breadCrumb.slug}/${breadCrumb.id}`}
                    >
                      {breadCrumb.name}
                    </Link>
                  </li>
                </>
              );
            })}

            <li class='breadcrumb-item active' aria-current='page'>
              {product.name}
            </li>
          </ol>
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRow: state.itemReducer.item,
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
