import React, { Component } from "react";
import "react-table/react-table.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllPage, deletePage } from "../../../redux/backend/action/page";

import { Table } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import history from "../../../history";
import PaginateTable from "../../../components/PaginateTable";

class ListTable extends Component {
  state = {
    page: "",
  };

  handlePageChange = (page) => {
    this.setState({ Page_page: page });

    /* get all pages */
    let param = this.state;

    /* send current page immediately as state can't get current value */
    param.Page_page = page;

    this.reloadGrid(param);
  };

  handlePageFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    /* get all pages */
    let param = this.state;

    if (name === "filterName") {
      this.setState({ filterName: value });
      param.filterName = value;
    }

    this.reloadGrid(param);
  };

  handleDeletePage = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you wish to delete this item?")) {
      this.props.deletePage(id);
    }
  };

  reloadGrid = (param) => {
    this.props.getAllPage(param);
  };

  render() {
    const { pageListing } = this.props;

    if (!pageListing.items) {
      return <div>No data found</div>;
    }

    return (
      <>
        <Table responsive size="sm" className="tbl-list-view">
          <thead>
            <tr>
              <th>Name</th>
              <th style={{ width: "250px" }}>Created At</th>
              <th style={{ width: "185px" }}></th>
            </tr>
            <tr className="pages" style={{ background: "#E3EAF2" }}>
              <th style={{ verticalAlign: "middle" }}>
                <input
                  type="text"
                  name="filterName"
                  className="form-control"
                  onChange={this.handlePageFilterChange}
                />
              </th>

              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pageListing.items.map((item, index) => {
              return (
                <>
                  <tr>
                    <td>{item.name}</td>

                    <td>{item.createdAt}</td>
                    <td align="right">
                      {/* <Link
                        className='btn btn-small btn-outline-default'
                        to={'/page/view/' + item.id}
                        style={{ marginLeft: '5px' }}
                      >
                        View
                      </Link>{' '} */}
                      <Link
                        className="btn btn-small btn-outline-default"
                        to={"/page/edit/" + item.id}
                        style={{ marginLeft: "5px" }}
                      >
                        Edit
                      </Link>{" "}
                      <Link
                        className="btn btn-small btn-outline-danger"
                        onClick={(e) => this.handleDeletePage(e, item.id)}
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>

        <PaginateTable
          listing={this.props.pageListing}
          activePage={this.state.page}
          handlePageChange={this.handlePageChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pageListing: state.pageReducer.pageListing,
});
export default connect(
  mapStateToProps,
  { getAllPage, deletePage }
)(ListTable);
