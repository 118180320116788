import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getStore } from '../../../redux/backend/action/store';
import Detail from './detail';

class Index extends Component {
  componentDidMount() {
    this.props.getStore();
  }

  render() {
    const { store } = this.props;

    return (
      <>
        <h4>
          Store <small>: {store.item && <>{store.item.name}</>}</small>
        </h4>

        <div className='plane-border-container'>
          <Detail />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
});
export default connect(
  mapStateToProps,
  { getStore }
)(Index);
