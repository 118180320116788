import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getCustomer } from '../../../redux/backend/action/customer';

import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

/* https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html */
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Index extends Component {
  state = {
    customer: '',
  };

  componentDidMount() {}

  componentWillReceiveProps(recievedProps) {
    const customer = recievedProps.customer.item;
    if (customer) {
      this.setState({
        id: customer.id,
        name: customer.name,
        content: customer.content,
      });
    }
  }

  render() {
    const { customer } = this.props;

    if (!customer.item) {
      return <>Loading..</>;
    }

    return (
      <>
        <dl class='row'>
          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Name
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {customer.item.name}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Address
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {customer.item.address}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Email
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {customer.item.email}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Mobile
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {customer.item.mobile}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Phone
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {customer.item.phone}</dd>

          <dt
            class='col-sm-2 proxima-nova-light mb-10'
            style={{ maxWidth: '120px' }}
          >
            Status
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>: {customer.item.status}</dd>

          <dt class='col-sm-2 proxima-nova-light' style={{ maxWidth: '120px' }}>
            Created At
          </dt>
          <dd class='col-sm-10 proxima-nova-bold'>
            : {customer.item.createdAt}
          </dd>
        </dl>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customerReducer.customer,
});

export default connect(
  mapStateToProps,
  { getCustomer }
)(withRouter(Index));
