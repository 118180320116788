import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getAllItemByCollection } from '../../redux/frontend/action/item';
import { getAllCollectionFilter } from '../../redux/frontend/action/collection';
import { getFooter } from '../../redux/frontend/action/section';

import Header from '../header';
import Footer from '../footer';
import Items from './items';
import Filters from './filter';
import BreadCrumb from './breadCrumb';

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {
    var params = this.props.location.search;

    this.props.getAllItemByCollection(this.props.match.params.id, params);
    this.props.getAllCollectionFilter(this.props.match.params.id, params);
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /**
     * new Project in town ?
     */

    if (this.state.id !== this.props.match.params.id) {
      var params = this.props.location.search;
      this.props.getAllItemByCollection(this.props.match.params.id, params);
      this.props.getAllCollectionFilter(this.props.match.params.id, params);

      this.setState({
        id: this.props.match.params.id,
      });
    }
  }

  createMarkup = () => {
    return { __html: this.props.collection ? this.props.collection.content : '' };
  };

  render() {
    const { collection, store } = this.props;
    
    if (!collection || !store.item) {
      return <></>;
    }
    return (
      <>
        <Header />
        <div className='main-container' style={{ width: '100%' }}>
          <div style={{ maxWidth: '90rem', margin: '0px auto' }}>
            <BreadCrumb />


            {store.item.disableCollectionFilter > 0 && store.item.disableProduct > 0 && (
              <>
              <Row>
                <Col md='12'>
                  <div
                    className='proxima-nova-light-'
                    style={{
                      padding: '15px',
                      marginTop: '15px',
                      background: '#f5f5f5',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={this.createMarkup()}
                      className='editor'
                    ></div>

                  </div>

                </Col>
              </Row>
              </>
            )}

            {store.item.disableCollectionFilter === 0 && store.item.disableProduct === 0 && (
              <>
              <Row>
                <Col md='3'>
                  <Filters />
                </Col>
                <Col md='9'>
                  <Items collectionId={this.state.id} />
                </Col>
              </Row>
              </>
            )}

            
          </div>
        </div>

        {/* footer */}
        <Footer />
        {/*  */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.storeReducer.store,
  section: state.sectionReducer.section,
  collection: state.itemReducer.collection,
  itemListing: state.itemReducer.itemListing,
});
export default connect(
  mapStateToProps,
  {
    getAllItemByCollection,
    getAllCollectionFilter,
    getFooter,
  }
)(withRouter(Index));
