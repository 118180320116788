import React, { Component } from "react";
import { Carousel, Card, Button, Row, Col } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { getPage } from "../../redux/frontend/action/page";

import Header from "../header";
import Footer from "../footer";
import BreadCrumb from "./breadCrumb";

class Index extends Component {
  state = {
    id: this.props.match.params.id,
  };

  componentDidMount() {
    this.props.getPage(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps == undefined) {
      return false;
    }

    /**
     * new Project in town ?
     */
    if (this.state.id !== this.props.match.params.id) {
      this.props.getPage(this.props.match.params.id);

      this.setState({
        id: this.props.match.params.id,
      });
    }
  }

  createMarkup = () => {
    return { __html: this.props.page.item.content };
  };

  render() {
    const { page } = this.props;

    if (!page.item) {
      return <>Loading..</>;
    }

    return (
      <>
        <Header />
        <div
          className="main-container"
          style={{ width: "100%", minHeight: "300px" }}
        >
          {/* PRODUCT LIST */}
          <div style={{ maxWidth: "75rem", margin: "0px auto" }}>
            <BreadCrumb />

            <h2>{this.props.page.item.name}</h2>

            <div
              className="proxima-nova-light-"
              style={{
                padding: "15px",
                marginTop: "15px",
                background: "#f5f5f5",
              }}
            >
              <div
                dangerouslySetInnerHTML={this.createMarkup()}
                className="editor"
              ></div>
            </div>
          </div>
        </div>

        {/* footer */}
        <Footer />
        {/*  */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.pageReducer.page,
});
export default connect(
  mapStateToProps,
  { getPage }
)(withRouter(Index));
