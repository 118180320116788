import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getAllItemByCollection } from '../../redux/frontend/action/item';
import { postAddWatchlist } from '../../redux/frontend/action/watchlist';
import { postAddCart } from '../../redux/frontend/action/cart';

import ProductThumb from '../components/productThumb';
import PaginateTable from '../../components/PaginateTable';
import queryString from 'query-string';

import AppliedFilters from './appliedFilters';

class Index extends Component {
  state = {
    page: '',
  };

  handleAddToCart = (e, id) => {
    e.preventDefault();
    this.props.postAddCart(id);
    console.log('cart here ', id);
  };

  handleAddToWishlist = (e, id) => {
    e.preventDefault();
    this.props.postAddWatchlist(id);
    console.log('wishlist here', id);
  };

  handlePageChange = (page) => {
    /* get all filters */
    let param = this.state;

    /* send current filter immediately as state can't get current value */
    param.Item_page = page;

    let urlParam = queryString.parse(this.props.location.search);

    // add page no.
    urlParam['Item_page'] = page;

    let paramString = new URLSearchParams(urlParam).toString();

    this.reloadGrid(paramString);
  };

  reloadGrid = (param) => {
    // change url
    this.props.history.push(this.props.location.pathname + '?' + param);

    // api call here
    this.props.getAllItemByCollection(this.props.collectionId, param);
  };

  render() {
    const { collection, itemListing } = this.props;
    if (!collection || !itemListing.items) {
      return <></>;
    }

    return (
      <>
        <h2>{collection.name}</h2>

        {/* applied filters */}
        <AppliedFilters />

        <Row>
          {itemListing.items.map((item, index) => {
            return (
              <>
                <Col md='3' xs='6' style={{ marginBottom: '20px' }}>
                  <ProductThumb
                    product={item}
                    handleAddToCart={this.handleAddToCart}
                    handleAddToWishlist={this.handleAddToWishlist}
                  />
                </Col>
              </>
            );
          })}
        </Row>
        <PaginateTable
          listing={this.props.itemListing}
          activePage={this.state.page}
          handlePageChange={this.handlePageChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collection: state.itemReducer.collection,
  itemListing: state.itemReducer.itemListing,
});
export default connect(
  mapStateToProps,
  { postAddCart, postAddWatchlist, getAllItemByCollection }
)(withRouter(Index));
