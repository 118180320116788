import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col, Form } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { submitLogin } from '../../redux/frontend/action/login';

class Index extends Component {
  state = {
    username: 'upadesh.ng@gmail.com',
    password: 'Secret123',
  };
  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'username') {
      this.setState({ username: value });
    } else if (name === 'password') {
      this.setState({ password: value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log(this.state);
    this.props.submitLogin(this.state, this.props.history);
  };

  render() {
    return (
      <>
        <h2>LOGIN!</h2>

        <Form>
          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name='username'
              type='email'
              placeholder='Enter email'
              onChange={this.handleChange}
              defaultValue='upadesh.ng@gmail.com'
            />
          </Form.Group>

          <Form.Group controlId='formBasicPassword'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              name='password'
              type='password'
              placeholder='Password'
              onChange={this.handleChange}
              defaultValue='Secret123'
            />
          </Form.Group>
          {/* <Form.Group controlId='formBasicCheckbox'>
                    <Form.Check type='checkbox' label='Check me out' />
                  </Form.Group> */}
          <Button
            variant='btn btn-success'
            type='submit'
            onClick={(e) => this.handleSubmit(e)}
          >
            Login
          </Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(
  mapStateToProps,
  { submitLogin }
)(withRouter(Index));
