import React, { Component } from 'react';
import { Carousel, Card, Button, Row, Col, ListGroup } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link, withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { getAllItemByCollection } from '../../redux/frontend/action/item';
import { getAllCollectionFilter } from '../../redux/frontend/action/collection';

class Index extends Component {
  state = {
    // redirect: false,
    // params: null,
  };

  componentDidMount() {
    console.log('kk');

    // var id = this.props.match.params.id;
    // console.log(this.props.location.search);
    // this.props.getAllItemByCollection(id);
    // this.props.getAllCollectionFilter(id, this.props.location.search);
  }

  handleGetCheckedFilter = (filter, value) => {
    let urlParam = queryString.parse(this.props.location.search);
    var curValue = urlParam[filter];

    if (typeof curValue != 'undefined') {
      if (curValue.includes('_' + value + '_') === true) {
        return true;
      }
    }
  };

  handleOnChange = (e, name) => {
    var value = e.target.value;
    var checked = e.target.checked;

    let urlParam = queryString.parse(this.props.location.search);

    var curValue = urlParam[name];
    var toAdd = '_' + value + '_';

    // add to url if not exists
    if (typeof curValue != 'undefined' && curValue.includes(toAdd) === false) {
      curValue = curValue + toAdd;
    }

    // if undefined url value
    if (typeof curValue == 'undefined') {
      curValue = toAdd;
    }

    // add value
    urlParam[name] = curValue;

    let param = new URLSearchParams(urlParam).toString();

    if (checked === false) {
      param = param.replace('_' + value + '_', '');
    }

    // param = param.replace('__', '_');

    this.handleFilterResult(param);
  };

  handleFilterResult = (param) => {
    // change url
    this.props.history.push(this.props.location.pathname + '?' + param);

    // api call here
    this.props.getAllItemByCollection(this.props.match.params.id, param);
  };

  render() {
    const { collectionFilterListing } = this.props;

    if (!collectionFilterListing.items) {
      return <div></div>;
    }

    return (
      <>
        <h2>Filters</h2>
        {collectionFilterListing.items.map((item, index) => {
          return (
            <>
              <ListGroup className='mb-15'>
                <ListGroup.Item>
                  <h5
                    className='proxima-nova-dark'
                    style={{ fontSize: '16px' }}
                  >
                    {item.name}
                  </h5>

                  <div
                    style={{
                      overflow: 'scroll',
                      maxHeight: '150px',
                    }}
                  >
                    {item.filterOptions.map((filterOption, indexOption) => {
                      return (
                        <>
                          <div>
                            <input
                              type='checkbox'
                              checked={this.handleGetCheckedFilter(
                                item.slug,
                                filterOption.slug
                              )}
                              id={filterOption.id}
                              value={filterOption.slug}
                              onChange={(e) =>
                                this.handleOnChange(e, item.slug)
                              }
                            />{' '}
                            <label
                              for={filterOption.id}
                              className='proxima-nova-light'
                            >
                              {filterOption.name}
                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </>
          );
        })}

        {/* <ListGroup>
          <ListGroup.Item>
            <h5 style={{ fontSize: '16px' }}>Shape</h5>
            <div>
              <input
                type='checkbox'
                id='3'
                value='round'
                onChange={(e) => this.handleOnChange(e, 'shape')}
              />{' '}
              <label for='3'>Round</label>
            </div>
            <div>
              <input
                type='checkbox'
                id='4'
                value='oval'
                onChange={(e) => this.handleOnChange(e, 'shape')}
              />{' '}
              <label for='4'>Oval</label>
            </div>
          </ListGroup.Item>
        </ListGroup> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  collectionFilterListing: state.collectionReducer.collectionFilterListing,
});
export default connect(
  mapStateToProps,
  { getAllCollectionFilter, getAllItemByCollection }
)(withRouter(Index));
